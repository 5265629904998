import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const SceneForm = styled(Form)`
  padding: 2rem 4rem 2rem 4rem;
`;

export const FixedButton = styled.div`
  position: fixed;
  margin-top: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.darkRed};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    text-align: center;
  `}
`;

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 5rem;
`;

export const Wrapper = styled.div`
  margin-bottom: 0.8rem;
`;

export const Table = styled.div`
  margin: 3rem 0 3rem 0;
`;

export const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 2fr);
  gap: 2rem;
`;

export const Label = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    margin-right: 0.5rem;
  `}
`;

export const ContainerSelect = styled.div`
  padding-top: 1rem;
`;


export const ContainerData = styled.div`
  width: 10vh;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 5rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

