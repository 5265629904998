import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
`;

export const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.background};
`;
