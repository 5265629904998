import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';

import {
  FaPlus,
  FaAmbulance,
  FaNotesMedical,
  FaClipboardList,
  FaFileSignature,
  FaFileAlt,
  FaBan,
  FaCopy,
  FaList,
  FaRecycle,
  FaListAlt,
} from "react-icons/fa";

import * as S from './styles';
import AuthContext from '../../context/auth';
import Select from '../select';

const TableContainer = (props) => {
  const { columns, data, type, rowProps, duplicateOccurrence, openStatusHistory, cancel, changeStatus, filtros, setFiltros, setFiltrosTransferencia, filtrosTransferencia, usaFiltro } = props;

  const [filtrosPadrao] = React.useState(data);

  const [filtrosTabelaVTR, setFiltrosTabelaVTR] = React.useState([])
  const [filtrosPrioridade, setFiltrosPrioridade] = React.useState([])
  const [filtrosStatus, setFiltrosStatus] = React.useState([])
  const [filtrosMedico, setFiltrosMedico] = React.useState([])
  const [filtrosCidade, setFiltrosCidade] = React.useState([])
  const [filtrosBairro, setFiltrosBairro] = React.useState([])
  const [filtrosSolicitante, setFiltrosSolicitante] = React.useState([])
  const [filtrosPaciente, setFiltrosPaciente] = React.useState([])
  const [filtrosIdade, setFiltrosIdade] = React.useState([])
  const [filtrosHD, setFiltrosHD] = React.useState([])
  const [filtrosChamado, setFiltrosChamado] = React.useState([])
  const [filtrosData, setFiltrosData] = React.useState([])
  const [filtrosHora, setFiltrosHora] = React.useState([])
  const [filtrosHospital, setFiltrosHospital] = React.useState([])

  const [filtrosTransferenciaTabelaVTR, setFiltrosTransferenciaTabelaVTR] = React.useState([])
  const [filtrosTransferenciaPrioridade, setFiltrosTransferenciaPrioridade] = React.useState([])
  const [filtrosTransferenciaStatus, setFiltrosTransferenciaStatus] = React.useState([])
  const [filtrosTransferenciaMedico, setFiltrosTransferenciaMedico] = React.useState([])
  const [filtrosTransferenciaCidade, setFiltrosTransferenciaCidade] = React.useState([])
  const [filtrosTransferenciaBairro, setFiltrosTransferenciaBairro] = React.useState([])
  const [filtrosTransferenciaSolicitante, setFiltrosTransferenciaSolicitante] = React.useState([])
  const [filtrosTransferenciaPaciente, setFiltrosTransferenciaPaciente] = React.useState([])
  const [filtrosTransferenciaIdade, setFiltrosTransferenciaIdade] = React.useState([])
  const [filtrosTransferenciaHD, setFiltrosTransferenciaHD] = React.useState([])
  const [filtrosTransferenciaChamado, setFiltrosTransferenciaChamado] = React.useState([])
  const [filtrosTransferenciaData, setFiltrosTransferenciaData] = React.useState([])
  const [filtrosTransferenciaHora, setFiltrosTransferenciaHora] = React.useState([])
  const [filtrosTransferenciaHospital, setFiltrosTransferenciaHospital] = React.useState([])


  const loadFilters = () => {

    if (filtros) {
      console.log("passou aqui", filtros)
      let filtroVTR = []
      let filtroPrioridade = []
      let filtroStatus = []
      let filtroMedico = []
      let filtrosCidade = []
      let filtrosBairro = []
      let filtrosSolicitante = []
      let filtrosPaciente = []
      let filtrosIdade = []
      let filtrosHD = []
      let filtrosChamado = []
      let filtrosData = []
      let filtrosHora = []
      let filtrosHospital = []

      filtros.map((item) => {
        if (item.vtr) {
          let vtr = {
            id: item.id,
            value: item.vtr,
            name: item.vtr
          }
          filtroVTR.push(vtr)
        }
        if (item.prioridade) {
          let prioridade = {
            id: item.id,
            value: item.prioridade,
            name: item.prioridade
          }
          filtroPrioridade.push(prioridade)
        }
        if (item.status.descricao) {
          let status = {
            id: item.status.id,
            value: item.status.descricao,
            name: item.status.descricao
          }
          filtroStatus.push(status)
        }
        //LND olha isso
        if(item.doctor){
          try{
            if (item.doctor.props.children.props.children[1].props.children) {
              let medico = {
                id: item.medico,
                value: item.doctor.props.children.props.children[1].props.children,
                name: item.doctor.props.children.props.children[1].props.children
              }
              filtroMedico.push(medico)
            }
          } catch(e){
            console.log("erro", e)
          }
       }

        if (item.city) {
          let cidade = {
            id: item.cidade,
            value: item.city,
            name: item.city
          }
          filtrosCidade.push(cidade)
        }
        if(item.neighborhood){
          try{
          if (item.neighborhood.props.children.props.children[1].props.children) {
            let bairro = {
              id: item.bairro,
              value: item.bairro,
              name: item.neighborhood.props.children.props.children[1].props.children
            }
            filtrosBairro.push(bairro)
          }
        } catch(e){
          console.log("erro", e)
        }
        }

        if (item.requester) {
          let solicitante = {
            id: item.solicitante,
            value: item.requester,
            name: item.requester
          }
          filtrosSolicitante.push(solicitante)
        }
        if (item.patient) {
          let paciente = {
            id: item.paciente,
            value: item.patient,
            name: item.patient
          }
          filtrosPaciente.push(paciente)
        }
        if (item.age) {
          let idade = {
            id: item.idade,
            value: item.age,
            name: item.age
          }
          filtrosIdade.push(idade)
        }
        if (item.hd) {
          let hd = {
            id: item.hd,
            value: item.hd,
            name: item.hd
          }
          filtrosHD.push(hd)
        }
        if (item.id) {
          let chamado = {
            id: item.id,
            value: item.id,
            name: item.id
          }
          filtrosChamado.push(chamado)
        }
        if (item.date) {
          let data = {
            id: item.id,
            value: item.date,
            name: item.date
          }
          filtrosData.push(data)
        }
        if (item.hora) {
          let hora = {
            id: item.id,
            value: item.hora,
            name: item.hora
          }
          filtrosHora.push(hora)
        }
        if (item.hospital) {
          let hospital = {
            id: item.id,
            value: item.hospital,
            name: item.hospital
          }
          filtrosHospital.push(hospital)
        }

      })
      //remova valores duplicados de filtroStatus
      let filtraStatus = filtroStatus.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraStatus.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraPrioridade = filtroPrioridade.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraPrioridade.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraVTR = filtroVTR.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraVTR.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraMedico = filtroMedico.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraMedico.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraCidade = filtrosCidade.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraCidade.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraBairro = filtrosBairro.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraBairro.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraSolicitante = filtrosSolicitante.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraSolicitante.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraPaciente = filtrosPaciente.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraPaciente.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraIdade = filtrosIdade.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraIdade.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraHD = filtrosHD.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraHD.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraChamado = filtrosChamado.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraChamado.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraData = filtrosData.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraData.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraHora = filtrosHora.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraHora.sort((a, b) => (a.name > b.name) ? 1 : -1)
      let filtraHospital = filtrosHospital.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraHospital.sort((a, b) => (a.name > b.name) ? 1 : -1)


      setFiltrosTabelaVTR(filtraVTR)
      setFiltrosPrioridade(filtraPrioridade)
      setFiltrosStatus(filtraStatus)
      setFiltrosMedico(filtraMedico)
      setFiltrosCidade(filtraCidade)
      setFiltrosBairro(filtraBairro)
      setFiltrosSolicitante(filtraSolicitante)
      setFiltrosPaciente(filtraPaciente)
      setFiltrosIdade(filtraIdade)
      setFiltrosHD(filtraHD)
      setFiltrosChamado(filtraChamado)
      setFiltrosData(filtraData)
      setFiltrosHora(filtraHora)
      setFiltrosHospital(filtraHospital)

    }
    if (filtrosTransferencia) {
      let filtroTransferenciaVTR = []
      let filtroTransferenciaPrioridade = []
      let filtroTransferenciaStatus = []
      let filtroTransferenciaMedico = []
      let filtrosTransferenciaCidade = []
      let filtrosTransferenciaBairro = []
      let filtrosTransferenciaSolicitante = []
      let filtrosTransferenciaPaciente = []
      let filtrosTransferenciaIdade = []
      let filtrosTransferenciaHD = []
      let filtrosTransferenciaChamado = []
      let filtrosTransferenciaData = []
      let filtrosTransferenciaHora = []
      let filtrosTransferenciaHospital = []

      filtrosTransferencia.map((item) => {
        console.log("chegou um valor de filtro de transferencia", item)
        if (item.vtr) {
          let vtr = {
            id: item.id,
            value: item.vtr,
            name: item.vtr
          }
          filtroTransferenciaVTR.push(vtr)
        }
        if (item.prioridade) {
          let prioridade = {
            id: item.id,
            value: item.prioridade,
            name: item.prioridade
          }
          filtroTransferenciaPrioridade.push(prioridade)
        }
        if (item.status.descricao) {
          let status = {
            id: item.status.id,
            value: item.status.descricao,
            name: item.status.descricao
          }
          filtroTransferenciaStatus.push(status)
        }
        //LND olha isso
        try{
        if (item.doctor.props.children.props.children[0].props.children[0]) {
          let medico = {
            id: item.doctor.props.children.props.children[0].props.children[0],
            value: item.doctor.props.children.props.children[0].props.children[0],
            name: item.doctor.props.children.props.children[0].props.children[0]
          }
          filtroTransferenciaMedico.push(medico)
        }
      }catch(e){
        
      }

        if (item.city) {
          let cidade = {
            id: item.cidade,
            value: item.city,
            name: item.city
          }
          filtrosTransferenciaCidade.push(cidade)
        }
        console.log("esse é o item neighborhood transferencia", item)
        try{
        if (item.neighborhood.props.children.props.children[1].props.children) {
          let bairro = {
            id: item.neighborhood.props.children.props.children[1].props.children,
            value: item.neighborhood.props.children.props.children[1].props.children,
            name: item.neighborhood.props.children.props.children[1].props.children
          }
          filtrosTransferenciaBairro.push(bairro)
        }
      }catch(e){
 
      }
        if (item.requester) {
          let solicitante = {
            id: item.solicitante,
            value: item.requester,
            name: item.requester
          }
          filtrosTransferenciaSolicitante.push(solicitante)
        }
        if (item.patient) {
          let paciente = {
            id: item.paciente,
            value: item.patient,
            name: item.patient
          }
          filtrosTransferenciaPaciente.push(paciente)
        }
        if (item.age) {
          let idade = {
            id: item.idade,
            value: item.age,
            name: item.age
          }
          filtrosTransferenciaIdade.push(idade)
        }
        if (item.hd) {
          let hd = {
            id: item.hd,
            value: item.hd,
            name: item.hd
          }
          filtrosTransferenciaHD.push(hd)
        }
        if (item.id) {
          let chamado = {
            id: item.id,
            value: item.id,
            name: item.id
          }
          filtrosTransferenciaChamado.push(chamado)
        }
        if (item.date) {
          let data = {
            id: item.id,
            value: item.date,
            name: item.date
          }
          filtrosTransferenciaData.push(data)
        }
        if (item.hora) {
          let hora = {
            id: item.id,
            value: item.hora,
            name: item.hora
          }
          filtrosTransferenciaHora.push(hora)
        }
        if (item.hospital) {
          let hospital = {
            id: item.id,
            value: item.hospital.props.children[1],
            name: item.hospital.props.children[1]
          }
          filtrosTransferenciaHospital.push(hospital)
        }

      })
      //remova valores duplicados de filtroStatus
      let filtraStatus = filtroTransferenciaStatus.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraStatus.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraPrioridade = filtroTransferenciaPrioridade.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraPrioridade.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraVTR = filtroTransferenciaVTR.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraVTR.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraMedico = filtroTransferenciaMedico.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraMedico.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraCidade = filtrosTransferenciaCidade.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraCidade.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraBairro = filtrosTransferenciaBairro.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraBairro.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraSolicitante = filtrosTransferenciaSolicitante.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraSolicitante.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraPaciente = filtrosTransferenciaPaciente.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraPaciente.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraIdade = filtrosTransferenciaIdade.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraIdade.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraHD = filtrosTransferenciaHD.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraHD.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraChamado = filtrosTransferenciaChamado.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraChamado.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraData = filtrosTransferenciaData.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraData.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraHora = filtrosTransferenciaHora.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraHora.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      let filtraHospital = filtrosTransferenciaHospital.filter((item, index, self) => index === self.findIndex((t) => t.name === item.name))
      filtraHospital.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))


      setFiltrosTransferenciaTabelaVTR(filtraVTR)
      setFiltrosTransferenciaPrioridade(filtraPrioridade)
      setFiltrosTransferenciaStatus(filtraStatus)
      setFiltrosTransferenciaMedico(filtraMedico)
      setFiltrosTransferenciaCidade(filtraCidade)
      setFiltrosTransferenciaBairro(filtraBairro)
      setFiltrosTransferenciaSolicitante(filtraSolicitante)
      setFiltrosTransferenciaPaciente(filtraPaciente)
      setFiltrosTransferenciaIdade(filtraIdade)
      setFiltrosTransferenciaHD(filtraHD)
      setFiltrosTransferenciaChamado(filtraChamado)
      setFiltrosTransferenciaData(filtraData)
      setFiltrosTransferenciaHora(filtraHora)
      setFiltrosTransferenciaHospital(filtraHospital)


    }
  }

  const navigate = useNavigate();
  const { user, role } = useContext(AuthContext)

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const openSceneForm = (id) => {
    localStorage.setItem("action", "EDIT");
    localStorage.setItem("id", id);
    localStorage.setItem("user", user);
    localStorage.setItem("role", role);
    window.open(
      "/cena",
      "_blank"
    )
  }

  const openRetainedEquipmentForm = (id) => {
    navigate("/equipamentos", {
      state: {
        action: "READ",
        id: id
      }
    })
  }

  const openSceneFormView = (id) => {
    localStorage.setItem("action", "READ");
    localStorage.setItem("id", id);
    localStorage.setItem("user", user);
    localStorage.setItem("role", role);
    window.open(
      "/cena",
      "_blank"
    )
  }

  const isReadyToRoSendVtr = (id) => {
    if (props.data[id].status.id == 1) {
      return false
    } else {
      return true
    }

  }

  const isStatusRegulacao = (id) => {
    if (props.data[id].status.id == 1) {
      return true
    } else {
      return false
    }

  }

  const getPrioridadeCor = (prioridade) => {
    console.log("prioridade", prioridade)
    switch (prioridade) {
      case 1:
        return '#c00';
      case 3:
        return '#fc0';
      case 4:
        return '#0c0';
      case 5:
        return '#00f';
    }
  }

  const isMedico = () => {
    return (["ROOT", "MÉDICO", "ADMINISTRADOR"].indexOf(role) >= 0)
  }
  const isTarm = () => {
    return (["TARM"].indexOf(role) >= 0)
  }
  const isAdmin = () => {
    return (["ROOT", "ADMINISTRADOR"].indexOf(role) >= 0)
  }
  const isRadioOperador = () => {
    return (["ROOT", "RADIO OPERADOR", "ADMINISTRADOR"].indexOf(role) >= 0)
  }

  // useEffect(() => {
  //   loadFilters()  
  // }, [filtros, filtrosTransferencia])


  const aplicaFiltro = (type, value) => {

    console.log("esse é o value", value)
    if (filtros) {
      if (value == undefined || value == "") {
        console.log("como não tem valor, filtro padrao", filtrosPadrao)
        //setFiltros(filtrosPadrao)
        usaFiltro(false)
      } else {
        let filtrosTabela = filtros.filter((item) => {
          console.log("olha esse item", item)
          if (type === 'vtr') {
            return item.vtr === value
          } else if (type === 'prioridade') {
            return item.prioridade == value
          } else if (type === 'status') {
            return item.status.descricao === value
          } else if (type === 'medico') {
            return item.doctor.props.children.props.children[1].props.children == value
          } else if (type === 'cidade') {
            return item.city == value
          } else if (type === 'bairro') {
            return item.neighborhood.props.children.props.children[1].props.children == value
          } else if (type === 'solicitante') {
            return item.requester == value
          } else if (type === 'paciente') {
            return item.patient == value
          } else if (type === 'idade') {
            return item.age == value
          } else if (type === 'hd') {
            return item.hd == value
          } else if (type === 'chamado') {
            return item.id == value
          } else if (type === 'data') {
            return item.date == value
          } else if (type === 'hora') {
            return item.hora == value
          } else if (type === 'hospital') {
            return item.hospital == value
          }
        })
        setFiltros(filtrosTabela)
        usaFiltro(true)
      }
    }
    else
      if (filtrosTransferencia) {
        if (value == undefined || value == "") {
          console.log("como não tem valor, filtro padrao", filtrosPadrao)
          //setFiltros(filtrosPadrao)
          usaFiltro(false)
        } else {
          let filtrosTabela = filtrosTransferencia.filter((item) => {
            console.log("olha esse item de transferencia", item)
            if (type === 'vtr') {
              return item.vtr === value
            } else if (type === 'prioridade') {
              return item.prioridade == value
            } else if (type === 'status') {
              return item.status.descricao === value
            } else if (type === 'medico') {
              console.log("entrou no filtro de medico", item.doctor.props.children.props.children[0].props.children[0])
              return item.doctor.props.children.props.children[0].props.children[0] == value
            } else if (type === 'cidade') {
              return item.city == value
            } else if (type === 'bairro') {
              return item.neighborhood.props.children.props.children[1].props.children == value
            } else if (type === 'solicitante') {
              return item.requester == value
            } else if (type === 'paciente') {
              return item.patient == value
            } else if (type === 'idade') {
              return item.age == value
            } else if (type === 'hd') {
              return item.hd == value
            } else if (type === 'chamado') {
              return item.id == value
            } else if (type === 'data') {
              return item.date == value
            } else if (type === 'hora') {
              return item.hora == value
            } else if (type === 'hospital') {
              return item.hospital.props.children[1] == value
            }
          }
          )
          setFiltrosTransferencia(filtrosTabela)
          usaFiltro(true)
        }
      }
  }

  const [filtroStatusSelecionado, setFiltroStatusSelecionado] = React.useState({})
  const [filtroVtrSelecionado, setFiltroVtrSelecionado] = React.useState({})
  const [filtroPrioridadeSelecionado, setFiltroPrioridadeSelecionado] = React.useState({})
  const [filtroMedicoSelecionado, setFiltroMedicoSelecionado] = React.useState({})
  const [filtroCidadeSelecionado, setFiltroCidadeSelecionado] = React.useState({})
  const [filtroBairroSelecionado, setFiltroBairroSelecionado] = React.useState({})
  const [filtroSolicitanteSelecionado, setFiltroSolicitanteSelecionado] = React.useState({})
  const [filtroPacienteSelecionado, setFiltroPacienteSelecionado] = React.useState({})
  const [filtroIdadeSelecionado, setFiltroIdadeSelecionado] = React.useState({})
  const [filtroHDSelecionado, setFiltroHDSelecionado] = React.useState({})
  const [filtroChamadoSelecionado, setFiltroChamadoSelecionado] = React.useState({})
  const [filtroDataSelecionado, setFiltroDataSelecionado] = React.useState({})
  const [filtroHoraSelecionado, setFiltroHoraSelecionado] = React.useState({})
  const [filtroHospitalSelecionado, setFiltroHospitalSelecionado] = React.useState({})

  const [filtroTransferenciaStatusSelecionado, setFiltroTransferenciaStatusSelecionado] = React.useState({})
  const [filtroTransferenciaVtrSelecionado, setFiltroTransferenciaVtrSelecionado] = React.useState({})
  const [filtroTransferenciaPrioridadeSelecionado, setFiltroTransferenciaPrioridadeSelecionado] = React.useState({})
  const [filtroTransferenciaMedicoSelecionado, setFiltroTransferenciaMedicoSelecionado] = React.useState({})
  const [filtroTransferenciaCidadeSelecionado, setFiltroTransferenciaCidadeSelecionado] = React.useState({})
  const [filtroTransferenciaBairroSelecionado, setFiltroTransferenciaBairroSelecionado] = React.useState({})
  const [filtroTransferenciaSolicitanteSelecionado, setFiltroTransferenciaSolicitanteSelecionado] = React.useState({})
  const [filtroTransferenciaPacienteSelecionado, setFiltroTransferenciaPacienteSelecionado] = React.useState({})
  const [filtroTransferenciaIdadeSelecionado, setFiltroTransferenciaIdadeSelecionado] = React.useState({})
  const [filtroTransferenciaHDSelecionado, setFiltroTransferenciaHDSelecionado] = React.useState({})
  const [filtroTransferenciaChamadoSelecionado, setFiltroTransferenciaChamadoSelecionado] = React.useState({})
  const [filtroTransferenciaDataSelecionado, setFiltroTransferenciaDataSelecionado] = React.useState({})
  const [filtroTransferenciaHoraSelecionado, setFiltroTransferenciaHoraSelecionado] = React.useState({})
  const [filtroTransferenciaHospitalSelecionado, setFiltroTransferenciaHospitalSelecionado] = React.useState({})

  const setTooltipVisible = (tooltipID, visivel, type) => {

    if (type) {
    if (visivel) {
        document.getElementById(type + tooltipID ).style.display = "flex";
    } else {
      let tooltip = document.getElementById(type + tooltipID )
      if (tooltip) {
        tooltip.style.display = "none"
      } else {
        console.log("nao encontrou o tooltip")
      }
    }
  }
 
  }


  return (
    <S.TableContainer>
      <S.Table {...getTableProps}>
        <S.Head>
          {headerGroups.map((headerGroup) => (
            <S.Tr {...headerGroup.getHeaderGroupProps()}>
              {type ==='employee' && <S.Th> </S.Th>}
              {type === 'workSchedule' && <S.Th> </S.Th>}
              {type === 'first' &&
              <>
                <S.Th></S.Th>
                <S.Th></S.Th>
                
                </>
              }
              {(type === 'second' || type === 'third') && (
                <>
                  <S.Th>ID
                  </S.Th>
                  <S.Th></S.Th>
                  <S.Th></S.Th>
                </>
              )}
              {headerGroup.headers.map((column) => (
                <>
                    <S.Th {...column.getHeaderProps()} key={column.id}>
                      {column.render('Header')} 
                    </S.Th>
                  

                  {/* {filtros && //FILTROS CHAMADOS ABERTOS
                    <S.Th {...column.getHeaderProps()} key={column.id}>

                      {column.render('Header')}
                      {filtrosTabelaVTR && column.id === 'vtr' &&
                        <Select id={column.id}
                          data={filtrosTabelaVTR}
                          value={filtroVtrSelecionado}
                          onChange={(e) => {
                            setFiltroVtrSelecionado(e.target.value)
                            aplicaFiltro("vtr", e.target.value)
                          }} />
                      }
                      {filtrosTabelaVTR && column.id === 'prioridade' &&
                        <Select id={column.id}
                          data={filtrosPrioridade}
                          value={filtroPrioridadeSelecionado}
                          onChange={(e) => {
                            setFiltroPrioridadeSelecionado(e.target.value)
                            aplicaFiltro("prioridade", e.target.value)
                          }} />
                      }
                      {filtrosStatus && column.id === 'pr' &&
                        <Select id={column.id}
                          data={filtrosStatus}
                          value={filtroStatusSelecionado}
                          onChange=
                          {(e) => {

                            aplicaFiltro("status", e.target.value)
                            setFiltroStatusSelecionado(e.target.value)
                          }} />
                      }
                      {filtrosMedico && column.id === 'doctor' &&
                        <Select id={column.id}
                          data={filtrosMedico}
                          value={filtroMedicoSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroMedicoSelecionado(e.target.value)
                            aplicaFiltro("medico", e.target.value)
                          }} />
                      }
                      {filtrosCidade && column.id === 'city' &&
                        <Select id={column.id}
                          data={filtrosCidade}
                          value={filtroCidadeSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroCidadeSelecionado(e.target.value)
                            aplicaFiltro("cidade", e.target.value)
                          }} />
                      }
                      {filtrosBairro && column.id === 'neighborhood' &&
                        <Select id={column.id}
                          data={filtrosBairro}
                          value={filtroBairroSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroBairroSelecionado(e.target.value)
                            aplicaFiltro("bairro", e.target.value)
                          }} />
                      }
                      {filtrosSolicitante && column.id === 'requester' &&
                        <Select id={column.id}
                          data={filtrosSolicitante}
                          value={filtroSolicitanteSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroSolicitanteSelecionado(e.target.value)
                            aplicaFiltro("solicitante", e.target.value)
                          }} />
                      }
                      {filtrosPaciente && column.id === 'patient' &&
                        <Select id={column.id}
                          data={filtrosPaciente}
                          value={filtroPacienteSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroPacienteSelecionado(e.target.value)
                            aplicaFiltro("paciente", e.target.value)
                          }} />
                      }
                      {filtrosIdade && column.id === 'age' &&
                        <Select id={column.id}
                          data={filtrosIdade}
                          value={filtroIdadeSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroIdadeSelecionado(e.target.value)
                            aplicaFiltro("idade", e.target.value)
                          }} />
                      }
                      {filtrosHD && column.id === 'hd' &&
                        <Select id={column.id}
                          data={filtrosHD}
                          value={filtroHDSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroHDSelecionado(e.target.value)
                            aplicaFiltro("hd", e.target.value)
                          }} />
                      }
                      {filtrosChamado && column.id === 'callNumber' &&
                        <Select id={column.id}
                          data={filtrosChamado}
                          value={filtroChamadoSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroChamadoSelecionado(e.target.value)
                            aplicaFiltro("chamado", e.target.value)
                          }} />
                      }
                      {filtrosData && column.id === 'date' &&
                        <Select id={column.id}
                          data={filtrosData}
                          value={filtroDataSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroDataSelecionado(e.target.value)
                            aplicaFiltro("data", e.target.value)
                          }} />
                      }
                      {filtrosHora && column.id === 'hora' &&
                        <Select id={column.id}
                          data={filtrosHora}
                          value={filtroHoraSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroHoraSelecionado(e.target.value)
                            aplicaFiltro("hora", e.target.value)
                          }} />
                      }
                      {filtrosHospital && column.id === 'hospital' &&
                        <Select id={column.id}
                          data={filtrosHospital}
                          value={filtroHospitalSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroHospitalSelecionado(e.target.value)
                            aplicaFiltro("hospital", e.target.value)
                          }} />
                      }

                    </S.Th>

                  }
                  {filtrosTransferencia && // FILTROS CHAMADOS ABERTOS TRANSFERENCIA

                    <S.Th {...column.getHeaderProps()} key={column.id}>

                      {column.render('Header')}
                      {filtrosTransferenciaTabelaVTR && column.id === 'vtr' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaTabelaVTR}
                          value={filtroTransferenciaVtrSelecionado}
                          onChange={(e) => {
                            setFiltroTransferenciaVtrSelecionado(e.target.value)
                            aplicaFiltro("vtr", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaTabelaVTR && column.id === 'prioridade' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaPrioridade}
                          value={filtroTransferenciaPrioridadeSelecionado}
                          onChange={(e) => {
                            setFiltroTransferenciaPrioridadeSelecionado(e.target.value)
                            aplicaFiltro("prioridade", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaStatus && column.id === 'pr' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaStatus}
                          value={filtroTransferenciaStatusSelecionado}
                          onChange=
                          {(e) => {

                            aplicaFiltro("status", e.target.value)
                            setFiltroTransferenciaStatusSelecionado(e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaMedico && column.id === 'doctor' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaMedico}
                          value={filtroTransferenciaMedicoSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaMedicoSelecionado(e.target.value)
                            aplicaFiltro("medico", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaCidade && column.id === 'city' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaCidade}
                          value={filtroTransferenciaCidadeSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaCidadeSelecionado(e.target.value)
                            aplicaFiltro("cidade", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaBairro && column.id === 'neighborhood' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaBairro}
                          value={filtroTransferenciaBairroSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaBairroSelecionado(e.target.value)
                            aplicaFiltro("bairro", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaSolicitante && column.id === 'requester' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaSolicitante}
                          value={filtroTransferenciaSolicitanteSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaSolicitanteSelecionado(e.target.value)
                            aplicaFiltro("solicitante", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaPaciente && column.id === 'patient' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaPaciente}
                          value={filtroTransferenciaPacienteSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaPacienteSelecionado(e.target.value)
                            aplicaFiltro("paciente", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaIdade && column.id === 'age' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaIdade}
                          value={filtroTransferenciaIdadeSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaIdadeSelecionado(e.target.value)
                            aplicaFiltro("idade", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaHD && column.id === 'hd' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaHD}
                          value={filtroTransferenciaHDSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaHDSelecionado(e.target.value)
                            aplicaFiltro("hd", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaChamado && column.id === 'callNumber' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaChamado}
                          value={filtroTransferenciaChamadoSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaChamadoSelecionado(e.target.value)
                            aplicaFiltro("chamado", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaData && column.id === 'date' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaData}
                          value={filtroTransferenciaDataSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaDataSelecionado(e.target.value)
                            aplicaFiltro("data", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaHora && column.id === 'hora' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaHora}
                          value={filtroTransferenciaHoraSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaHoraSelecionado(e.target.value)
                            aplicaFiltro("hora", e.target.value)
                          }} />
                      }
                      {filtrosTransferenciaHospital && column.id === 'hospital' &&
                        <Select id={column.id}
                          data={filtrosTransferenciaHospital}
                          value={filtroTransferenciaHospitalSelecionado}
                          onChange=
                          {(e) => {
                            setFiltroTransferenciaHospitalSelecionado(e.target.value)
                            aplicaFiltro("hospital", e.target.value)
                          }} />
                      }

                    </S.Th>

                  } */}
                </>
              ))}
              {(type === 'second' || type === 'third') && (
                <>
                  {(isMedico() || isRadioOperador()) &&
                    <S.Th colSpan={5}></S.Th>
                  }
                  {!(isMedico() || isRadioOperador()) &&
                    <S.Th colSpan={3}></S.Th>
                  }
                </>
              )}
            </S.Tr>
          ))}
        </S.Head>
        <S.Body>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <S.Tr
                {...row.getRowProps(rowProps)}
                key={row.index}
              >
                {type === 'first' &&
                  <S.Td>
                    <S.cardToolTip onMouseOver={() => setTooltipVisible(row.original.chamado, true, "retainedEquipment")} onMouseLeave={() => setTooltipVisible(row.original.chamado, false, "retainedEquipment")}>
                      <S.Tooltip id={"retainedEquipment" + row.original.chamado}>
                        EQUIPAMENTOS RETIDOS
                      </S.Tooltip>
                      <FaListAlt size={20} cursor='pointer' onClick={() => openRetainedEquipmentForm(row.original.chamado)}>  </FaListAlt>
                    </S.cardToolTip>
                  </S.Td>
                }
        
                {type === 'second' && row.original?.numeroChamadoDuplicado ?
                  <S.Td>
                    {row.original?.numeroChamadoDuplicado + "/" + row.original?.sequenciaChamadoDuplicado}
                  </S.Td>
                  :
                  <S.Td>
                    {row.original.id}
                  </S.Td>
                }
                {type === 'second' &&
                  <>
                    {isRadioOperador() && !isAdmin() &&
                      <>
                        <S.Td className={row.original.alert}>

                          {isReadyToRoSendVtr(row.id) &&
                          
                          <S.cardToolTip onMouseOver={() => setTooltipVisible(row.original.id, true, "roOpenScene")} onMouseLeave={() => setTooltipVisible(row.original.id, false, "roOpenScene")}>
                            <S.Tooltip id={"roOpenScene" + row.original.id}>
                              FICHA DE REGULAÇÃO
                            </S.Tooltip>
                            <FaAmbulance size={20} cursor='pointer' onClick={() => openSceneFormView(row.original.id)} />
                          </S.cardToolTip>
                          }
                        </S.Td>
                        <S.Td>
                        </S.Td>

                        {/* <S.Td className={row.original.alert}>
                      <FaFileAlt size={20} cursor='pointer' onClick={() => openSceneForm(row.original.id)} />
                    </S.Td>
                    <S.Td className={row.original.alert}>
                      <FaFileSignature size={20} cursor='pointer' />
                    </S.Td> */}

                      </>
                    }
                    {isTarm() && !isAdmin() &&
                      <>
                        <S.Td className={row.original.alert}>
                        <S.cardToolTip onMouseOver={() => setTooltipVisible(row.original.id, true, "tarmOpenScene")} onMouseLeave={() => setTooltipVisible(row.original.id, false, "tarmOpenScene")}>
                            <S.Tooltip id={"tarmOpenScene" + row.original.id}>
                            FICHA DE REGULAÇÃO
                            </S.Tooltip>
                            <FaAmbulance size={20} cursor='pointer' onClick={() => openSceneFormView(row.original.id)} />
                        </S.cardToolTip>
                        </S.Td>
                        <S.Td>
                        </S.Td>
                      </>
                    }
                  </>
                }
                {isMedico() && !isAdmin() &&
                  <>
                    {/* <S.Td className={row.original.alert}>
                    <FaAmbulance size={20} cursor='pointer' />
                  </S.Td> */}
                    <S.Td className={row.original.alert}>
                      <S.cardToolTip onMouseOver={() => setTooltipVisible(row.original.id, true, "medicoOpenScene")} onMouseLeave={() => setTooltipVisible(row.original.id, false, "medicoOpenScene")}>
                        <S.Tooltip id={"medicoOpenScene" + row.original.id}>
                          FICHA DE REGULAÇÃO
                        </S.Tooltip>
                        <FaFileAlt size={20} cursor='pointer' onClick={() => openSceneFormView(row.original.id)} />
                      </S.cardToolTip>
                      {/* <a href='/cena?id=' target='_blank'> 
                      
                    </a> */}
                      {/* <a target='_blank' href={`/cena?id=${row.original.id}&action=NEW`}> <FaFileAlt size={20} cursor='pointer'/></a> */}
                    </S.Td>
                    <S.Td>
                    </S.Td>
                    {/* <S.Td className={row.original.alert}>
                    <FaFileSignature size={20} cursor='pointer' />
                  </S.Td> */}
                  </>
                }
                {isAdmin() && type != 'first' && type != 'employee' && type != 'workSchedule' &&
                  <>
                    {/* <S.Td className={row.original.alert}>
                      <FaAmbulance size={20} cursor='pointer' />
                    </S.Td> */}
                    {/* <S.Td className={row.original.alert}>
                      <FaFileAlt size={20} cursor='pointer' onClick={() => openSceneForm(row.original.id)} />
                    </S.Td> */}
                    <S.Td></S.Td>
                    <S.Td className={row.original.alert}>
                      <S.cardToolTip onMouseOver={() => setTooltipVisible(row.original.id, true, "adminOpenScene")} onMouseLeave={() => setTooltipVisible(row.original.id, false, "adminOpenScene")}>
                        <S.Tooltip id={"adminOpenScene" + row.original.id}>
                          FICHA DE REGULAÇÃO
                        </S.Tooltip>
                        <FaFileSignature size={20} cursor='pointer' onClick={() => openSceneForm(row.original.id)} />
                      </S.cardToolTip>
                    </S.Td>
                  </>
                }
                {type === 'third' &&
                  <>
                    <S.Td>
                      <FaNotesMedical size={20} cursor='pointer' />
                    </S.Td>
                    <S.Td>
                      <FaClipboardList size={20} cursor='pointer' onClick={() => navigate('/cena', {
                        state: {
                          action: "EDIT",
                          id: row.original.id
                        }
                      })} title='Cena' />
                    </S.Td>
                    <S.Td>
                      <FaFileSignature size={20} cursor='pointer' />
                    </S.Td>
                  </>
                }
                {row.cells.map((cell) => (
                  <S.Td>
                    {cell.column.id == "prioridade" &&
                      <p style={{ display: "block", backgroundColor: getPrioridadeCor(cell.value) }}>
                        {cell.render('Cell')}
                      </p>
                    }
                    {cell.column.id != "prioridade" &&
                      <p>
                        {cell.render('Cell')}
                      </p>
                    }
                  </S.Td>
                ))}
                {type === 'second' &&
                  <>
                    {/* {(isRadioOperador() && row.original.status.id == 3) && //status VTR Enviada
                    <S.Td>

                      <FaBan size={20} cursor='pointer' onClick={() => cancel(row.original)} />

                    </S.Td>
                  } */}
                    {isMedico() &&
                      <S.Td>
                        <S.cardToolTip onMouseOver={() => setTooltipVisible(row.original.id, true, "medicoDuplicateScene")} onMouseLeave={() => setTooltipVisible(row.original.id, false, "medicoDuplicateScene")}>
                          <S.ToolTipBotoes id={"medicoDuplicateScene" + row.original.id}>
                            DUPLICAR
                          </S.ToolTipBotoes>
                        <S.ButtonIcon onClick={() => duplicateOccurrence(row.original.id, row.original.status)}>
                          <FaCopy size={20} cursor='pointer' />
                        </S.ButtonIcon>
                        </S.cardToolTip>
                      </S.Td>
                    }
                    {(isMedico() || isRadioOperador()) &&
                      <S.Td>
                        <S.cardToolTip onMouseOver={() => setTooltipVisible(row.original.id, true, "medicoOpenStatusHistory")} onMouseLeave={() => setTooltipVisible(row.original.id, false, "medicoOpenStatusHistory")}>
                          <S.ToolTipBotoes id={"medicoOpenStatusHistory" + row.original.id}>
                            HISTÓRICO
                          </S.ToolTipBotoes>
                        <S.ButtonIcon onClick={() => openStatusHistory(row.original.id)}>
                          <FaList size={20} cursor='pointer' />
                        </S.ButtonIcon>
                        </S.cardToolTip>
                      </S.Td>
                    }
                    {(isMedico() || (isRadioOperador() && !isStatusRegulacao(row.id))) &&
                      <S.Td>
                        <S.cardToolTip onMouseOver={() => setTooltipVisible(row.original.id, true, "medicoChangeStatus")} onMouseLeave={() => setTooltipVisible(row.original.id, false, "medicoChangeStatus")}>
                          <S.ToolTipBotoes id={"medicoChangeStatus" + row.original.id}>
                            ALTERAR STATUS
                          </S.ToolTipBotoes>
                          <S.ButtonIcon onClick={() => changeStatus(row.original)}>
                            <FaRecycle size={20} cursor='pointer' />
                          </S.ButtonIcon>
                        </S.cardToolTip>
                      </S.Td>
                    }
                  </>
                }
                {type === 'third' &&
                  <>
                    <S.Td>
                      <FaBan size={20} cursor='pointer' />
                    </S.Td>
                    {isMedico() &&
                      <S.Td>
                        <S.ButtonIcon onClick={() => duplicateOccurrence(row.original.id)}>
                          <FaCopy size={20} cursor='pointer' />
                        </S.ButtonIcon>
                      </S.Td>
                    }
                  </>
                }
              </S.Tr>
            );
          })}

        </S.Body>
      </S.Table>
    </S.TableContainer>
  );
};

TableContainer.defaultProps = {
  rowProps: () => ({}),
};

TableContainer.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  rowProps: PropTypes.func,
};

export default TableContainer;
