import api from "./api"

class FuncionarioService{
    
    async getAll(){   
        return api.get("/funcionario/find-all").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getFuncionarioCargoById(id){
        return api.get("/funcionario/find-by-id/" + id).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async saveEmployeeCity(data){   
        return api.put("/funcionario/update", data).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}

const funcionarioService = new FuncionarioService()
export default funcionarioService

