import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
export const SceneForm = styled(Form)`
  padding: 2rem 4rem 2rem 4rem;
`;

export const FixedButton = styled.div`
  position: fixed;
  margin-top: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.darkRed};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    text-align: center;
  `}
`;

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  margin: 0.5rem 0.5rem 0.5rem 0;
`;

export const BlackLabel = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    margin-right: 0.5rem;
  `}
`;

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  text-align: left;
`;

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.darkRed};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    margin-right: 0.5rem;
  `}
`;

export const Text = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-weight: ${theme.fonts.weight.regular};
  `}
`;

export const Subtitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    background-color: ${theme.colors.darkRed};
    height: 4.7rem;
    padding: 1.5rem;
    margin: 0.5rem 0 0.5rem 0;
    text-align: center;
  `}
`;

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export const FormGroup = styled(Form.Group)``;

export const RedContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightRed};
  padding: 10px;
`;

export const ContainerIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.labels.green};
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    width: 2.6rem;
    height: 2.6rem;
    padding: 0.2rem;
    margin-left: 0.2rem;
    margin-bottom: 1rem;
  `}
`;

export const ContainerSelect = styled.div`
  margin-top: 1.63rem;
`;

export const ContainerButton = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FinishButton = styled.div`
  width: auto;
`;


export const InputContainer = styled(InputGroup)`
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
`;

export const FormControl = styled(Form.Control)`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.regular};
    border: 1px solid ${theme.colors.black};
    border-radius: ${theme.border.radius.medium};
    min-height: 3rem;
    width: 100%;
    padding-left: 0.2rem;
  `}
`;
