import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Input = (props) => {
  const { type, label, color, placeholder, value, textarea, onChange, onKeyPress, disabled, useValue } = props;

  const [currentValue, setCurrentValue ] = useState(value);

  const change = (e) => {
    setCurrentValue(e.target.value)
    onChange(e)
  }

  return (
    <div>
      <S.Label color={color}>{label}</S.Label>
      <S.InputContainer>
   {useValue ?
   <>
    { textarea && !disabled ?
          <S.FormControl
            type={type}
            placeholder={placeholder}
            value={value}
            as="textarea"
            rows={5}
            onChange={change}
            onKeyPress={onKeyPress}
          /> : textarea && disabled &&
          <S.FormControl
          type={type}
          placeholder={placeholder}
          value={value}
          as="textarea"
          disabled
          rows={5}
          onChange={change}
          onKeyPress={onKeyPress}
        />
        }
         
        { !textarea && !disabled ?
          <S.FormControl
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={change}
            onKeyPress={onKeyPress}
          /> : !textarea && disabled &&
          <S.FormControl
          disabled
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={change}
          onKeyPress={onKeyPress}
        />
        } 
   </>
   :<>
    { textarea && !disabled ?
          <S.FormControl
            type={type}
            placeholder={placeholder}
            value={currentValue}
            as="textarea"
            rows={5}
            onChange={change}
            onKeyPress={onKeyPress}
          /> : textarea && disabled &&
          <S.FormControl
          type={type}
          placeholder={placeholder}
          value={currentValue}
          as="textarea"
          disabled
          rows={5}
          onChange={change}
          onKeyPress={onKeyPress}
        />
        }
         
        { !textarea && !disabled ?
          <S.FormControl
            type={type}
            placeholder={placeholder}
            value={currentValue}
            onChange={change}
            onKeyPress={onKeyPress}
          /> : !textarea && disabled &&
          <S.FormControl
          disabled
          type={type}
          placeholder={placeholder}
          value={currentValue}
          onChange={change}
          onKeyPress={onKeyPress}
        />
        } 
   </>
   }
       

      </S.InputContainer>
    </div>
  );
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  color: '',
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  color: PropTypes.string,
};

export default Input;
