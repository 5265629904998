import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/layout';
import TableContainer from '../../components/table';
import Modal from '../../components/modal';
import Input from '../../components/input';
import Select from '../../components/select';
import Button from '../../components/button';

import {
  FaArrowLeft,
  FaPlusCircle,
  FaAmbulance,
  FaEdit,
} from "react-icons/fa";

import * as S from './styles';
import veiculoService from '../../services/VeiculoService';
import moment from 'moment';
import Swal from 'sweetalert2';
import funcionarioService from '../../services/FuncionarioService';

const Employees = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState()
  const [funcionariosCargos, setFuncionariosCargos] = useState([])
  const [cargos, setCargos] = useState([])
  const [funcionarios, setFuncionarios] = useState([])
  const [cidades, setCidades] = useState([])
  const [formData, setFormData] = useState({})

  const employeeColumns = [
    { Header: 'Cidade', accessor: 'cidade' },
    { Header: 'Nome', accessor: 'nome' },    
    { Header: 'Cargo', accessor: 'cargo' },
    { Header: '', accessor: 'button' },
  ];

  const loadFuncionariosCargos = () => {
    funcionarioService.getAll().then((response) => {
      let data = response.data

      data = data.map((funcionariocargo) => {

        return {
          cidade: funcionariocargo.cidade?.nome,
          nome: funcionariocargo.funcionario.nome,
          cargo: funcionariocargo.cargo.descricao,
          button: (<FaEdit size={20} cursor='pointer' onClick={() => {
            funcionarioService.getFuncionarioCargoById(funcionariocargo.id).then((result) => {              
              let data = result.data
              setFormData({
                funcionario: data.funcionario.id,
                cargo: data.cargo.id,
                cidade: data.cidade?.id                
              })
              setSelectedEmployee(funcionariocargo)
              setShow(true)
            }).catch((error) => {
              console.log(error)
              alert("Não foi possível recuperar os dados do funcionário")
            })           
            
          }} />)
        }
      })
      setFuncionariosCargos(data)
    }).catch((error) => {
      alert("Não foi possível listar os funcionários")
    })
  }

  const changeField = (field, value) => {
    let data = formData
    data[field] = value
    setFormData(data)
    console.log(data)
  }

  const save = () => {

    let putData = {
      id: selectedEmployee.id, 
      funcionario: formData.funcionario,
      cargo: formData.cargo,
      cidade: formData.cidade      
    }

    console.log(putData)
    funcionarioService.saveEmployeeCity(putData).then((response) => {
      let data = response.data
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Dados salvos com sucesso'
      }).then(() => {
        loadFuncionariosCargos()
        setShow(false)
      })
    }).catch((error) => {
      alert("Não foi possível salvar os dados do funcionário")
    })    
  }

  const loadCargos = () => {
    veiculoService.getCargos().then((response) => {
      let data = response.data
      console.log(data)
      setCargos(data)
    }).catch((error) => {
      alert("Não foi possível listar os cargos")
    })
  }

  const loadFuncionarios = () => {
    veiculoService.getFuncionarios().then((response) => {
      let data = response.data
      console.log("funcionarios", data)
      setFuncionarios(data)
    }).catch((error) => {
      alert("Não foi possível listar os funcionários")
    })
  }

  const loadCidades = () => {
    veiculoService.getCidades().then((response) => {
      let data = response.data
      console.log(data)
      setCidades(data)
    }).catch((error) => {
      alert("Não foi possível listar as cidades")
    })
  }

  useEffect(() => {
    loadFuncionariosCargos()
    loadCargos()
    loadFuncionarios()
    loadCidades()
  }, [])

  return (
    <Layout>
      <S.FixedButton title='Voltar' onClick={() => navigate('/home')}>
        <FaArrowLeft size={30} cursor='pointer' />
      </S.FixedButton>
      <S.EmployeesForm>
        <S.Title>Funcionários</S.Title>
        <TableContainer columns={employeeColumns} data={funcionariosCargos} type="employee" />
      </S.EmployeesForm>
      { (selectedEmployee && formData) && 
        <Modal title='Funcionário' show={show} setShow={setShow}>
          <S.ContainerGrid style={{marginTop: 20}}>
            <S.Text>Funcionário</S.Text>
          </S.ContainerGrid>
          <S.ContainerFlex>
            <Select propValue="fun_Id" propLabel="fun_Descricao" data={funcionarios} value={selectedEmployee.funcionario?.id} onChange={(e) => {
              changeField("funcionario", e.target.value)
            }} />
          </S.ContainerFlex>

          <S.ContainerGrid style={{marginTop: 20}}>
            <S.Text>Cargo</S.Text>
          </S.ContainerGrid>
          <S.ContainerFlex>
            <Select propValue="fcar_ID" propLabel="fcar_descricao" data={cargos} value={selectedEmployee.cargo?.id} onChange={(e) => {
              changeField("cargo", e.target.value)
            }} />
          </S.ContainerFlex>

          <S.ContainerGrid style={{marginTop: 20}}>
            <S.Text>Cidade</S.Text>
          </S.ContainerGrid>
          <S.ContainerFlex>
            <Select propValue="cid_id" propLabel="cid_Descricao" data={cidades} value={selectedEmployee.cidade?.id} onChange={(e) => {
              changeField("cidade", e.target.value)
            }} />
          </S.ContainerFlex>

          <S.ButtonContainer>
            <Button kind='secondary' name='Salvar' onClick={() => save()} />
          </S.ButtonContainer>
        </Modal>
      }
    </Layout>
  );
};

export default Employees;
