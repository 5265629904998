import api from "./api"

class VeiculoService{
    async getActive(){   
        return api.get("/veiculo/find-actives").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getAll(){   
        return api.get("/veiculo/find-all").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getLastSchedule(idVehicle){   
        return api.get("/veiculo/"+ idVehicle +"/last-schedule").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getCargos(){   
        return api.get("/veiculo/cargos").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getStatus(){   
        return api.get("/veiculo/status").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getFuncionarios(){   
        return api.get("/veiculo/funcionarios").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async getCidades(){   
        return api.get("/veiculo/cidades").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async createSchedule(data){   
        return api.post("/veiculo", data).then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
}

const veiculoService = new VeiculoService()
export default veiculoService

