import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/button';
import Layout from '../../components/layout';
import TableContainer from '../../components/table';
import Loading from '../../assets/images/loading.gif';
import moment from 'moment';
import './styles.css'

import {
  FaUserCircle,
  FaFolderPlus,
  FaFilter,
} from "react-icons/fa";

import {
  equipmentColumns,
  secondColumns,
  radioOperadorColumns,
  thirdColumns,
  firstTableData,
  thirdTableData,
  fourthTableData,
  fifthTableData,
  ambulanceStatus,
  tarmColumns,
} from './mock';

import * as S from './styles';
import chamadoService from '../../services/ChamadoService';
import AuthContext from '../../context/auth';
import Modal from '../../components/modal';
import Input from '../../components/input';
import Select from '../../components/select';
import Swal from 'sweetalert2';

const Home = () => {

  const navigate = useNavigate();

  const { user, role } = useContext(AuthContext)

  const [isLoading, setLoading] = useState(true)
  const [isLoadingSave, setLoadingSave] = useState(false)
  const [show, setShow] = useState(false)
  const [showCancelVtr, setShowCancelVtr] = useState(false)
  const [showChangeStatus, setShowChangeStatus] = useState(false)
  const [showFilterModal, setShowFilterModal] = useState(false)
  const [filterData, setFilterData] = useState({})
  const [motivoCancelamentoViatura, setMotivoCancelamentoViatura] = useState("")
  const [observacao, setObservacao] = useState("")
  const [dataStatus, setDataStatus] = useState(moment().format("YYYY-MM-DD"))
  const [horaStatus, setHoraStatus] = useState(moment().format("HH:mm"))
  const [chamados, setChamados] = useState([]);
  const [chamadosAbertos, setChamadosAbertos] = useState([])
  const [statusList, setStatusList] = useState([])
  const [chamadosTransferencia, setchamadosTransferencia] = useState([])
  const [chamadoSelecionado, setChamadoSelecionado] = useState()
  const [statusSelecionado, setStatusSelecionado] = useState()
  const [itemsStatus, setItemsStatus] = useState([]) //StatusHistory
  const [showSelectUnidadeDestino, setShowSelectUnidadeDestino] = useState(false)
  const [unidadeDestinoSelecionada, setUnidadeDestinoSelecionada] = useState()
  const [unidadesDestino, setUnidadesDestino] = useState([])
  const [bairros, setBairros] = useState([])

  const [usaFiltro, setUsaFiltro] = useState(false)
  const [toggleChamadoAtivoFinalizado, setToggleChamadoAtivoFinalizado] = useState(false)
  const [toggleChamadoUltimoDia, setToggleChamadoUltimoDia] = useState(false)
  const [dataInicio, setDataInicio] = useState("")
  const [dataFim, setDataFim] = useState("")
  const [prioridade, setPrioridade] = useState("")
  const [cidade, setCidade] = useState("")
  const [bairro, setBairro] = useState("")
  const [solicitante, setSolicitante] = useState("")
  const [paciente, setPaciente] = useState("")
  const [chamado, setChamado] = useState("")
  const [usuarioChamado, setUsuarioChamado] = useState("")
  const [viatura , setViatura] = useState("")
  const [tipoChamado, setTipoChamado] = useState("")
  const [acaoIntervencao, setAcaoIntervencao] = useState("")
  const [unidadeDestino, setUnidadeDestino] = useState("")
  const [hipoteseDiagnostica, setHipoteseDiagnostica] = useState("")
  const [historicoChamado, setHistoricoChamado] = useState("")


  const [equipments, setEquipments] = useState([
    // {
    //   date: '16/03/2023',
    //   establishment: 'UPA III - Unidade de Pronto Atendimento de Guaratinguetá',
    //   vtr: 'USA 01 - GUARA',
    //   time: '19:35:00',
    // },
    // {
    //   date: '16/03/2023',
    //   establishment: 'UPA III - Unidade de Pronto Atendimento de Guaratinguetá',
    //   vtr: 'USA 02 - GUARA',
    //   time: '19:44:00',
    // }
  ])

  const [listData , setListData] = useState({})

  const loadChamados = () => {
    //setLoading(true)
     
    chamadoService.getAllFields().then((response) => {
      setLoading(false)
      let data = response.data

      data["apoios"] = data["apoios"].map((apoio) => {
        apoio.label = apoio.descricao
        apoio.value = apoio.id
        return apoio
      })
      data["hospitais"] = data["hospitais"].map((hospital) => {
        hospital.descricao = hospital.cidade.nome + " - " + hospital.descricao
        hospital.id = hospital.id
        return hospital
      })
      data["hospitais"].sort((a, b) => (a.descricao > b.descricao) ? 1 : -1)


      data["viaturas"] = data["viaturas"].map((viatura) => {

        try {
          let viaturaNome = viatura.descricao.split("-")[0]
          viatura.id = viatura.id
          viatura.descricao = viatura.base.cidade.nome + " - " + viaturaNome
          return viatura

        } catch {
          viatura.id = viatura.id
          viatura.descricao = viatura.base.cidade.nome + " - " + viatura.descricao
          return viatura
        }

      })
      data["viaturas"].sort((a, b) => (a.descricao > b.descricao) ? 1 : -1)

      let viaturas = data["viaturas"]

      console.log("viaturas",viaturas)


      setListData(data)
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível carregar os dados")
    })

    chamadoService.getAll().then((response) => {
      let data = response.data.chamados
      setHistoricoChamado(response.data.historicos)
      setChamados(data)
      setLoading(false)
    }).catch((error) => {
      //setLoading(false)
      alert("Não foi possível carregar os chamados")
    })
  }

  const loadEquipamentosRetidos = () => {
    //setLoading(true)
    chamadoService.getRetainedEquipments().then((response) => {
      let data = response.data
      let retainedEquipments = data.map((item) => {
        return {
          chamado: item.chamado.id,
          date: moment(item.dataHoraCadastro).locale('pt-br').format("DD/MM/YYYY"),
          establishment: item.hospital.descricao,
          vtr: item.veiculo.descricao,
          time: moment(item.dataHoraAlteracao).locale('pt-br').format("HH:mm")
        }
      })

      setEquipments(retainedEquipments)
    }).catch((error) => {
      //setLoading(false)
      alert("Não foi possível carregar os equipamentos retidos")
    })
  }

  const loadStatus = () => {
    //setLoading(true)
    chamadoService.getStatusList().then((response) => {
      let data = response.data
      console.log(data)
      setStatusList(data)
      //setLoading(false)
    }).catch((error) => {
      //setLoading(false)
      //alert("Não foi possível carregar os chamados")
    })
  }


  const aplicarFiltros = () => {
    let listaChamados =  chamados;
    console.log("olha os chamados abertods" , listaChamados)
    if (chamado) {
       listaChamados = listaChamados.filter( item => {
          return item.id == chamado
      })
    }
    if(cidade){
      listaChamados = listaChamados.filter( item => {
        return item?.chamadoCidade?.nome == cidade
      })
    }
    if(bairro){
      listaChamados = listaChamados.filter( item => {
        return item?.chamadoBairro?.nome == bairro
      })
    }
    if(viatura) { 
      listaChamados = listaChamados.filter( item => {
        return item?.veiculo?.id == viatura
      })
    }
    if(prioridade){
      listaChamados = listaChamados.filter( item => {
        return item?.aphPrioridade == prioridade
      })
    }
    if(hipoteseDiagnostica){
      listaChamados = listaChamados.filter( item => {
        return item?.aphHipoteseDiagnostica == hipoteseDiagnostica
      })
    }
    if(tipoChamado){
      listaChamados = listaChamados.filter( item => {
        return item?.tipo?.descricao == tipoChamado
      })
    }

    if(acaoIntervencao == 1){
      listaChamados = listaChamados.filter( item => {
        return item?.acaoComIntervencao == acaoIntervencao
      })  
    }
    if(acaoIntervencao ==2){
      listaChamados = listaChamados.filter( item => {
        return item?.acaoComIntervencao ==  null
      })
    }
    if(unidadeDestino){
      listaChamados = listaChamados.filter( item => {
        return item?.hospitalDestino?.id == unidadeDestino
      })
    }
    if(dataInicio){
      listaChamados = listaChamados.filter(item => {
        let data = moment(dataInicio).locale('pt-br').format("DD/MM/YYYY");
        let dataFormatada = moment(data, "DD/MM/YYYY", true); 
        let formatDataItem = moment(item.data).locale('pt-br').format("DD/MM/YYYY");
        let dataItem = moment(formatDataItem, "DD/MM/YYYY", true); 
        return dataItem.isSameOrAfter(dataFormatada);
      });
    }
    if(dataFim){
      listaChamados = listaChamados.filter(item => {
        let data = moment(dataFim).locale('pt-br').format("DD/MM/YYYY");
        let dataFormatada = moment(data, "DD/MM/YYYY", true); 
        let formatDataItem = moment(item.data).locale('pt-br').format("DD/MM/YYYY");
        let dataItem = moment(formatDataItem, "DD/MM/YYYY", true); 
        return dataItem.isSameOrBefore(dataFormatada)
      })
    }
    if(solicitante){
      listaChamados = listaChamados.filter( item => {
        console.log("solicitante" , solicitante)
        return item?.solicitante?.toLowerCase().includes(solicitante.toLowerCase());
      })
    }
    if(paciente){
      listaChamados = listaChamados.filter( item => {
        return item?.pacienteNome?.toLowerCase().includes(paciente.toLowerCase());
      })
    }
    if(usuarioChamado){
      let historicos = historicoChamado.filter( item => {
        return  item?.usuario?.username?.toLowerCase().includes(usuarioChamado.toLowerCase()); 
      })
      listaChamados = listaChamados.filter( item => {
        let filtro = historicos.filter((historico) => {
         return historico?.chamado?.id == item.id
        })
        return item.id == filtro[0]?.chamado.id
      })
    }
 
    if(toggleChamadoAtivoFinalizado){
      filtrarChamadosAbertos(listaChamados, false)
      filtrarTransferencias(listaChamados, false)
    }else{
      filtrarChamadosAbertos(listaChamados, true)
      filtrarTransferencias(listaChamados, true)
    }

    if(toggleChamadoUltimoDia){
      filtrarChamadosAbertos(listaChamados, true, true)
      filtrarTransferencias(listaChamados, true, true)
    }

    setUsaFiltro(true)
  }

  const loadAllFields = () => {
    chamadoService.getAllFields().then((response) => {
      let data = response.data

      data["hospitais"] = data["hospitais"].map((hospital) => {
        hospital.descricao = hospital.cidade.nome + " - " + hospital.descricao
        hospital.id = hospital.id
        return hospital
      })
      data["hospitais"].sort((a, b) => (a.descricao > b.descricao) ? 1 : -1)

      setUnidadesDestino(data["hospitais"])
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível carregar os dados")
    })
  }

  useEffect(() => {
    loadStatus()
    loadEquipamentosRetidos()
    loadChamados()
    loadAllFields()
    const interval = setInterval(() => {
      loadEquipamentosRetidos()
      loadChamados()
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    //quando selecionar o status Liberação, mostrar select da unidade de destino
    if (statusSelecionado == 6) {
      setShowSelectUnidadeDestino(true)
    } else {
      setShowSelectUnidadeDestino(false)
    }
  }, [statusSelecionado])

  useEffect(() => {
    if(usaFiltro == false){
      filtrarChamadosAbertos(chamados, true)
      filtrarTransferencias(chamados, true)      
    }else{

    }

  }, [chamados, usaFiltro])

  const tipoIdade = {
    1: "A", //anos
    2: "M", //meses
    3: "D", //dias
    4: "N/A" //não informado
  }

  const getAlert = (chamado) => {
    const MAX_SECONDS_FOR_ALERT = 90
    console.log("esse é o chamado" , chamado)
    if (chamado.dataHoraEnvioUtr == null) {
      if (moment().diff(moment(chamado.dataHoraRegulacao), 'seconds') > MAX_SECONDS_FOR_ALERT) {
        return "blink-red"
      }
    }
    if (chamado.dataHoraInicioRegulacao == null) {
      let aberturaChamado = moment(chamado.data + " " + chamado.hora)
      console.log("aberturaChamado", aberturaChamado)
      if (moment().diff(aberturaChamado, 'seconds') > MAX_SECONDS_FOR_ALERT) {
        return "blink-red"
      }
    }
    if (chamado.dataHoraRegulacao == null) {
      if (moment().diff(chamado.dataHoraInicioRegulacao, 'seconds') > MAX_SECONDS_FOR_ALERT) {
        return "blink-red"
      }
    }
    return ""
  }

  const isTarm = () => {
    return (["ROOT", "TARM", "ADMINISTRADOR"].indexOf(role) >= 0)
  }

  const isRadioOperador = () => {
    return (["ROOT", "RADIO OPERADOR", "ADMINISTRADOR"].indexOf(role) >= 0)
  }

  const isMedico = () => {
    return (["ROOT", "MÉDICO", "ADMINISTRADOR"].indexOf(role) >= 0)
  }

  const isAdmin = () => {
    return (["ROOT", "ADMINISTRADOR"].indexOf(role) >= 0)
  }

  const setTooltipVisible = (tooltipID, visivel) => {
    
    if (visivel) {
      document.getElementById('tooltip:' + tooltipID ).style.display = "flex";
    } else {
      let tooltip = document.getElementById('tooltip:' + tooltipID )
      if (tooltip) {
        tooltip.style.display = "none"
      } else {
        console.log("nao encontrou o tooltip")
      }
    }
 
  }
  const setTooltipAddressVisible = (tooltipID, visivel) => {
    
    if (visivel) {
      document.getElementById('tooltipAddress:' + tooltipID ).style.display = "flex";
    } else {
      let tooltip = document.getElementById('tooltipAddress:' + tooltipID )
      if (tooltip) {
        tooltip.style.display = "none"
      } else {
        console.log("nao encontrou o tooltipAddress")
      }
    }
 
  }
  


  const filtrarChamadosAbertos = (chamados, isActive , last24h) => {

    setLoading(true)
    if (isActive ) {
      let statusList = []
      if (isAdmin) {
        statusList = [1, 2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 14, 15] //todos exceto Concluído e Cancelado
      } else if (isTarm) {
        statusList = [1] //Aguardando regulacao
      } else if (isRadioOperador) {
        statusList = [2, 3, 6, 9, 10, 13, 14, 15]
      } else if (isMedico) {
        statusList = [1, 4, 5, 6, 9, 10, 11, 12, 13, 14, 15]
      }

      let listChamadosAbertos = chamados.filter((chamado) => {

        return (([1].indexOf(chamado.tipo.id) >= 0) //1 = Regulação médica / 6 = Transferência
          && (statusList).indexOf(chamado.status.id) >= 0 )
      })

      listChamadosAbertos = listChamadosAbertos.map((chamado) => {

        let destino = "";

        if(chamado?.hospitalDestino?.descricao){
          destino =  "Destino: " + chamado?.hospitalDestino?.descricao 
        } 

        let cardDoctor = 
          <>
          <S.cardDoctor onMouseOver={() => setTooltipVisible(chamado.id, true)} onMouseLeave={() => setTooltipVisible(chamado.id, false)}>
            <S.Tooltip id={"tooltip:" + chamado.id}>
               {chamado?.usuarioRegulacao?.name}
               <br></br>
               {"Reg: " + chamado?.observacaoMedica}
               <br></br>
               {"APH: " +chamado?.tipoAph?.descricao}
               <br></br>
               {"HD: " +chamado?.aphHipoteseDiagnostica}
               <br></br>
               {
                chamado?.acaoComIntervencao != null && chamado?.acaoComIntervencao == 1 ?
                "Ação c/ Inter.:" + "S"
                :
                "Ação c/ Inter.:" + "N"                                           
               }
               <br></br>
               {"\nData. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("DD/MM/YYYY")}
               {"\nHora. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("HH:mm")}
               <br></br>
               {"Prioridade: " + chamado.aphPrioridade}
               <br></br>
               {"Cód. Desloc: " + chamado?.deslocamento?.id}
              </S.Tooltip>
            <div>
              {chamado.usuarioRegulacao?.name}
            </div>
          </S.cardDoctor>
          </>

          let cardAddress =
          <>
          <S.cardAddress onMouseOver={() => setTooltipAddressVisible(chamado.id, true)} onMouseLeave={() => setTooltipAddressVisible(chamado.id, false)}>
            <S.ToolTipAdrress id={"tooltipAddress:" + chamado.id}>
              {/* {"Cidade: " + chamado?.chamadoCidade?.nome}
              <br></br>
              {"Bairro: " + chamado?.chamadoBairro?.nome}
              <br></br> */}
              {"Logradouro: " + chamado?.chamadoEndereco}
              <br></br>
              {"Número: " + chamado?.enderecoNumero}
              <br></br>
              { 
              chamado?.chamadoPontoReferencia != null ?
              "Complemento: " + chamado?.chamadoPontoReferencia
              : ""
              }
            </S.ToolTipAdrress>
            <div>
              {chamado?.chamadoBairro?.nome}
            </div>
          </S.cardAddress>
          </>
          console.log("o que tem de chamado é isso ", chamado)

          let statusHistory = []
          
          chamadoService.getStatusHistory(chamado.id).then((response) => {
            statusHistory = response.data
          }).catch((error) => {
            alert("Não foi possível abrir o histórico")
          })
          console.log("olha ele ai o status history", statusHistory)

        return {
          usuarioRegulacao: chamado?.usuarioRegulacao?.name,
          vtr: chamado.veiculo?.descricao,
          hospital_id: chamado?.hospitalDestino?.id,
          tipo_acao: chamado?.acaoComIntervencao,
          vtr_id: chamado.veiculo?.id,
          prioridade: chamado.aphPrioridade,
          tipo_chamado: chamado?.tipo?.descricao,
          pr: chamado.status?.descricao,
          history: statusHistory,
          city: chamado.chamadoCidade?.nome,
          neighborhood: cardAddress,
          bairro: chamado.chamadoBairro?.nome,
          requester: chamado.solicitante,
          doctor: cardDoctor,
          patient: chamado.pacienteNome,
          age: chamado.pacienteIdade + tipoIdade[chamado.tipoIdade],
          hd: chamado.aphHipoteseDiagnostica,
          callNumber: chamado.id,
          date: moment(chamado.data, "YYYY-MM-DD").locale('pt-br').format("DD/MM/YYYY"),
          hora: moment(chamado.hora, "HH:mm").locale('pt-br').format("HH:mm"),
          hospital: destino,
          id: chamado.id,
          sequenciaChamadoDuplicado: chamado.sequenciaChamadoDuplicado,
          numeroChamadoDuplicado: chamado.numeroChamadoDuplicado,
          alert: getAlert(chamado),
          status: chamado.status
        }
      })
      setChamadosAbertos(listChamadosAbertos)
    } 
    else
    {
      let statusList = []
      if (isAdmin) {
        statusList = [7, 8] //todos exceto Concluído e Cancelado
      } else if (isTarm) {
        statusList = [1] //Aguardando regulacao
      } else if (isRadioOperador) {
        statusList = [2, 3, 6, 9, 10, 13, 14, 15]
      } else if (isMedico) {
        statusList = [1, 4, 5, 6, 9, 10, 11, 12, 13, 14, 15]
      }
      let listChamadosAbertos = chamados.filter(
        (chamado) => (chamado.status.id == 7 || chamado.status.id ==8) && (chamado.tipo.id != 6 ) 
      );


      listChamadosAbertos = listChamadosAbertos.map((chamado) => {

        let destino = "";

        if(chamado?.hospitalDestino?.descricao){
          destino =  "Destino: " + chamado?.hospitalDestino?.descricao 
        } 
 
        let cardDoctor = 
          <>
          <S.cardDoctor onMouseOver={() => setTooltipVisible(chamado.id, true)} onMouseLeave={() => setTooltipVisible(chamado.id, false)}>
            <S.Tooltip id={"tooltip:" + chamado.id}>
               {chamado?.usuarioRegulacao?.name}
               <br></br>
               {"Reg: " + chamado?.observacaoMedica}
               <br></br>
               {"APH: " +chamado?.tipoAph?.descricao}
               <br></br>
               {"HD: " +chamado?.aphHipoteseDiagnostica}
               <br></br>
               {
                chamado?.acaoComIntervencao != null && chamado?.acaoComIntervencao == 1 ?
                "Ação c/ Inter.:" + "S"
                :
                "Ação c/ Inter.:" + "N"                                           
               }
               <br></br>
               {"\nData. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("DD/MM/YYYY")}
               {"\nHora. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("HH:mm")}
               <br></br>
               {"Prioridade: " + chamado.aphPrioridade}
               <br></br>
               {"Cód. Desloc: " + chamado?.deslocamento?.id}
              </S.Tooltip>
            <div>
              {chamado.usuarioRegulacao?.name}
            </div>
          </S.cardDoctor>
          </>

          let cardAddress =
          <>
          <S.cardAddress onMouseOver={() => setTooltipAddressVisible(chamado.id, true)} onMouseLeave={() => setTooltipAddressVisible(chamado.id, false)}>
            <S.ToolTipAdrress id={"tooltipAddress:" + chamado.id}>
              {/* {"Cidade: " + chamado?.chamadoCidade?.nome}
              <br></br>
              {"Bairro: " + chamado?.chamadoBairro?.nome}
              <br></br> */}
              {"Logradouro: " + chamado?.chamadoEndereco}
              <br></br>
              {"Número: " + chamado?.enderecoNumero}
              <br></br>
              { 
              chamado?.chamadoPontoReferencia != null ?
              "Complemento: " + chamado?.chamadoPontoReferencia
              : ""
              }
            </S.ToolTipAdrress>
            <div>
              {chamado?.chamadoBairro?.nome}
            </div>
          </S.cardAddress>
          </>

        return {
          vtr: chamado.veiculo?.descricao,
          prioridade: chamado.aphPrioridade,
          pr: chamado.status?.descricao,
          city: chamado.chamadoCidade?.nome,
          neighborhood: cardAddress,
          requester: chamado.solicitante,
          doctor: cardDoctor,
          patient: chamado.pacienteNome,
          age: chamado.pacienteIdade + tipoIdade[chamado.tipoIdade],
          hd: chamado.aphHipoteseDiagnostica,
          callNumber: chamado.id,
          date: moment(chamado.data, "YYYY-MM-DD").locale('pt-br').format("DD/MM/YYYY"),
          hora: moment(chamado.hora, "HH:mm").locale('pt-br').format("HH:mm"),
          hospital: destino,
          id: chamado.id,
          sequenciaChamadoDuplicado: chamado.sequenciaChamadoDuplicado,
          numeroChamadoDuplicado: chamado.numeroChamadoDuplicado,
          alert: getAlert(chamado),
          status: chamado.status
        }
      })
      setChamadosAbertos(listChamadosAbertos)
    }
    if(last24h){
      let statusList = []
      if (isAdmin) {
        statusList = [7, 8] //todos exceto Concluído e Cancelado
      } else if (isTarm) {
        statusList = [1] //Aguardando regulacao
      } else if (isRadioOperador) {
        statusList = [2, 3, 6, 9, 10, 13, 14, 15]
      } else if (isMedico) {
        statusList = [1, 4, 5, 6, 9, 10, 11, 12, 13, 14, 15]
      }
      let yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');

     let listChamadosAbertos = chamados.filter(
        (chamado) => (chamado.status.id == 7 || chamado.status.id ==8) 
          && (chamado.tipo.id != 6 ) 
          && (chamado.data == yesterday )
      );

      listChamadosAbertos = listChamadosAbertos.map((chamado) => {

        let destino = "";

        if(chamado?.hospitalDestino?.descricao){
          destino =  "Destino: " + chamado?.hospitalDestino?.descricao 
        } 

        let cardDoctor = 
          <>
          <S.cardDoctor onMouseOver={() => setTooltipVisible(chamado.id, true)} onMouseLeave={() => setTooltipVisible(chamado.id, false)}>
            <S.Tooltip id={"tooltip:" + chamado.id}>
               {chamado?.usuarioRegulacao?.name}
               <br></br>
               {"Reg: " + chamado?.observacaoMedica}
               <br></br>
               {"APH: " +chamado?.tipoAph?.descricao}
               <br></br>
               {"HD: " +chamado?.aphHipoteseDiagnostica}
               <br></br>
               {
                chamado?.acaoComIntervencao != null && chamado?.acaoComIntervencao == 1 ?
                "Ação c/ Inter.:" + "S"
                :
                "Ação c/ Inter.:" + "N"                                           
               }
               <br></br>
               {"\nData. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("DD/MM/YYYY")}
               {"\nHora. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("HH:mm")}
               <br></br>
               {"Prioridade: " + chamado.aphPrioridade}
               <br></br>
               {"Cód. Desloc: " + chamado?.deslocamento?.id}
              </S.Tooltip>
            <div>
              {chamado.usuarioRegulacao?.name}
            </div>
          </S.cardDoctor>
          </>

          let cardAddress =
          <>
          <S.cardAddress onMouseOver={() => setTooltipAddressVisible(chamado.id, true)} onMouseLeave={() => setTooltipAddressVisible(chamado.id, false)}>
            <S.ToolTipAdrress id={"tooltipAddress:" + chamado.id}>
              {/* {"Cidade: " + chamado?.chamadoCidade?.nome}
              <br></br>
              {"Bairro: " + chamado?.chamadoBairro?.nome}
              <br></br> */}
              {"Logradouro: " + chamado?.chamadoEndereco}
              <br></br>
              {"Número: " + chamado?.enderecoNumero}
              <br></br>
              { 
              chamado?.chamadoPontoReferencia != null ?
              "Complemento: " + chamado?.chamadoPontoReferencia
              : ""
              }
            </S.ToolTipAdrress>
            <div>
              {chamado?.chamadoBairro?.nome}
            </div>
          </S.cardAddress>
          </>

        return {
          vtr: chamado.veiculo?.descricao,
          prioridade: chamado.aphPrioridade,
          pr: chamado.status?.descricao,
          city: chamado.chamadoCidade?.nome,
          neighborhood: cardAddress,
          requester: chamado.solicitante,
          doctor: cardDoctor,
          patient: chamado.pacienteNome,
          age: chamado.pacienteIdade + tipoIdade[chamado.tipoIdade],
          hd: chamado.aphHipoteseDiagnostica,
          callNumber: chamado.id,
          date: moment(chamado.data, "YYYY-MM-DD").locale('pt-br').format("DD/MM/YYYY"),
          hora: moment(chamado.hora, "HH:mm").locale('pt-br').format("HH:mm"),
          hospital: destino,
          id: chamado.id,
          sequenciaChamadoDuplicado: chamado.sequenciaChamadoDuplicado,
          numeroChamadoDuplicado: chamado.numeroChamadoDuplicado,
          alert: getAlert(chamado),
          status: chamado.status
        }
      })
      setChamadosAbertos(listChamadosAbertos)

    }
    setLoading(false)
  }

  
  const filterBairros = (idCidade) => {
    let listBairros = []
    const cidadeSelecionada = listData["cidades"].find((cidade) => cidade.nome == idCidade)
    if (cidadeSelecionada) {
      listBairros = cidadeSelecionada.bairros
    }
    listBairros = listBairros.sort((a, b) => (a.nome > b.nome) ? 1 : -1)
    setBairros(listBairros)
  }

  const filtrarTransferencias = (chamados, isActive, last24h) => {
    setLoading(true)
    if (isActive) {
      let listChamados = chamados.filter((chamado) => {
        return [6].indexOf(chamado.tipo.id) >= 0 && chamado.status.id != 8 && chamado.status.id != 7 //6 = Transferência
      })
      listChamados = listChamados.map((chamado) => {
        let cardDoctor = 
        <>
        <S.cardDoctor onMouseOver={() => setTooltipVisible(chamado.id, true)} onMouseLeave={() => setTooltipVisible(chamado.id, false)}>
          <S.Tooltip id={"tooltip:" + chamado.id}>
             {chamado?.usuarioRegulacao?.name}
             <br></br>
             {"Reg: " + chamado?.observacaoMedica}
             <br></br>
             {"APH: " +chamado?.tipoAph?.descricao}
             <br></br>
             {"HD: " +chamado?.aphHipoteseDiagnostica}
             <br></br>
             {
              chamado?.acaoComIntervencao != null && chamado?.acaoComIntervencao == 1 ?
              "Ação c/ Inter.:" + "S"
              :
              "Ação c/ Inter.:" + "N"                                           
             }
             <br></br>
             {"\nData. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("DD/MM/YYYY")}
             {"\nHora. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("HH:mm")}
             <br></br>
             {"Prioridade: " + chamado.aphPrioridade}
             <br></br>
             {"Cód. Desloc: " + chamado?.deslocamento?.id}
            </S.Tooltip>
          <div>
            {chamado.usuarioRegulacao?.name}
          </div>
        </S.cardDoctor>
        </>

        let cardAddress =
        <>
        <S.cardAddress onMouseOver={() => setTooltipAddressVisible(chamado.id, true)} onMouseLeave={() => setTooltipAddressVisible(chamado.id, false)}>
          <S.ToolTipAdrress id={"tooltipAddress:" + chamado.id}>
            {/* {"Cidade: " + chamado?.chamadoCidade?.nome}
            <br></br>
            {"Bairro: " + chamado?.chamadoBairro?.nome}
            <br></br> */}
            {"Logradouro: " + chamado?.chamadoEndereco}
            <br></br>
            {"Número: " + chamado?.enderecoNumero}
            <br></br>
            { 
            chamado?.chamadoPontoReferencia != null ?
            "Complemento: " + chamado?.chamadoPontoReferencia
            : ""
            }
          </S.ToolTipAdrress>
          <div>
            {chamado?.chamadoBairro?.nome}
          </div>
        </S.cardAddress>
        </>

        let origem = "";
        let destino = "";

        if(chamado?.hospitalOrigem?.descricao){
          origem = "Origem: " + chamado?.hospitalOrigem?.descricao 
        }

        if(chamado?.hospitalDestino?.descricao){
          destino =  "Destino: " + chamado?.hospitalDestino?.descricao 
        } 
 
 
        return {
          vtr: chamado.veiculo?.descricao,
          prioridade: chamado.aphPrioridade,
          pr: chamado.status?.descricao,
          city: chamado.chamadoCidade?.nome,
          neighborhood: cardAddress,
          doctor: cardDoctor,
          patient: chamado.pacienteNome,
          age: chamado.pacienteIdade + tipoIdade[chamado.tipoIdade],
          hd: chamado.aphHipoteseDiagnostica,
          callNumber: chamado.id,
          requester: chamado.solicitante,
          date: moment(chamado.data, "YYYY-MM-DD").format("DD/MM/YYYY"),
          hora: moment(chamado.hora, "HH:mm").format("HH:mm"),
          hospital: <div> {origem} <br></br> {destino} </div>, 
          id: chamado.id,
          alert: getAlert(chamado),
          status: chamado.status
        }
      })
      setchamadosTransferencia(listChamados)
    } else {
      let listChamados = chamados.filter((chamado) => (chamado.status.id == 7 || chamado.status.id == 8) && chamado.tipo.id == 6 && chamado.tipo.id != 4   );
      listChamados = listChamados.map((chamado) => {


        let cardDoctor = 
        <>
        <S.cardDoctor onMouseOver={() => setTooltipVisible(chamado.id, true)} onMouseLeave={() => setTooltipVisible(chamado.id, false)}>
        <S.Tooltip id={"tooltip:" + chamado.id}>
           {chamado?.usuarioRegulacao?.name}
           <br></br>
           {"Reg: " + chamado?.observacaoMedica}
           <br></br>
           {"APH: " +chamado?.tipoAph?.descricao}
           <br></br>
           {"HD: " +chamado?.aphHipoteseDiagnostica}
           <br></br>
           {
            chamado?.acaoComIntervencao != null && chamado?.acaoComIntervencao == 1 ?
            "Ação c/ Inter.:" + "S"
            :
            "Ação c/ Inter.:" + "N"                                           
           }
           <br></br>
           {"\nData. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("DD/MM/YYYY")}
           {"\nHora. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("HH:mm")}
           <br></br>
           {"Prioridade: " + chamado.aphPrioridade}
           <br></br>
           {"Cód. Desloc: " + chamado?.deslocamento?.id}
          </S.Tooltip>
        <div>
          {chamado.usuarioRegulacao?.name}
        </div>
      </S.cardDoctor>
      </>

      let cardAddress =
      <>
      <S.cardAddress onMouseOver={() => setTooltipAddressVisible(chamado.id, true)} onMouseLeave={() => setTooltipAddressVisible(chamado.id, false)}>
        <S.ToolTipAdrress id={"tooltipAddress:" + chamado.id}>
          {/* {"Cidade: " + chamado?.chamadoCidade?.nome}
          <br></br>
          {"Bairro: " + chamado?.chamadoBairro?.nome}
          <br></br> */}
          {"Logradouro: " + chamado?.chamadoEndereco}
          <br></br>
          {"Número: " + chamado?.enderecoNumero}
          <br></br>
          { 
          chamado?.chamadoPontoReferencia != null ?
          "Complemento: " + chamado?.chamadoPontoReferencia
          : ""
          }
        </S.ToolTipAdrress>
        <div>
          {chamado?.chamadoBairro?.nome}
        </div>
      </S.cardAddress>

      </>
        return {
          vtr: chamado.veiculo?.descricao,
          prioridade: chamado.aphPrioridade,
          pr: chamado.status?.descricao,
          city: chamado.chamadoCidade?.nome,
          neighborhood: cardAddress,
          doctor:  cardDoctor,
          patient: chamado.pacienteNome,
          age: chamado.pacienteIdade + tipoIdade[chamado.tipoIdade],
          hd: chamado.aphHipoteseDiagnostica,
          callNumber: chamado.id,
          requester: chamado.solicitante,
          date: moment(chamado.data, "YYYY-MM-DD").format("DD/MM/YYYY"),
          hora: moment(chamado.hora, "HH:mm").format("HH:mm"),
          hospital: '',
          id: chamado.id,
          alert: getAlert(chamado),
          status: chamado.status
        }
      })
      setchamadosTransferencia(listChamados)
    }
    if (last24h) {
      let listChamados = chamados.filter((chamado) => (chamado.status.id == 7 || chamado.status.id == 8) && chamado.tipo.id == 6 && chamado.tipo.id != 4 && moment(chamado.data).isSameOrBefore(moment().subtract(1, 'day')) );
      listChamados = listChamados.map((chamado) => {
        let cardDoctor = 
        <>
        <S.cardDoctor onMouseOver={() => setTooltipVisible(chamado.id, true)} onMouseLeave={() => setTooltipVisible(chamado.id, false)}>
        <S.Tooltip id={"tooltip:" + chamado.id}>
           {chamado?.usuarioRegulacao?.name}
           <br></br>
           {"Reg: " + chamado?.observacaoMedica}
           <br></br>
           {"APH: " +chamado?.tipoAph?.descricao}
           <br></br>
           {"HD: " +chamado?.aphHipoteseDiagnostica}
           <br></br>
           {
            chamado?.acaoComIntervencao != null && chamado?.acaoComIntervencao == 1 ?
            "Ação c/ Inter.:" + "S"
            :
            "Ação c/ Inter.:" + "N"                                           
           }
           <br></br>
           {"\nData. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("DD/MM/YYYY")}
           {"\nHora. Reg.: " + moment(chamado?.dataHoraRegulacao ).locale('pt-br').format("HH:mm")}
           <br></br>
           {"Prioridade: " + chamado.aphPrioridade}
           <br></br>
           {"Cód. Desloc: " + chamado?.deslocamento?.id}
          </S.Tooltip>
        <div>
          {chamado.usuarioRegulacao?.name}
        </div>
      </S.cardDoctor>
      </>

      let cardAddress =
      <>
      <S.cardAddress onMouseOver={() => setTooltipAddressVisible(chamado.id, true)} onMouseLeave={() => setTooltipAddressVisible(chamado.id, false)}>
        <S.ToolTipAdrress id={"tooltipAddress:" + chamado.id}>
          {/* {"Cidade: " + chamado?.chamadoCidade?.nome}
          <br></br>
          {"Bairro: " + chamado?.chamadoBairro?.nome}
          <br></br> */}
          {"Logradouro: " + chamado?.chamadoEndereco}
          <br></br>
          {"Número: " + chamado?.enderecoNumero}
          <br></br>
          { 
          chamado?.chamadoPontoReferencia != null ?
          "Complemento: " + chamado?.chamadoPontoReferencia
          : ""
          }
        </S.ToolTipAdrress>
        <div>
          {chamado?.chamadoBairro?.nome}
        </div>
      </S.cardAddress>
        </>

        return {
          vtr: chamado.veiculo?.descricao,
          prioridade: chamado.aphPrioridade,
          pr: chamado.status?.descricao,
          city: chamado.chamadoCidade?.nome,
          neighborhood: cardAddress,
          doctor: cardDoctor,
          patient: chamado.pacienteNome,
          age: chamado.pacienteIdade + tipoIdade[chamado.tipoIdade],
          hd: chamado.aphHipoteseDiagnostica,
          callNumber: chamado.id,
          requester: chamado.solicitante,
          date: moment(chamado.data, "YYYY-MM-DD").format("DD/MM/YYYY"),
          hora: moment(chamado.hora, "HH:mm").format("HH:mm"),
          hospital: '',
          id: chamado.id,
          alert: getAlert(chamado),
          status: chamado.status
        }
      })
      setchamadosTransferencia(listChamados)
    }
    setLoading(false)
  }

  const duplicarChamado = (id, status) => {
    setLoading(true)
    if (status.id == 1) {
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: '"Não é possível duplicar um chamado sem regulação"'
      })
    }
    else {
      chamadoService.duplicate(id).then((response) => {
        loadChamados()
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
        alert("Não foi possível duplicar o chamado")
      })
    }
  }


  const cancel = (chamado) => {
    if (window.confirm("Deseja realmente cancelar o envio da viatura?")) {
      setChamadoSelecionado(chamado)
      setStatusSelecionado(9) //Cancelamento de envio da viatura
      setShowCancelVtr(true)
      setObservacao("")
    }
  }

  const changeStatus = (chamado) => {
    setChamadoSelecionado(chamado)
    setStatusSelecionado(chamado.status?.id)
    setShowChangeStatus(true)
    setObservacao("")
  }

  const saveStatus = (idChamado) => {
    setLoadingSave(true)
    let data = {
      data: dataStatus,
      hora: horaStatus,
      status: statusSelecionado,
      observacao: observacao,
      unidadeDestino: unidadeDestinoSelecionada
    }
    chamadoService.saveStatus(idChamado, data).then((result) => {
      setLoadingSave(false)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Ocorrência atualizada com sucesso'
      }).then(function () {
        setShowChangeStatus(false)
        setShowCancelVtr(false)
      });
    }).catch((error) => {
      setLoadingSave(false)
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: '"Houve um erro ao alterar o status"'
      })
    })
  }

  const openStatusHistory = (id) => {
    setLoading(true)
    chamadoService.getStatusHistory(id).then((response) => {
      setItemsStatus(response.data)
      setLoading(false)
      setShow(true)
    }).catch((error) => {
      setLoading(false)
      setShow(false)
      alert("Não foi possível abrir o histórico")
    })
  }

  const openFilterChamados = () => {
    setShowFilterModal(true)
  } 

  const openNewSceneForm = () => {
    localStorage.setItem("action", "NEW");
    localStorage.setItem("user", user);
    localStorage.setItem("role", role);
    navigate("/cena")
  }

  return (
    <Layout>
      <S.Container>
        {isLoading &&
          <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
        }
        <S.ContainerFlex>
          <S.Wrapper>
            <S.NewCall>
              {(isTarm() || isRadioOperador()) &&
                <Button kind='secondary' name='Novo Chamado' onClick={() => openNewSceneForm()} hasIcon>
                  <FaFolderPlus size={25} />
                </Button>
              }
            </S.NewCall>
          </S.Wrapper>
          {isAdmin() ?
          <>
          <S.Wrapper>
            <S.NavLink isActive={!toggleChamadoAtivoFinalizado} onClick={() => {
              filtrarChamadosAbertos(chamados, true)
              filtrarTransferencias(chamados, true)
              setToggleChamadoAtivoFinalizado(false)
            }}>
              {/* <FaUser size={25} /> */}
              <S.NavItem>Ativos</S.NavItem>
            </S.NavLink>
          </S.Wrapper>
          <S.Wrapper>
            <S.NavLink isActive={toggleChamadoAtivoFinalizado} onClick={() => {
              filtrarChamadosAbertos(chamados, false)
              filtrarTransferencias(chamados, false)
              setToggleChamadoAtivoFinalizado(true)
              setUsaFiltro(true)
            }}>
              {/* <FaUser size={25} /> */}
              <S.NavItem>Finalizados</S.NavItem>
            </S.NavLink>
          </S.Wrapper>
          </>
          :
          <>
          <S.Wrapper>
            <S.NavLink isActive={!toggleChamadoUltimoDia} onClick={() => {
              filtrarChamadosAbertos(chamados, true)
              filtrarTransferencias(chamados, true)
              setToggleChamadoUltimoDia(false)
              //setUsaFiltro(false)
            }}>
              {/* <FaUser size={25} /> */}
              <S.NavItem>Ativos</S.NavItem>
            </S.NavLink>
          </S.Wrapper>
          <S.Wrapper>
            <S.NavLink isActive={toggleChamadoUltimoDia} onClick={() => {
              filtrarChamadosAbertos(chamados, false, true)
              filtrarTransferencias(chamados, false, true)
              setToggleChamadoUltimoDia(true)
              setUsaFiltro(true)
            }} >
              {/* <FaUser size={25} /> */}
              <S.NavItem>Últimas 24h</S.NavItem>
            </S.NavLink>
          </S.Wrapper>
          </>
          }

          {(isRadioOperador() || isAdmin())   &&
            <S.Wrapper>
              <S.Title>Equipamentos Retidos</S.Title>
              <TableContainer columns={equipmentColumns} data={equipments} type='first' />
            </S.Wrapper>
          }
        </S.ContainerFlex>
        <S.Wrapper>
            <S.NewCall>
                <Button kind='secondary' name='Filtrar Chamados' onClick={() => openFilterChamados()} hasIcon>
                  <FaFilter size={25} />
                </Button>          
            </S.NewCall>
          </S.Wrapper>
        <S.Title>Urgências / Emergências</S.Title>
        {(isMedico()) && !isAdmin() &&
        <>
          <TableContainer columns={secondColumns} data={chamadosAbertos} type='second' cancel={cancel} 
          changeStatus={changeStatus} duplicateOccurrence={duplicarChamado} 
          openStatusHistory={openStatusHistory} filtros={chamadosAbertos} setFiltros={setChamadosAbertos} usaFiltro={setUsaFiltro} />
          </>
        }
        {(isRadioOperador()) && !isAdmin() &&
          <TableContainer columns={radioOperadorColumns} data={chamadosAbertos} type='second' cancel={cancel} changeStatus={changeStatus} duplicateOccurrence={duplicarChamado} openStatusHistory={openStatusHistory}  filtros={chamadosAbertos} setFiltros={setChamadosAbertos} usaFiltro={setUsaFiltro} />
        }
        {(isTarm()) && !isAdmin() &&
          <TableContainer columns={tarmColumns} data={chamadosAbertos} type='second' cancel={cancel} changeStatus={changeStatus} duplicateOccurrence={duplicarChamado} openStatusHistory={openStatusHistory}  filtros={chamadosAbertos} setFiltros={setChamadosAbertos} usaFiltro={setUsaFiltro}  />
        }
        {isAdmin() &&
          <TableContainer columns={secondColumns} data={chamadosAbertos} type='second' cancel={cancel} changeStatus={changeStatus} duplicateOccurrence={duplicarChamado} openStatusHistory={openStatusHistory}   filtros={chamadosAbertos} setFiltros={setChamadosAbertos} usaFiltro={setUsaFiltro}  />
        }
        <S.Title>TRANSFERÊNCIA / INTERNAÇÕES / EXAMES</S.Title>
        <TableContainer columns={secondColumns} data={chamadosTransferencia} type='second' cancel={cancel} changeStatus={changeStatus} duplicateOccurrence={duplicarChamado} openStatusHistory={openStatusHistory} filtrosTransferencia={chamadosTransferencia} setFiltrosTransferencia={setchamadosTransferencia}  usaFiltro={setUsaFiltro} />

        {/* <S.Title>Urgências / Emergências</S.Title>
        <TableContainer columns={thirdColumns} data={fourthTableData} type='third' />
        <S.Title>TRANSFERÊNCIA / INTERNAÇÕES / EXAMES</S.Title>
        <TableContainer columns={thirdColumns} data={fifthTableData} type='third' /> */}
        {/* <S.Title>Status Veículos</S.Title> */}
        {/* <Card data={ambulanceStatus} /> */}
      </S.Container>
      <Modal title='Histórico do Chamado' show={show} setShow={setShow} >
        {isLoadingSave &&
              <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
        }
        <S.ContainerFlex>
          <table>
            <thead>
              <tr>
                <th>Data/Hora</th>
                <th>Status</th>
                <th>Usuário</th>
                <th>Observação</th>
                <th style={{ textAlign: "center" }}>Regulacao</th>
                <th style={{ textAlign: "center" }}>APH</th>
                <th style={{ textAlign: "center" }}>HD</th>
                <th style={{ textAlign: "center" }}>Deslocamento</th>
                <th>Prioridade</th>
              </tr>
            </thead>
            <tbody>
              {itemsStatus.map((item) => {
                console.log("esse é o item, " , item)
              if(item.regulacaoObservacao != null && <></>){
                return (
                  <tr>
                    <td>{moment(item.dataHora).format("DD/MM/YYYY HH:mm")}</td>
                    <td>{item.status.descricao}</td>
                    <td>{item.usuario.name}</td>
                    <td style={{ textAlign: "center" }} >{item.observacao}</td>
                    <td style={{ textAlign: "center" }}>{item?.regulacaoObservacao}</td>
                    <td style={{ textAlign: "center" }}>{item?.regulacaoAPH?.descricao}</td>
                    <td style={{ textAlign: "center" }}>{item?.regulacaoHD?.descricao}</td>
                    <td style={{ textAlign: "center" }}>{item?.regulacaoCodigoDeslocamento}</td>
                    {item?.regulacaoPrioridade == 1 && 
                    <td style={{ backgroundColor: "#c00", textAlign: "center" }}>{item?.regulacaoPrioridade}</td>
                    }
                    {item?.regulacaoPrioridade == 3 && 
                    <td style={{ backgroundColor: "#fc0", textAlign: "center" }}>{item?.regulacaoPrioridade}</td>
                    }
                    {item?.regulacaoPrioridade == 4 && 
                    <td style={{ backgroundColor: "#0c0", textAlign: "center" }}>{item?.regulacaoPrioridade}</td>
                    }
                    {item?.regulacaoPrioridade == 5 && 
                    <td style={{ backgroundColor: "#00f", textAlign: "center" }}>{item?.regulacaoPrioridade}</td>
                    }

                  </tr>
                )
              }
              if(item.regulacaoObservacao == null && <></>){
                  return (
                  <tr>
                    <td>{moment(item?.dataHora).format("DD/MM/YYYY HH:mm")}</td>
                    <td>{item?.status.descricao}</td>
                    <td>{item?.usuario.name}</td>
                    <td>{item?.observacao}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )
                } 
              }
              )}
            </tbody>
          </table>
        </S.ContainerFlex>
        <S.ButtonContainer>
          {!isLoadingSave &&
            <Button kind='secondary' name='Salvar' onClick={() => { }} />
          }
          {isLoadingSave &&
            <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
          }
        </S.ButtonContainer>
      </Modal>

      <Modal title='Cancelamento do Envio da Viatura' show={showCancelVtr} setShow={setShowCancelVtr}>
        <S.ContainerFlex>
          <Input color='black' type='text' textarea label='Motivo:' value={observacao} onChange={(e) => setObservacao(e.target.value)} />
        </S.ContainerFlex>
        <S.ButtonContainer>
          {!isLoadingSave &&
            <Button kind='secondary' name='Salvar' onClick={() => saveStatus(chamadoSelecionado?.id)} />
          }
          {isLoadingSave &&
            <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
          }
        </S.ButtonContainer>
      </Modal>


      <Modal title='Filtro de Chamados' show={showFilterModal} setShow={setShowFilterModal}>
        <S.ContainerFlex>
          <div style={{ flex: 1, display: "grid" , gridTemplateColumns: "repeat(7, 1fr)", gap: 10  }}>
            <Input useValue  type='datetime-local' label='Data Inicial' value={dataInicio} onChange={(e) => {
              setFilterData("dataInicio", e.target.value)
              setDataInicio(e.target.value)
              }} />
            <Input useValue type='datetime-local' label='Data Final' value={dataFim} onChange={(e) =>{
              setFilterData("dataFim", e.target.value)
              setDataFim(e.target.value)
            } } /> 

            <Input useValue type='text' label='Usuário Chamado' value={usuarioChamado} onChange={(e) => setUsuarioChamado(e.target.value)} />
            <Input useValue type='text' label='Nome Solicitante' value={solicitante} onChange={(e) => setSolicitante(e.target.value)} />
            <Input useValue type='text' label='Nome Paciente' value={paciente} onChange={(e) => setPaciente(e.target.value)} />

              <S.Wrapper>
              {listData["cidades"] && listData["cidades"].length > 0 &&
                <Select propLabel='name' propValue='name' data={listData["cidades"].map((item) => { return { value: item.id, name: item.nome } })}
                  label='Cidade' value={cidade} onChange={(e) => {
                    setCidade(e.target.value)
                    filterBairros(e.target.value)
                  }} />
              }
            </S.Wrapper>
            <S.Wrapper>
            <Select data={bairros.map((item) => { return { value: item.id, name: item.nome } })}
             label='Bairro' propValue='name' propLabel='name' value={bairro} onChange={(e) => setBairro(e.target.value)} />
            </S.Wrapper>

            <S.Wrapper>
            <Select data={ chamados.map((item) =>  { return { value: item.id , name:item.id}})}  label='Chamado' value={chamado}
            onChange={(e) => {
              setChamado(e.target.value)
            }} />
            </S.Wrapper>
 

            <S.Wrapper>
            {listData["viaturas"] && listData["viaturas"].length > 0 &&
              <Select data={listData["viaturas"].map((item) => { return { value: item.id, name: item.descricao } })} label= 'Viatura' 
              value={viatura} onChange={(e) => setViatura(e.target.value)} />
            }
            </S.Wrapper>
            <S.Wrapper>
              <Select data={[{value: "1", name: "Vermelho"}, {value: "3", name: "Amarelo"} , {value: "4", name: "Verde"} , {value: "5", name: "Azul"}]} label='Prioridade' value={prioridade} onChange={(e) => setPrioridade(e.target.value)} />
            </S.Wrapper>
            <S.Wrapper>
              {listData["hipotesesDiagnosticas"] && listData["hipotesesDiagnosticas"].length > 0 &&
                <Select data={listData["hipotesesDiagnosticas"].map((item) => { return { value: item.id, name: item.descricao } }).sort((a, b) => (a.name > b.name) ? 1 : -1)} label='HD' propLabel='name' propValue='name' value={hipoteseDiagnostica} onChange={(e) => setHipoteseDiagnostica(e.target.value)} />
              }
            </S.Wrapper>
            <S.Wrapper>
              {listData["tipoChamado"] && listData["tipoChamado"].length > 0 &&
              <Select data={listData["tipoChamado"].map((item) => { return { value: item.id, name: item.descricao } })} propLabel='name' propValue='name' type='text' label='Tipo Chamado' value={tipoChamado} onChange={(e) => setTipoChamado(e.target.value)} />
              }
            </S.Wrapper>
            <S.Wrapper>
              {listData["chamadoTipoAcao"] && listData["chamadoTipoAcao"].length > 0 &&
                <Select data={listData["chamadoTipoAcao"].map((item) => { return { value: item.id, name: item.descricao } })} label='Ação Intervenção' value={acaoIntervencao} onChange={(e) => setAcaoIntervencao(e.target.value)} />
              }
            </S.Wrapper>
            <S.Wrapper>
              {listData["hospitais"] && listData["hospitais"].length > 0 &&
                <Select data={listData["hospitais"].map((item) => { return { value: item.id, name: item.descricao } })} label='Unidade Destino' value={unidadeDestino} onChange={(e) => setUnidadeDestino(e.target.value)} />
              }
            </S.Wrapper>
          </div>
        </S.ContainerFlex>
        <S.ButtonContainer>
          {!isLoadingSave &&
          <>
            <Button kind='secondary' name='Aplicar Filtros' onClick={() =>
            {
              setUsaFiltro(true)
              aplicarFiltros()
              setShowFilterModal(false)
            } } />

            <Button kind='primary' name='Limpar Filtros' onClick={() => 
              {

                setDataInicio("")
                setDataFim("")
                setCidade("")
                setBairro("")
                setChamado("")
                setUsuarioChamado("")
                setSolicitante("")
                setPaciente("")
                setViatura("")
                setPrioridade("")
                setHipoteseDiagnostica("")
                setTipoChamado("")
                setAcaoIntervencao("")
                setUnidadeDestino("")

                { 
                  toggleChamadoUltimoDia &&
                  setToggleChamadoUltimoDia(false)
                }
                {
                  toggleChamadoAtivoFinalizado &&
                  setToggleChamadoAtivoFinalizado(false)
                }
                setUsaFiltro(false)
              }
            } />
          </>
          }
          {isLoadingSave &&
            <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
          }
        </S.ButtonContainer>
      </Modal>

      <Modal title={`Alteração de Status - Ocorrência: #${chamadoSelecionado?.id}`} show={showChangeStatus} setShow={setShowChangeStatus}>
        {isLoading &&
          <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
        }
        <S.ContainerGrid style={{ marginTop: 20 }}>
          <S.Text>Novo Status</S.Text>
        </S.ContainerGrid>
        <S.ContainerFlex>
          <Input color='black' type='date' label='Data' value={dataStatus} onChange={(e) => {
            //console.log("e.target.value", e.target.value)
            setDataStatus(e.target.value)
          }} />
          <Input color='black' type='time' label='Hora' value={horaStatus} onChange={(e) => {
            // console.log("e.target.value", e.target.value)
            setHoraStatus(e.target.value)
          }} />
        </S.ContainerFlex>
        <S.ContainerFlex>
          <Select propValue="id" propLabel="descricao" data={statusList} value={chamadoSelecionado?.status?.id} onChange={(e) => {
            setStatusSelecionado(e.target.value)
          }} />
        </S.ContainerFlex>

        {showSelectUnidadeDestino == true &&
          <>
            <span style={{ fontSize: 16, fontWeight: "bold", marginTop: 10, fontFamily: "Arial", display: "block" }}>Unidade Destino</span>
            <S.ContainerFlex style={{ marginBottom: 10 }}>
              <Select propValue="id" propLabel="descricao" data={unidadesDestino} onChange={(e) => {
                setUnidadeDestinoSelecionada(e.target.value)
              }} />
            </S.ContainerFlex>
          </>
        }
        <Input color='black' type='text' textarea label='Observação:' value={observacao} onChange={(e) => setObservacao(e.target.value)} />

        <S.ButtonContainer>
          {!isLoadingSave &&
            <Button kind='secondary' name='Salvar' onClick={() => saveStatus(chamadoSelecionado?.id)} />
          }
          {isLoadingSave &&
            <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
          }
        </S.ButtonContainer>
      </Modal>

    </Layout>
  );
};

export default Home;
