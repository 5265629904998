import React from 'react';
import PropTypes from 'prop-types';

import Navbar from './navbar';
import * as S from './styles';

const Layout = (props) => (
  <S.Container>
    <Navbar />
    <S.Wrapper>
      {props.children}
    </S.Wrapper>
  </S.Container>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
