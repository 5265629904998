import styled, { css } from 'styled-components';
import Form from 'react-bootstrap/Form';

export const WorkScheduleForm = styled(Form)`
  padding: 2rem 4rem 2rem 4rem;
`;

export const FixedButton = styled.div`
  position: fixed;
  margin-top: 1rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.darkRed};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    text-align: center;
  `}
`;

export const ContainerFlex = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 5rem;
`;

export const Text = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    margin-right: 0.5rem;
  `}
`;

export const ContainerIcon = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.labels.green};
    background-color: ${theme.colors.white};
    border-radius: ${theme.border.radius.medium};
    margin-bottom: 3rem;
  `}
`;

export const ButtonContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
