import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';

import Layout from '../../components/layout';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import Input from '../../components/input';
import Select from '../../components/select';
import Loading from '../../assets/images/loading.gif';

import {
  tipoChamadoData,
  tipoVeiculoData,
  secondCheckboxData,
  thirdCheckboxData,
  fourthCheckboxData,
  fifthCheckboxData,
  sixthCheckboxData,
  seventhCheckboxData,
  ageData,
  medicoRegulacaoData,
  genderData,
  aphData,
  diagnosticData,
  sweatingData,
  skinColoringData,
  bleedingData,
  woundData,
  woundSiteData,
  typeOfProcedureData,
  procedureData,
  conductData,
  medicoCenaData,
  equipeEnfermagemData,
  usersData,
  viaturaData,
  equipeViaturaData,
  hdData,
  acaoComIntervencaoData,
  encerramentoData,
  deslocamentoData,
  motivoTransferenciaData,
  tipoEquipeContatoData
} from '../../util/checkboxOptions';

import {
  FaArrowLeft,
  FaGlobeAmericas,
  FaPlusCircle,
  FaClipboardCheck,
} from "react-icons/fa";

import * as S from './styles';
import chamadoService from '../../services/ChamadoService';
import AuthContext from '../../context/auth';
import Swal from 'sweetalert2';
import moment from 'moment';
import veiculoService from '../../services/VeiculoService';

const SceneForm = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const { user, role } = { user:localStorage.getItem("user") ,  role:localStorage.getItem("role")}
  const {login} = useContext(AuthContext)
  login(user,role)

  const [action, setAction] = useState(localStorage.getItem("action"))
  if(action =="NEW"){
    localStorage.removeItem("id")
  }
  const [id, setId] = useState(location.state?.id) //id do chamado
  const [listData, setListData] = useState({})
  const [formData, setFormData] = useState({})
  const [showCoordenadas, setShowCoordenadas] = useState(false)
  const [motivoTransferencia, setMotivoTransferencia] = useState()
  const [tipoChamado, setTipoChamado] = useState(1) //regulacao, por padrão
  const [bairros, setBairros] = useState([])
  const [bairrosPaciente, setBairrosPaciente] = useState([])
  const [chamado, setChamado] = useState(null)
  const [motivoEncerramento, setMotivoEncerramento] = useState(null)
  const [viaturas, setViaturas] = useState([])
  const [viaturaSelecionada, setViaturaSelecionada] = useState()
  const [equipeViatura, setEquipeViatura] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isLoadingEncerramento, setLoadingEncerramento] = useState(false)
  const [isSemIntervencao, setSemIntervencao] = useState(false)
  const [tipoVeiculo, setTipoVeiculo] = useState([3])
  const [tipoAcaoComIntervencao, setTipoAcaoComIntervencao] = useState([])
  const [tipoAcaoSemIntervencao, setTipoAcaoSemIntervencao] = useState([])
  const [hipoteseDiagnostica, setFilterHD] = useState([])

  const loadListData = () => {
    setLoading(true)
    chamadoService.getAllFields().then((response) => {
      setLoading(false)
      let data = response.data


      data["apoios"] = data["apoios"].map((apoio) => {
        apoio.label = apoio.descricao
        apoio.value = apoio.id
        return apoio
      })
      data["hospitais"] = data["hospitais"].map((hospital) => {
        hospital.descricao = hospital.cidade.nome + " - " + hospital.descricao
        hospital.id = hospital.id
        return hospital
      })
      data["hospitais"].sort((a, b) => (a.descricao > b.descricao) ? 1 : -1)

      setFilterHD(data["hipotesesDiagnosticas"])

      data["viaturas"] = data["viaturas"].map((viatura) => {

        try {
          let viaturaNome = viatura.descricao.split("-")[0]
          viatura.id = viatura.id
          viatura.descricao = viatura.base.cidade.nome + " - " + viaturaNome
          return viatura

        } catch {
          viatura.id = viatura.id
          viatura.descricao = viatura.base.cidade.nome + " - " + viatura.descricao
          return viatura
        }

      })
      data["viaturas"].sort((a, b) => (a.descricao > b.descricao) ? 1 : -1)

      let viaturas = data["viaturas"]

      console.log("viaturas",viaturas)

      setViaturas(viaturas)
      filtrarViaturasPorCidade()

      setListData(data)
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível carregar os dados")
    })


    try {


      let action = localStorage.getItem("action")
      const idDoChamado = localStorage.getItem("id")
      //if (action == "READ") {
      if (idDoChamado) {
        setLoading(true)
        chamadoService.getById(idDoChamado).then((response) => {
          let chamado = response.data
          console.log("esse é o chamado", chamado)
          setTipoChamado(chamado.tipo?.id)
          setChamado(chamado)
          changeField("deslocamento", chamado.deslocamento?.id)
          changeField("apoio", chamado.apoios.map((apoio) => apoio.id))
          changeField("observacao", chamado.conteudoRegistrado)

          if (chamado?.acaoComIntervencao == 1) {
            setTipoAcaoComIntervencao([1])
          } else {
            setTipoAcaoComIntervencao([0])
          }

          if (chamado?.acaoSemIntervencao?.id > 0) {
            setTipoAcaoSemIntervencao([chamado?.acaoSemIntervencao.id])
            setTipoAcaoComIntervencao([2])
            changeField("chamadoTipoAcao", "2")
            changeField("chamadoTipoAcaoSemIntervencao", chamado?.acaoSemIntervencao.id)
          } else if (chamado?.acaoComIntervencao == 1) {
            setTipoAcaoComIntervencao([1])
            changeField("chamadoTipoAcao", "1")
            setTipoAcaoSemIntervencao([0])
          } else {
            setTipoAcaoSemIntervencao([0])
            setTipoAcaoComIntervencao([0])
          }

          if (chamado?.aphHipoteseDiagnostica != "" && chamado?.aphHipoteseDiagnostica != undefined) {
            changeField("hipoteseDiagnostica", chamado.aphHipoteseDiagnostica)
          }
          if (chamado?.observacaoMedica != "" && chamado?.observacaoMedica != undefined) {
            changeField("regulacaoMedica", chamado?.observacaoMedica)
          }

          if (chamado?.tipoAph?.id != "" && chamado?.tipoAph?.id != undefined) {
            changeField("aph", chamado?.tipoAph?.id)
          }

          if (chamado?.aphPrioridade != "" && chamado?.aphPrioridade != undefined) {
            changeField("prioridade", chamado.aphPrioridade)
          }

          if (chamado?.tipoVeiculo?.id == 1) {
            setTipoVeiculo(1)
            changeField("tipoVeiculo", [1])
          } else if (chamado?.tipoVeiculo?.id == 2) {
            setTipoVeiculo(2)
            changeField("tipoVeiculo", [2])
          } else {
            setTipoVeiculo([])
            changeField("tipoVeiculo", [])
          }
          console.log("chamado.tipoVeiculo", chamado.tipoVeiculo)

          setLoading(false)
        }).catch((error) => {
          console.log(error)
          alert("Não foi possível carregar os dados do chamado")
        })
      }
    } catch {
      alert("Não foi possível carregar os dados do chamado")

    }
  }

  useEffect(() => {
    loadListData()
    
  }, [])



  const isTipoChamadoDuplicidade = () => {
    return (tipoChamado == 14)
  }

  const isTipoChamadoTrote = () => {
    return (tipoChamado == 3)
  }

  const isReadyToFinish = () => {
    let statusChamado = chamado?.status.id
    if (chamado?.observacaoMedica?.trim() != "" && chamado?.observacaoMedica) { //se tem regulacao
      //o médico pode encerrar a qualquer momento, exceto quando a viatura foi enviada
      if (statusChamado != 3) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

    //regra antiga
    // if (statusChamado == 6 || statusChamado == 8 || statusChamado == 9) {
    //   return true
    // } else {
    //   return false
    // }
  }

  const isTipoChamadoRegulacaoTransferencia = () => {
    return ([1, 6].indexOf(tipoChamado) >= 0)
  }

  const isTipoChamadoEquipeContato = () => {
    return ([11].indexOf(tipoChamado) >= 0)
  }

  const isTipoChamadoTransferencia = () => {
    return ([6].indexOf(tipoChamado) >= 0)
  }

  const isTipoChamadoRegulacao = () => {
    //console.log("tipoChamado", tipoChamado)
    return ([1].indexOf(tipoChamado) >= 0)
  }

  const changeField = (field, value) => {
    let data = formData
    data[field] = value
    setFormData(data)
    console.log(data)
  }
  const setDadosTipoAcaoComIntervencao = (e) => {
    if (e == 0) {
      let data = formData
      data["chamadoTipoAcaoSemIntervencao"] = ""
      setFormData(data)
      setTipoAcaoSemIntervencao([0])
    } else if (e == 1) {
      let data = formData
      data["chamadoTipoAcaoSemIntervencao"] = ""
      setFormData(data)
      setTipoAcaoSemIntervencao([0])
    }
  }
  const setDadosTipoAcaoSemIntervencao = (e) => {
    if (e > 0) {
      let data = formData
      data["chamadoTipoAcao"] = 2
      setFormData(data)
    }
  }

  const addCheckField = (field, value, toAdd) => {
    let data = formData
    if (data[field]) {
      if (toAdd) {
        data[field].push(value)
      } else {
        data[field] = data[field].filter((id) => id != value)
      }
    } else {
      data[field] = [value]
    }
    setFormData(data)
    console.log(data)
  }

  const filterBairros = (idCidade) => {
    let listBairros = []
    const cidadeSelecionada = listData["cidades"].find((cidade) => cidade.id == idCidade)
    if (cidadeSelecionada) {
      listBairros = cidadeSelecionada.bairros
    }
    listBairros = listBairros.sort((a, b) => (a.nome > b.nome) ? 1 : -1)
    setBairros(listBairros)
  }
  const filterHipotesesDiagnosticas = (idAPh) => {
    console.log("está passando o id" , idAPh)
    let hipoteses = []
    console.log("nesse momento, o hipotese selecionada é", hipoteseDiagnostica)
    const aphSelecionada = listData["hipotesesDiagnosticas"].filter((aph) => aph.idAPH == idAPh)
    if(aphSelecionada){
      hipoteses = aphSelecionada
    }
    hipoteses = hipoteses.sort((a, b) => (a.descricao > b.descricao) ? 1 : -1)
    console.log("hipoteses filtradas ", hipoteses)
    setFilterHD(hipoteses)
  }

  const filterBairrosPaciente = (idCidade) => {
    let listBairros = []
    const cidadeSelecionada = listData["cidades"].find((cidade) => cidade.id == idCidade)
    if (cidadeSelecionada) {
      listBairros = cidadeSelecionada.bairros
    }
    listBairros = listBairros.sort((a, b) => (a.nome > b.nome) ? 1 : -1)
    setBairrosPaciente(listBairros)
  }

  const saveRegulacao = () => {

    let data = formData
    data.id = chamado?.id
    let camposPrenchidosRegulacao = verificaCamposFormularioRegulacaoMedico(data)
    if (camposPrenchidosRegulacao.validado && camposPrenchidosRegulacao.campos.length == 0) {
      console.log("saveRegulacao", data)
      setLoading(true)
      chamadoService.saveRegulacao(data).then((response) => {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Regulação médica foi salva com sucesso'
        }).then(function () {
          navigate("/home")
        });
      }).catch((error) => {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          text: '"Houve um erro ao salvar a regulação"'
        })
      })
    }
    if (camposPrenchidosRegulacao.campos.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        html: camposPrenchidosRegulacao.campos.map((campo) => '<li>' + campo + '</li>').join("")
      })
    }
  }

  function verificaCamposFormularioRegulacaoMedico(data) {
    let validacaoCampos = {
      validado: false,
      campos: []
    }
    // if (tipoVeiculo === 1 || tipoVeiculo === 2) {
    //     validacaoCampos.validado = true
    // }else {
    //   validacaoCampos.validado = false
    //   validacaoCampos.campos.push("Informe o tipo do veículo");
    // }
    if (data.aph != "" && data.aph != undefined) {
      validacaoCampos.validado = true
    } else {
      validacaoCampos.validado = false
      validacaoCampos.campos.push("Selecione o APH");
    }
    if (data.hipoteseDiagnostica != "" && data.hipoteseDiagnostica != undefined) {
      validacaoCampos.validado = true
    } else {
      validacaoCampos.validado = false
      validacaoCampos.campos.push("Informe a hipotese diagnóstica");
    }
    if (data.regulacaoMedica != "" && data.regulacaoMedica != undefined) {
      validacaoCampos.validado = true
    } else {
      validacaoCampos.validado = false
      validacaoCampos.campos.push("Informe a regulação médica");
    }
    if (data.prioridade != "" && data.prioridade != undefined) {
      validacaoCampos.validado = true
    } else {
      validacaoCampos.validado = false
      validacaoCampos.campos.push("Selecione a prioridade");
    }
    if (data.chamadoTipoAcao != "" && data.chamadoTipoAcao != undefined) {
      validacaoCampos.validado = true
    } else {
      validacaoCampos.validado = false
      validacaoCampos.campos.push("Selecione o tipo de ação")
    }
    if(data.chamadoTipoAcao == 1 ){
      if(data.tipoVeiculo!= "" && data.tipoVeiculo != undefined){
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione o tipo de veículo")
      }
    }

    if (data.chamadoTipoAcao == 2) {
      if (data.chamadoTipoAcaoSemIntervencao != "" && data.chamadoTipoAcaoSemIntervencao != 0 && data.chamadoTipoAcaoSemIntervencao != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Informe o motivo sem intervenção")
      }
    }
 
    console.log("tipochamado" , tipoChamado)
    if(data.deslocamento != "" && data.deslocamento != undefined){
      validacaoCampos.validado = true   
    }else{
      if(tipoChamado == 6 ){
        validacaoCampos.validado = true
      }else if (data.chamadoTipoAcao  == 2) {
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione o código de deslocamento")
      }
      
    }


    return validacaoCampos

  }
  function verificaCamposFormularioRoTarm(data) {

    let validacaoCampos = {
      validado: false,
      campos: []
    }
    if (tipoChamado === 3) {//TROTE  
      if (data.telefone != "" && data.telefone != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o telefone");
      }
    }
    if (tipoChamado === 11) {//EQUIPE SAMU
      if (data.telefone != "" && data.telefone != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o telefone");
      }
      if (data.cidade != "" && data.cidade != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione uma cidade");
      }
      if (data.tipoEquipeContato != "" && data.tipoEquipeContato != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione o motivo do contato");
      }
    }
    if (tipoChamado === 14) {//DUPLICIDADE OCORRÊNCIA
      if (data.telefone != "" && data.telefone != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o telefone");
      }
      if (data.solicitanteNome != "" && data.solicitanteNome != undefined) {
        validacaoCampos.validado = true
      } else {
        if (!isTarm()) {
          validacaoCampos.validado = false
          validacaoCampos.campos.push("Preencha o nome do solicitante");
        }
      }
      if (data.cidade != "" && data.cidade != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione uma cidade");
      }
    }
    if (tipoChamado === 4 || tipoChamado === 8 || tipoChamado === 10) {//INFORMAÇÃO ou ENGANO ou  QUEDA DA LIGACAO
      if (data.telefone != "" && data.telefone != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o telefone");
      }
      if (data.solicitanteNome != "" && data.solicitanteNome != undefined) {
        validacaoCampos.validado = true
      } else {
        if (!isTarm()) {
          validacaoCampos.validado = false
          validacaoCampos.campos.push("Preencha o nome do solicitante");
        }
      }
      if (data.idade != "" && data.idade != undefined) {
        validacaoCampos.validado = true
      } else {
        if (data.idadeUnidade == "4")//Não informado
        {
          validacaoCampos.validado = true
        } else {
          validacaoCampos.validado = false
          validacaoCampos.campos.push("Informe a idade");
        }
      }
      if (data.idadeUnidade != "" && data.idadeUnidade != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Informe a unidade da idade");
      }
      if (data.sexo != "" && data.sexo != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Informe o sexo");
      }
    }
    if (tipoChamado === 6) {//TRANSF./INTERNAÇÃO
      if (data.telefone != "" && data.telefone != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o telefone");
      }
      if (data.solicitanteNome != "" && data.solicitanteNome != undefined) {
        validacaoCampos.validado = true
      } else {
        if (!isTarm()) {
          validacaoCampos.validado = false
          validacaoCampos.campos.push("Preencha o nome do solicitante");
        }
      }
      if (data.sexo != "" && data.sexo != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Informe o sexo");
      }
      if (data.idade != "" && data.idade != undefined) {
        validacaoCampos.validado = true
      } else {
        if (data.idadeUnidade == "4")//Não informado
        {
          validacaoCampos.validado = true
        } else {
          validacaoCampos.validado = false
          validacaoCampos.campos.push("Informe a idade");
        }
      }
      if (data.idadeUnidade != "" && data.idadeUnidade != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Informe a unidade da idade");
      }
      if (data.cidade != "" && data.cidade != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione uma cidade");
      }
      if (data.bairro != "" && data.bairro != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione um  bairro");
      }
      if (data.endereco != "" && data.endereco != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o endereço");
      }
      if (data.enderecoNumero != "" && data.enderecoNumero != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o número do endereço");
      }
    }
    if (tipoChamado === 1) {//REGULAÇÃO MÉDICA
      if (data.telefone != "" && data.telefone != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o telefone");
      }
      if (data.solicitanteNome != "" && data.solicitanteNome != undefined) {
        validacaoCampos.validado = true
      } else {
        if (!isTarm()) {
          validacaoCampos.validado = false
          validacaoCampos.campos.push("Preencha o nome do solicitante");
        }
      }
      if (data.sexo != "" && data.sexo != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Informe o sexo");
      }
      if (data.idade != "" && data.idade != undefined) {
        validacaoCampos.validado = true
      } else {
        if (data.idadeUnidade == "4")//Não informado
        {
          validacaoCampos.validado = true
        } else {
          validacaoCampos.validado = false
          validacaoCampos.campos.push("Informe a idade");
        }
      }
      if (data.idadeUnidade != "" && data.idadeUnidade != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Informe a unidade da idade");
      }
      if (data.cidade != "" && data.cidade != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione uma cidade");
      }
      if (data.bairro != "" && data.bairro != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione um  bairro");
      }
      if (data.endereco != "" && data.endereco != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o endereço");
      }
      if (data.enderecoNumero != "" && data.enderecoNumero != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o número do endereço");
      }
      if (data.queixaPrimaria != "" && data.queixaPrimaria != undefined) {
        validacaoCampos.validado = true
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("É preciso informar uma queixa primária");
      }
    }
    return validacaoCampos
  }

  const enviarViatura = () => {
    let data = formData
    data.id = chamado?.id
    console.log("esse é forma data ao enviar viatura" , data)
    setLoading(true)
    chamadoService.enviarViatura(data).then((response) => {
      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Viatura enviada com sucesso'
      }).then(function () {
        navigate("/home")
      });
    }).catch((error) => {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: '"Houve um erro ao salvar o envio da viatura"'
      })
    })
  }

  const finalizarChamado = () => {
    setLoadingEncerramento(true)
    let data = formData
    data.id = chamado?.id
    let verificaCamposFormulario = validaEncerramentoMedicoConduta(data)
    if (verificaCamposFormulario.validado && verificaCamposFormulario.campos.length == 0) {
      chamadoService.finalizarChamado(data).then((response) => {
        setLoadingEncerramento(false)
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Chamado finalizado com sucesso'
        }).then(function () {
          navigate("/home")
        });
      }).catch((error) => {
        setLoadingEncerramento(false)
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          text: '"Houve um erro ao finalizar o chamado"'
        })
      })
    } 

    if (verificaCamposFormulario.campos.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        html: verificaCamposFormulario.campos.map((campo) => '<li>' + campo + '</li>').join("")
      })
    }
    setLoadingEncerramento(false)
  }
  const validaEncerramentoMedicoConduta = (data) => {

    let validacaoCampos = {
      validado: true,
      campos: []
    }

    if (data.tipoConduta != "" && data.tipoConduta != undefined) {
      validacaoCampos.validado = true
    } else {
      validacaoCampos.validado = false
      validacaoCampos.campos.push("Selecione o tipo da conduta");
    }


    if(data.encerramento != "" && data.encerramento != undefined){
      validacaoCampos.validado = true
    }else{
      validacaoCampos.validado = false
      validacaoCampos.campos.push("Selecione o encerramento");
    }

    if(data.descricaoConduta != "" && data.descricaoConduta != undefined){
      validacaoCampos.validado = true
    }else{
      validacaoCampos.validado = false
      validacaoCampos.campos.push("Preencha a descrição da conduta");
    } 

    if(data.encerramento == 1 ){//Se for óbito, validar os campos do formulario de obito

      if(data.tipoObito != "" && data.tipoObito != undefined){
        validacaoCampos.validado = true 
      } else {
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione o tipo de obito");
      }

      if(data.nomeProfissionalObito != "" && data.nomeProfissionalObito != undefined){
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o nome do profissional de obito");
      }

      if(data.crmProfissionalObito != "" && data.crmProfissionalObito != undefined){
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o CRM do profissional de obito");
      }

      if(data.dataObito != "" && data.dataObito != undefined){
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione a data do obito");
      }

      if(data.horaObito != "" && data.horaObito != undefined){
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione a hora do obito");
      }

      if(data.nomeResponsavelRecebimentoObito != "" && data.nomeResponsavelRecebimentoObito != undefined){
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o nome do responsável pelo recebimento do obito");
      }

      if(data.rgResponsavelRecebimentoObito != "" && data.rgResponsavelRecebimentoObito != undefined){
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Preencha o RG do responsável pelo recebimento do obito");
      }

    }

    if(data.encerramento== 5){
      if(data.unidadeDestino != "" && data.unidadeDestino != undefined){
        validacaoCampos.validado = true
      }else{
        validacaoCampos.validado = false
        validacaoCampos.campos.push("Selecione a unidade de destino");  
      }

    }
    return validacaoCampos
  }


  const save = () => {
    let data = formData
    data["tipoChamado"] = tipoChamado
    setLoading(true)
    let verificaCamposFormulario = verificaCamposFormularioRoTarm(data)
    if (verificaCamposFormulario.validado && verificaCamposFormulario.campos.length == 0) {
      chamadoService.save(data).then((response) => {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'O chamado foi salvo com sucesso'
        }).then(function () {
          navigate("/home")
        });
      }).catch((error) => {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          text: '"Houve um erro ao salvar o chamado"'
        })
      })
    }
    if (verificaCamposFormulario.campos.length > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        html: verificaCamposFormulario.campos.map((campo) => '<li>' + campo + '</li>').join("")
      })
    }
    //alert(validacao.campos)
    setLoading(false)
  }

  const isTarm = () => {
    return (["ROOT", "TARM", "ADMINISTRADOR"].indexOf(role) >= 0)
  }

  const isRadioOperador = () => {
    return (["ROOT", "RADIO OPERADOR", "ADMINISTRADOR"].indexOf(role) >= 0)
  }

  const isMedico = () => {
    return (["ROOT", "MÉDICO", "ADMINISTRADOR"].indexOf(role) >= 0)
  }

  const isStatusInicial = () => {
    return !isStatusRegulacao() && !isStatusRadioOperador() && !isStatusExecucao() && !isStatusLiberacao()
  }

  const isStatusRegulacao = () => {
    return ([1].indexOf(chamado?.status?.id) >= 0)    //aguardando regulacao
  }

  const isStatusConduta = () => {
    return ([4, 12].indexOf(chamado?.status?.id) >= 0)    //conduta ou aguardando conduta
  }

  const isStatusRadioOperador = () => {
    return ([2, 9].indexOf(chamado?.status?.id) >= 0)    //regulacao feita ou canc. envio VTR
  }

  const isStatusExecucao = () => {
    return ([3, 4, 5].indexOf(chamado?.status?.id) >= 0)    //chamado em execucao
  }

  const isStatusLiberacao = () => {
    return ([6].indexOf(chamado?.status?.id) >= 0)    //chamado em liberacao
  }

  const selectViatura = (id) => {
    setViaturaSelecionada(id)
    changeField("viatura", id)
  }

  useEffect(() => {
    if (viaturaSelecionada) {
      //seleciona automaticamente a equipe vinculada à viatura no plantão
      veiculoService.getLastSchedule(viaturaSelecionada).then((result) => {
        let data = result.data

        let equipe = []
        for (let funcionario of data.funcionarios) {
          equipe.push({
            "nome": funcionario.fun_Descricao,
            "cargo": funcionario.fcar_descricao
          })
        }
        setEquipeViatura(equipe)

      }).catch((error) => {
        console.log(error)
        alert("Não foi possível recuperar a escala da viatura")
      })
    }
  }, [viaturaSelecionada])

  useEffect(() => {
    if (chamado) {
      if (chamado?.latitude) {
        setShowCoordenadas(true)
      }

      //filtra as viaturas de acordo com a cidade do chamado
      filtrarViaturasPorCidade()
    }
  }, [chamado])

  useEffect(() => {
    if (chamado && listData["cidades"]) {
      filterBairros(chamado.chamadoCidade?.id)
    }
  }, [chamado, listData])

  useEffect(() => { 
    if(chamado && listData['hipotesesDiagnosticas'])  {
      filterHipotesesDiagnosticas(chamado.tipoAph?.id)
    }
  }, [chamado, listData])

  const filtrarViaturasPorCidade = () => {
    // if (chamado?.chamadoCidade){
    //   let viaturasFiltradas = viaturas.filter((viatura) => viatura.cidade.id == chamado.chamadoCidade?.id)
    //   setViaturas(viaturasFiltradas)
    // }
  }

  const selecionaTipoAcao = (data) => {

    console.log("selecionaTipoAcao", data)
    if (data > 1) {
      console.log("sem intervenção")
      setSemIntervencao(true)
      formData["chamadoTipoAcaoSemIntervencao"] = data
      formData["chamadoTipoAcao"] = 0
    } else if (data == 1) {
      console.log("com intervenção")
      setSemIntervencao(false)
      formData["chamadoTipoAcao"] = 1
      formData["chamadoTipoAcaoSemIntervencao"] = 0
    } else if (data == 0) {
      console.log("pradao acao")
      formData["chamadoTipoAcao"] = 0
      formData["chamadoTipoAcaoSemIntervencao"] = 0
    }


    return true
  }

  return (
    <Layout>
      {action == "NEW" &&
      <S.FixedButton title='Voltar' onClick={() => navigate('/home')}>
        <FaArrowLeft size={30} cursor='pointer' />
      </S.FixedButton>
      }
      <S.SceneForm>
        {isLoading &&
          <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
        }
        <S.Title>FICHA DE REGULAÇÃO - CENA</S.Title>
        <S.ContainerFlex>
          <S.Wrapper>
            <S.Text>Nº:</S.Text>
          {chamado?.numeroChamadoDuplicado != null ?
          <>
          <S.BlackLabel>{chamado?.numeroChamadoDuplicado + "/" + chamado.sequenciaChamadoDuplicado}</S.BlackLabel>
          </>  
          :
          <>
          <S.BlackLabel>{chamado?.id}</S.BlackLabel>
          </>
          
          }
            

          </S.Wrapper>
          <S.Wrapper>
            <S.Text>Data:</S.Text>
            <S.Text>{(chamado) ? moment(chamado.data + " " + chamado.hora, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss") : moment().format("DD/MM/YYYY HH:mm:ss")}</S.Text>
          </S.Wrapper>
        </S.ContainerFlex>

        <S.Subtitle>CHAMADO</S.Subtitle>
        <S.ContainerGrid>
          <S.Wrapper>
            <S.Label>ABERTO POR:</S.Label>
            <S.Text>{(chamado) ? chamado.usuario?.name : user}</S.Text>
          </S.Wrapper>
          {action != "NEW" &&
            <>
              {/* <S.Wrapper>
              <S.Label>Médico Regulação:</S.Label>
              <Select data={medicoRegulacaoData} />
            </S.Wrapper>
            <S.Wrapper>
              <S.Label>Rádio Operador:</S.Label>
              <Select data={usersData} />
            </S.Wrapper>
            <S.Wrapper>
              <S.Label>Médico Cena:</S.Label>
              <Select data={medicoCenaData} />
            </S.Wrapper>
            <S.Wrapper>
              <S.Label>Equipe Enfermagem Cena:</S.Label>
              <Select data={equipeEnfermagemData} />            
            </S.Wrapper>
            <S.Wrapper>
              <S.Label>Usuário Pós-Cena:</S.Label>
              <Select data={usersData} />            
            </S.Wrapper>
            <S.Wrapper>
              <S.Label>VTR:</S.Label>
              <Select data={viaturaData} />            
            </S.Wrapper>
            <S.Wrapper>
              <S.Label>Equipe VTR:</S.Label>
              <S.Label>Condutor:</S.Label>
              <Select data={equipeViaturaData} />
              <S.Label>Técnico:</S.Label>
              <Select data={equipeViaturaData} />            
            </S.Wrapper> */}
            </>
          }
        </S.ContainerGrid>
        <hr />
        <br /><br />

        {((action == "READ")) ?
          <>

            {chamado?.tipo != null &&
              <h2 style={{ fontWeight: "bold" }}> {tipoChamadoData.find((item) => item.value == chamado.tipo.id).label}</h2>
            }
            <S.ContainerGrid>
              <S.Wrapper>
                {chamado != null &&

                  <Input type='input' label='Telefone:' disabled value={chamado?.telefone} onChange={(e) => changeField("telefone", e.target.value)} />
                }
              </S.Wrapper>
              <S.Wrapper>
                {chamado != null &&
                  <Input type='input' label='Telefone 2:' disabled value={chamado?.telefoneAlternativo} onChange={(e) => changeField("telefoneAlternativo", e.target.value)} />
                }
              </S.Wrapper>
            </S.ContainerGrid>

            {(!isTipoChamadoTrote() && !isTipoChamadoEquipeContato()) &&
              <S.ContainerGrid>
                <S.Wrapper>
                  {chamado != null &&
                    <Input type='input' disabled label='Nome do Solicitante:' value={chamado?.solicitante} onChange={(e) => changeField("solicitanteNome", e.target.value)} />
                  }
                </S.Wrapper>
              </S.ContainerGrid>
            }

            {(!isTipoChamadoDuplicidade() && !isTipoChamadoTrote() && !isTipoChamadoEquipeContato()) &&
              <S.Wrapper>
                {chamado != null &&
                  <Input type='input'  label='Nome do Paciente:' disabled value={chamado?.pacienteNome} onChange={(e) => changeField("pacienteNome", e.target.value)} />
                }
              </S.Wrapper>
            }

            {(isTipoChamadoDuplicidade()) &&
              <S.Wrapper>
                {chamado != null &&
                  <Input type='input' label='Número do Chamado Duplicado:' disabled value={formData["numeroChamadoDuplicado"]} onChange={(e) => changeField("numeroChamadoDuplicado", e.target.value)} />
                }
              </S.Wrapper>
            }

            {(!isTipoChamadoTrote() && !isTipoChamadoEquipeContato() && !isTipoChamadoDuplicidade()) &&
              <S.ContainerFlex>
                {/* <S.Wrapper>
              <Input type='date' label='Data de Nascimento:' onChange={(e) => changeField("pacienteDataNascimento", e.target.value)} />
            </S.Wrapper> */}
                <S.Wrapper>
                  <S.ContainerFlex>
                    {chamado != null &&
                      <Input type='input' label='Idade:' disabled value={chamado?.pacienteIdade} onChange={(e) => changeField("idade", e.target.value)} />
                    }
                    <S.ContainerSelect>
                      <Select data={ageData} disabled value={chamado?.tipoIdade} onChange={(e) => changeField("idadeUnidade", e.target.value)} />
                    </S.ContainerSelect>
                  </S.ContainerFlex>
                </S.Wrapper>
                <S.Wrapper>
                  {chamado != null &&
                    <Select data={genderData} label='Sexo:' disabled value={chamado?.pacienteSexo?.id} onChange={(e) => changeField("sexo", e.target.value)} />
                  }
                </S.Wrapper>
              </S.ContainerFlex>
            }

            {isTipoChamadoEquipeContato() &&
              <S.Wrapper>
                <Select data={tipoEquipeContatoData} label='Motivo do contato:' disabled value={chamado?.descricao} onChange={(e) => changeField("tipoEquipeContato", e.target.value)} />
              </S.Wrapper>
            }

            {isTipoChamadoRegulacaoTransferencia() &&
              <>
                <Checkbox
                  data={[{ type: 'checkbox', label: 'Informar Coordenadas', value: true }]}
                  onChange={(e) => {
                    setShowCoordenadas(e.target.checked)
                  }}
                  value={[showCoordenadas]}
                  checked={chamado?.latitude != null && chamado?.latitude != undefined}
                />
                {showCoordenadas &&
                  <S.ContainerFlex style={{ width: 500 }}>
                    <Input type='input' label='Latitude:' value={chamado?.latitude} disabled onChange={(e) => changeField("latitude", e.target.value)} />
                    <Input type='input' label='Longitude:' value={chamado?.longitude} disabled onChange={(e) => changeField("longitude", e.target.value)} />
                    <FaGlobeAmericas size={25} cursor='pointer' color='blue' />
                  </S.ContainerFlex>
                }
              </>
            }

            {(isTipoChamadoRegulacao() || isTipoChamadoTransferencia()) &&
              <>
                <S.ContainerGrid>
                  <S.Wrapper>
                    {chamado != null &&
                      <Input type='input' label='Endereço:' disabled value={chamado?.chamadoEndereco} onChange={(e) => changeField("endereco", e.target.value)} />
                    }
                  </S.Wrapper>
                  <S.Wrapper>
                    {chamado != null &&
                      <Input type='input' label='Nº:' value={chamado?.enderecoNumero} disabled onChange={(e) => changeField("enderecoNumero", e.target.value)} />
                    }
                  </S.Wrapper>
                </S.ContainerGrid>
                <S.ContainerGrid>
                  <S.Wrapper>
                    <Select data={bairros} label='Bairro:' disabled propValue="id" propLabel="nome" value={chamado?.chamadoBairro?.id} onChange={(e) => changeField("bairro", e.target.value)} />
                  </S.Wrapper>
                  <S.Wrapper>
                    {chamado != null &&
                      <Input type='input' label='Outro bairro:' disabled value={chamado?.bairroNovo} onChange={(e) => changeField("outroBairro", e.target.value)} />
                    }
                  </S.Wrapper>
                </S.ContainerGrid>
                <S.Wrapper>
                  {chamado != null &&
                    <Input type='input' label='Referência/Complemento: ' disabled value={chamado?.chamadoPontoReferencia} onChange={(e) => changeField("complemento", e.target.value)} />
                  }
                </S.Wrapper>
                {isTipoChamadoRegulacao() &&
                  <S.Wrapper>
                    <br /><br />
                    {chamado != null &&
                      <Input type='input' label='Queixa Primária: ' value={chamado?.queixaPrimaria} onChange={(e) => changeField("queixaPrimaria", e.target.value)} textarea={true} />
                    }
                  </S.Wrapper>
                }
              </>
            }

            {(action != "NEW" && (isRadioOperador())) &&
                <>
                  {/* <S.Wrapper>
              <S.Label>Histórico Regulação Médica:</S.Label>
            </S.Wrapper> */}
                  <S.Container>
                    {/* { chamado?.regulacoes && 
                <>
                  { chamado?.regulacoes?.map((regulacao) => {
                    return (
                      <S.Wrapper key={regulacao.id}>
                        <S.Text>{moment(regulacao.dataHora).format("DD/MM/YYYY HH:mm:ss")}</S.Text>
                        <S.BlackLabel>{regulacao.usuario.name}</S.BlackLabel>
                      </S.Wrapper>
                    )
                  })}                  
                </>
              } */}

                    {/* { listData['hipotesesDiagnosticas'] && 
                <Select data={listData['hipotesesDiagnosticas']} label='HD:' onChange={(e) => changeField("hipoteseDiagnostica", e.target.value)} />
              } */}

                    <S.Wrapper>
                      {chamado == null &&
                        <Input type='input' textarea={true} label='Regulação' onChange={(e) => changeField("regulacaoMedica", e.target.value)} disabled={!isMedico()} />
                      }
                      {chamado != null &&
                        <Input type='input' textarea={true} label='Regulação' value={chamado?.observacaoMedica} onChange={(e) => changeField("regulacaoMedica", e.target.value)} disabled={!isMedico()} />
                      }
                    </S.Wrapper>
                    
                    {listData['aph'] && 
                    <Select data={listData['aph']} label='APH:' disabled={!isMedico()} value={chamado?.tipoAph?.id} propLabel="descricao" propValue="id" onChange={(e) =>
                      {
                        changeField("aph", e.target.value)
                        filterHipotesesDiagnosticas(e.target.value)
                      }
                      } />
                     

                    }
                    <S.Wrapper>

                    {chamado != null &&
                      <Select data={hipoteseDiagnostica} value={parseInt(chamado.aphHipoteseDiagnostica)} disabled={!isMedico()} propValue="id" propLabel="descricao" label='HD:' onChange={(e) => changeField("hipoteseDiagnostica", e.target.value)} /> 
                    }
                    </S.Wrapper>
                    {chamado == null &&
                      <S.Wrapper>
                        <Select data={listData["prioridades"]} value={chamado?.aphPrioridade} propValue="id" propLabel="descricao" label='Prioridade:' onChange={(e) => changeField("prioridade", e.target.value)} disabled={!isMedico()} />
                      </S.Wrapper>
                    }
                    {chamado != null &&
                      <S.Wrapper>
                        <Select data={listData["prioridades"]} value={chamado?.aphPrioridade} propValue="id" propLabel="descricao" label='Prioridade:' onChange={(e) => changeField("prioridade", e.target.value)} disabled={!isMedico()} />
                      </S.Wrapper>
                    }

                    {chamado != null &&
                      <S.Wrapper>
                        <Select data={listData["chamadoTipoAcao"]} value={[tipoAcaoComIntervencao]} propValue="id" propLabel="descricao" label='Tipo Ação:'
                          onChange={(e) => {
                            setTipoAcaoComIntervencao(e.target.value)
                            changeField("chamadoTipoAcao", e.target.value)
                            setDadosTipoAcaoComIntervencao(e.target.value)
                          }} disabled={!isMedico()} />
                      </S.Wrapper>
                    }

                    {tipoAcaoComIntervencao == 2 &&
                      <S.Wrapper>
                        <Select data={listData["chamadoTipoAcaoSemIntervencao"]?.sort((a, b) => a.descricao > b.descricao)} value={tipoAcaoSemIntervencao} propValue="id" propLabel="descricao" label='Sem intervenção:' onChange={(e) => {
                          changeField("chamadoTipoAcaoSemIntervencao", e.target.value)
                          setTipoAcaoSemIntervencao(e.target.value)
                          setDadosTipoAcaoSemIntervencao(e.target.value)
                        }} disabled={!isMedico()} />
                      </S.Wrapper>
                    }

                    {chamado == null && tipoAcaoComIntervencao == 1 ?
                      <S.ContainerFlex>
                        <S.Label>Tipo de Veículo:
                          <Checkbox data={tipoVeiculoData} type="radio" value={listData[tipoVeiculo]} name="tipoVeiculo" onChange={(e) => {
                            setTipoVeiculo(Number(e.target.value))
                            changeField("tipoVeiculo", e.target.value)
                          }} disabled={!isMedico()} />
                        </S.Label>
                      </S.ContainerFlex>
                      :
                      <></>
                    }
                    {chamado != null && tipoAcaoComIntervencao == 1 ?
                      <S.ContainerFlex>
                        <S.Label>Tipo de Veículo:
                          <Checkbox data={tipoVeiculoData} type="radio" value={[chamado?.tipoVeiculo?.id]} name="tipoVeiculo" onChange={(e) => {
                            setTipoVeiculo(Number(e.target.value))
                            changeField("tipoVeiculo", e.target.value)
                          }} disabled={!isMedico()} />
                        </S.Label>
                      </S.ContainerFlex>
                      :
                      <></>
                    }

                  </S.Container>
                  {isTipoChamadoRegulacao() && tipoAcaoComIntervencao == 1 &&
                    <S.Container>
                      <S.Wrapper>
                        <S.Label>Apoio:</S.Label>
                      </S.Wrapper>
                      {listData["apoios"] &&
                        <Checkbox data={listData["apoios"]} name="apoio" value={formData["apoio"]} onChange={(e) => addCheckField("apoio", e.target.value, e.target.checked)} disabled={!isMedico()} />
                      }
                      <S.Wrapper>
                        {listData['deslocamentos'] &&
                          <Select data={listData['deslocamentos']} value={formData["deslocamento"]} propLabel="descricao" propValue="id" label='Código de Deslocamento:' onChange={(e) => changeField("deslocamento", e.target.value)} disabled={!isMedico()} />
                        }
                      </S.Wrapper>
                    </S.Container>
                  }                  
                </>
              }

            <S.Wrapper>
              {chamado != null &&
                <Input type='input' label='Observações: ' value={chamado?.conteudoRegistrado} onChange={(e) => changeField("observacao", e.target.value)} textarea={true} />
              }
            </S.Wrapper>

            <br /><br />

            {isTipoChamadoRegulacaoTransferencia() &&
              <>
                <S.ContainerFlex>
                  <S.Wrapper>
                    <Select data={listData["tiposSolicitantes"]} propValue="id" propLabel="descricao" disabled label='Quem Solicitou:' value={chamado?.chamadoTipoSolicitante?.id} onChange={(e) => changeField("tipoSolicitante", e.target.value)} />
                  </S.Wrapper>

                  {isTipoChamadoRegulacao() &&
                    <>
                      <S.Wrapper>
                        <Select data={listData["localizacoesSolicitante"]} propValue="id" propLabel="descricao" disabled label='Distância do Paciente:' value={chamado?.chamadoLocalizacaoSolicitante?.id} onChange={(e) => changeField("localizacaoSolicitante", e.target.value)} />
                      </S.Wrapper>
                      <S.Wrapper>
                        <Select data={listData["locaisPacientes"]} propValue="id" propLabel="descricao" disabled value={chamado?.chamadoLocal?.id} label='Local:' onChange={(e) => changeField("localPaciente", e.target.value)} />
                        {chamado != null &&
                          <Input type='input' label='Outro local: ' disabled value={chamado?.outroLocal} onChange={(e) => changeField("outroLocal", e.target.value)} />
                        }
                      </S.Wrapper>
                    </>
                  }
                </S.ContainerFlex>
              </>
            }

            {(isTipoChamadoTransferencia() && isMedico()) &&
              <>
                {/* <S.Subtitle>APH 2º</S.Subtitle> */}
                <hr></hr>
                <S.Wrapper>
                  <S.Label>Motivo da Transferência</S.Label>
                </S.Wrapper>
                <S.Wrapper>
                  {chamado.motivoTransferencia == null ?
                    <>
                      <Checkbox data={motivoTransferenciaData} value={[motivoTransferencia]} name="motivoTransferencia" onChange={(e) => {
                        setMotivoTransferencia(e.target.value)
                        changeField("motivoTransferencia", e.target.value)
                      }} />
                    </>
                    :
                    <>
                      <Checkbox data={motivoTransferenciaData} value={[chamado.motivoTransferencia]} name="motivoTransferencia" onChange={(e) => {
                        setMotivoTransferencia(e.target.value)
                        changeField("motivoTransferencia", e.target.value)
                      }} />
                    </>
                  }

                </S.Wrapper>
                <S.ContainerFlex>
                  <S.Wrapper>
                    {chamado.unidadeOrigem == null ?
                      <>
                        {listData["hospitais"] &&
                          <Select data={listData["hospitais"]} propValue="id" propLabel="descricao" label='Unidade Origem:' onChange={
                            (e) =>  {
                                changeField("unidadeOrigem", e.target.value)
                                changeField("hospitalOrigem", e.target.value)
                              }
                            } />
                        }
                      </>
                      :
                      <>
                        {listData["hospitais"] &&
                          <Select data={listData["hospitais"]} propValue="id" value={chamado.unidadeOrigem} propLabel="descricao" label='Unidade Origem:' onChange={
                            (e) => {
                              changeField("unidadeOrigem", e.target.value)
                              changeField("hospitalOrigem", e.target.value)
                            }
                          } />
                        }
                      </>
                    }
                    {chamado.complementoUnidadeOrigem == null ?
                      <>
                        <Input type='input' label='Complemento Origem' onChange={(e) => changeField("complementoUnidadeOrigem", e.target.value)} />
                      </>
                      :
                      <>
                        <Input type='input' label='Complemento Origem' value={chamado.complementoUnidadeOrigem} onChange={(e) => changeField("complementoUnidadeOrigem", e.target.value)} />
                      </>
                    }

                  </S.Wrapper>
                  <S.Wrapper>
                    {chamado.unidadeDestino == null ?
                      <>
                        {listData["hospitais"] &&
                          <Select data={listData["hospitais"]} propValue="id" propLabel="descricao" label='Unidade Destino:' onChange={
                            (e) => {
                               changeField("unidadeDestino", e.target.value)
                               changeField("hospitalDestino" , e.target.value)
                          }} />
                        }
                      </>
                      :
                      <>
                        {listData["hospitais"] &&
                          <Select data={listData["hospitais"]} propValue="id" value={chamado.unidadeDestino} propLabel="descricao" label='Unidade Destino:' onChange={
                            (e) => {
                              changeField("unidadeDestino", e.target.value)
                              changeField("hospitalDestino" , e.target.value)
                            }} />
                          // <Select data={listData["hospitais"]} propValue="id" value={chamado.unidadeDestino.cidade +" - " + chamado.unidadeDestino} propLabel="descricao" label='Unidade Destino:' onChange={(e) => changeField("unidadeDestino", e.target.value)} />
                        }
                      </>
                    }
                    {chamado.complementoUnidadeDestino == null ?
                      <>
                        <Input type='input' label='Complemento Destino' onChange={(e) => changeField("complementoUnidadeDestino", e.target.value)} />
                      </>
                      :
                      <>
                        <Input type='input' label='Complemento Destino' value={chamado.complementoUnidadeDestino} onChange={(e) => changeField("complementoUnidadeDestino", e.target.value)} />
                      </>
                    }

                  </S.Wrapper>
                </S.ContainerFlex>
                <S.ContainerFlex>
                  {chamado.pa == null ?
                    <>
                      <Input type='input' label='PA' onChange={(e) => changeField("pa", e.target.value)} />
                    </>
                    :
                    <>
                      <Input type='input' label='PA' value={chamado.pa} onChange={(e) => changeField("pa", e.target.value)} />
                    </>
                  }

                  {chamado.fc == null ?
                    <>
                      <Input type='input' label='FC' onChange={(e) => changeField("fc", e.target.value)} />
                    </>
                    :
                    <>
                      <Input type='input' label='FC' value={chamado.fc} onChange={(e) => changeField("fc", e.target.value)} />
                    </>
                  }
                  {chamado.fr == null ?
                    <>
                      <Input type='input' label='FR / SATM' onChange={(e) => changeField("fr", e.target.value)} />
                    </>
                    :
                    <>
                      <Input type='input' label='FR / SATM' value={chamado.fr} onChange={(e) => changeField("fr", e.target.value)} />
                    </>
                  }
                  {chamado.glasgow == null ?
                    <>
                      <Input type='input' label='GLASGOW' onChange={(e) => changeField("glasgow", e.target.value)} />
                    </>
                    :
                    <>
                      <Input type='input' label='GLASGOW' value={chamado.glasgow} onChange={(e) => changeField("glasgow", e.target.value)} />
                    </>
                  }
                  {chamado.dx == null ?
                    <>
                      <Input type='input' label='DX' onChange={(e) => changeField("dx", e.target.value)} />
                    </>
                    :
                    <>
                      <Input type='input' label='DX' value={chamado.dx} onChange={(e) => changeField("dx", e.target.value)} />
                    </>
                  }

                </S.ContainerFlex>
                <S.Wrapper>
                  {chamado.observacaoTransferencia == null ?
                    <>
                      <Input type='input' label='Observação Transferência' textarea={true} onChange={(e) => changeField("observacaoTransferencia", e.target.value)} />
                    </>
                    :
                    <>
                      <Input type='input' label='Observação Transferência' value={chamado.observacaoTransferencia} textarea={true} onChange={(e) => changeField("observacaoTransferencia", e.target.value)} />
                    </>
                  }

                </S.Wrapper>

              </>
            }

            {(isTarm() || isRadioOperador()) && isStatusInicial() &&
              <S.ContainerButton>
                <S.FinishButton>
                  {!isLoading &&
                    <Button kind='secondary' name='Salvar' hasIcon onClick={save}>
                      <FaClipboardCheck size={25} color='green' />
                    </Button>
                  }
                  {isLoading &&
                    <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
                  }
                </S.FinishButton>
              </S.ContainerButton>
            }

            {(action != "NEW" && isMedico()) &&
              <>
                {/* <S.Wrapper>
              <S.Label>Histórico Regulação Médica:</S.Label>
            </S.Wrapper> */}
                <S.Container>
                  {/* { chamado?.regulacoes && 
                <>
                  { chamado?.regulacoes?.map((regulacao) => {
                    return (
                      <S.Wrapper key={regulacao.id}>
                        <S.Text>{moment(regulacao.dataHora).format("DD/MM/YYYY HH:mm:ss")}</S.Text>
                        <S.BlackLabel>{regulacao.usuario.name}</S.BlackLabel>
                      </S.Wrapper>
                    )
                  })}                  
                </>
              } */}

                  {/* { listData['hipotesesDiagnosticas'] && 
                <Select data={listData['hipotesesDiagnosticas']} label='HD:' onChange={(e) => changeField("hipoteseDiagnostica", e.target.value)} />
              } */}

                  <S.Wrapper>
                    {chamado == null &&
                      <Input type='input' textarea={true} label='Regulação:' onChange={(e) => changeField("regulacaoMedica", e.target.value)} />
                    }
                    {chamado != null &&
                      <Input type='input' textarea={true} label='Regulação:' value={chamado?.observacaoMedica} onChange={(e) => changeField("regulacaoMedica", e.target.value)} />
                    }
                  </S.Wrapper>
                  {listData['aph'] &&
                    <Select data={listData['aph']} label='APH:' value={chamado?.tipoAph?.id} propLabel="descricao" propValue="id" onChange={(e) =>
                      {
                        changeField("aph", e.target.value)
                        filterHipotesesDiagnosticas(e.target.value)
                      }
                      } />
                  }
                  <S.Wrapper>
                    {chamado != null &&
                    <Select data={hipoteseDiagnostica} value={chamado?.aphHipoteseDiagnostica} propValue="descricao" propLabel="descricao" label='HD:' onChange={(e) => changeField("hipoteseDiagnostica", e.target.value)} />
                    }
                  </S.Wrapper>

                  {chamado == null &&
                    <S.Wrapper>
                      <Select data={listData["prioridades"]} value={chamado?.aphPrioridade} propValue="id" propLabel="descricao" label='Prioridade:' onChange={(e) => changeField("prioridade", e.target.value)} />
                    </S.Wrapper>
                  }
                  {chamado != null &&
                    <S.Wrapper>
                      <Select data={listData["prioridades"]} value={chamado?.aphPrioridade} propValue="id" propLabel="descricao" label='Prioridade:' onChange={(e) => changeField("prioridade", e.target.value)} />
                    </S.Wrapper>
                  }

                  {chamado != null &&
                    <S.Wrapper>
                      <Select data={listData["chamadoTipoAcao"]} value={[tipoAcaoComIntervencao]} propValue="id" propLabel="descricao" label='Tipo Ação:'
                        onChange={(e) => {
                          setTipoAcaoComIntervencao(e.target.value)
                          changeField("chamadoTipoAcao", e.target.value)
                          setDadosTipoAcaoComIntervencao(e.target.value)
                        }} />
                    </S.Wrapper>
                  }

                  {tipoAcaoComIntervencao == 2 &&
                    <S.Wrapper>
                      <Select data={listData["chamadoTipoAcaoSemIntervencao"]?.sort((a, b) => a.descricao > b.descricao)} value={tipoAcaoSemIntervencao} propValue="id" propLabel="descricao" label='Sem intervenção:' onChange={(e) => {
                        changeField("chamadoTipoAcaoSemIntervencao", e.target.value)
                        setTipoAcaoSemIntervencao(e.target.value)
                        setDadosTipoAcaoSemIntervencao(e.target.value)
                      }} />
                    </S.Wrapper>
                  }

                  {chamado == null && tipoAcaoComIntervencao == 1 ?
                    <S.ContainerFlex>
                      <S.Label>Tipo de Veículo:
                        <Checkbox data={tipoVeiculoData} type="radio" value={listData[tipoVeiculo]} name="tipoVeiculo" onChange={(e) => {
                          setTipoVeiculo(Number(e.target.value))
                          changeField("tipoVeiculo", e.target.value)
                        }} />
                      </S.Label>
                    </S.ContainerFlex>
                    :
                    <></>
                  }
                  {chamado != null && tipoAcaoComIntervencao == 1 ?
                    <S.ContainerFlex>
                      <S.Label>Tipo de Veículo:
                        <Checkbox data={tipoVeiculoData} type="radio" value={[chamado?.tipoVeiculo?.id]} name="tipoVeiculo" onChange={(e) => {
                          setTipoVeiculo(Number(e.target.value))
                          changeField("tipoVeiculo", e.target.value)
                        }} />
                      </S.Label>
                    </S.ContainerFlex>
                    :
                    <></>
                  }

                </S.Container>
                {isTipoChamadoRegulacao() && tipoAcaoComIntervencao == 1 &&
                  <S.Container>
                    <S.Wrapper>
                      <S.Label>Apoio:</S.Label>
                    </S.Wrapper>
                    {chamado.apoios == null ?
                    <>
                    {listData["apoios"] &&
                      <Checkbox data={listData["apoios"]} name="apoio" value={[]} onChange={(e) => addCheckField("apoio", e.target.value, e.target.checked)} />
                    }
                    </>
                    :
                    <>
                    {listData["apoios"] &&
                      <Checkbox data={listData["apoios"]} name="apoio" 
                      value={chamado.apoios.map( (apoio) => {
                        return apoio.id
                      })} onChange={(e) => addCheckField("apoio", e.target.value, e.target.checked)} />
                    }
                    </>
                    }

                    {chamado.deslocamento == null ?
                    <>
                    <S.Wrapper>
                      {listData['deslocamentos'] &&
                        <Select data={listData['deslocamentos']} propLabel="descricao" propValue="id" label='Código de Deslocamento:' onChange={(e) => changeField("deslocamento", e.target.value)} />
                      }
                    </S.Wrapper>
                    </>
                    :
                    <>
                    <S.Wrapper>
                      {listData['deslocamentos'] &&
                        <Select data={listData['deslocamentos']} propLabel="descricao" value={chamado.deslocamento.id} propValue="id" label='Código de Deslocamento:' onChange={(e) => changeField("deslocamento", e.target.value)} />
                      }
                    </S.Wrapper>
                    </>
                    }

                  </S.Container>
                }
                {/* <S.Subtitle>OBSERVAÇÕES</S.Subtitle>
            <S.Container>
              <S.Label>Data:</S.Label><S.BlackLabel>09/03/2023 20:51:03</S.BlackLabel>
              <S.Label>Usuário:</S.Label><S.BlackLabel>(RÁDIO OPERADOR) ADRIANO LONGO HUMMEL</S.BlackLabel>
              <S.Wrapper>
                <S.Label>Observação:</S.Label><S.BlackLabel>Controle de frota: PASSADO P EQUIPE AS 20:50H.</S.BlackLabel>
              </S.Wrapper>
            </S.Container> */}
                <S.ContainerButton style={{ marginBottom: 30 }}>
                  <S.FinishButton>
                    {!isLoading &&
                      <Button kind='secondary' style={{ width: 300 }} name='Salvar Regulação' hasIcon onClick={saveRegulacao}>
                        <FaClipboardCheck size={25} color='green' />
                      </Button>
                    }
                    {isLoading &&
                      <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
                    }
                  </S.FinishButton>
                </S.ContainerButton>
              </>
            }
            {(isRadioOperador() && isStatusRadioOperador()) &&
              <>
                <S.RedContainer>
                  <S.Subtitle>RÁDIO OPERADOR</S.Subtitle>
                  <S.Wrapper>
                    <S.Label>Viatura:</S.Label>
                  </S.Wrapper>
                  {(viaturas.length > 0) &&
                    <Select data={viaturas} propLabel="descricao" propValue="id" onChange={(e) => selectViatura(e.target.value)} />
                  }
                  {equipeViatura.length > 0 &&
                    <div style={{ marginTop: 20 }}>
                      {equipeViatura.map((funcionario) => {
                        return (
                          <p style={{ fontSize: 18 }}>
                            <span style={{ fontWeight: "bold" }}>{funcionario.cargo}: </span>
                            <span>{funcionario.nome}</span>
                          </p>
                        )
                      })}
                    </div>
                  }
                 
                <S.Wrapper>
                {chamado != null &&
                  <Input type='input' label='Observações VTR: ' value={chamado?.observacaoVTR} onChange={(e) => changeField("observacaoVTR", e.target.value)} textarea={true} />
                }
              </S.Wrapper>
                </S.RedContainer>

                <S.ContainerButton>
                  <S.FinishButton>
                    {!isLoading &&
                      <Button kind='secondary' name='Enviar viatura' hasIcon onClick={enviarViatura}>
                        <FaClipboardCheck size={25} color='green' />
                      </Button>
                    }
                    {isLoading &&
                      <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
                    }
                  </S.FinishButton>
                </S.ContainerButton>
              </>
            }

            {isMedico() && isReadyToFinish() &&
              <>
                <S.RedContainer>
                  <S.Subtitle>ENCERRAMENTO</S.Subtitle>
                  <S.Wrapper>
                    <S.Label>Encerramento:</S.Label>
                  </S.Wrapper>
                  <Select data={encerramentoData} onChange={(e) => {
                    changeField("encerramento", e.target.value)
                    setMotivoEncerramento(e.target.value)
                  }} />

                  <S.Wrapper>
                    <S.Label>Tipo Conduta:</S.Label>
                  </S.Wrapper>
                  <Select data={listData["tipoCondutas"]} propValue="id" propLabel="descricao" onChange={(e) => {
                    changeField("tipoConduta", e.target.value)
                  }} />

                  <S.Wrapper>
                    <S.Label>Descrição Conduta:</S.Label>
                  </S.Wrapper>
                  <Input type='input' onChange={(e) => changeField("descricaoConduta", e.target.value)} textarea={true} />
                  {((motivoEncerramento) == 5) &&
                    <S.ContainerFlex>
                      <S.Wrapper>
                        {listData["hospitais"] &&
                          <Select data={listData["hospitais"]} value={chamado?.hospitalDestino?.id} propValue="id" propLabel="descricao" label='Unidade Destino:' onChange={(e) => changeField("unidadeDestino", e.target.value)} />
                        }
                        <Input type='input' label='Complemento Destino' onChange={(e) => changeField("complementoUnidadeDestino", e.target.value)} />
                      </S.Wrapper>
                    </S.ContainerFlex>
                  }
                  {((motivoEncerramento) == 1) &&
                    <S.ContainerFlex>
                      <S.Wrapper>
                        {listData["tipoObito"] &&
                          <Select data={listData["tipoObito"]} value={listData["tipoObito"][0]} propValue="id" propLabel="descricao" label='Tipo do Óbito:' onChange={(e) => changeField("tipoObito", e.target.value)} />
                        }
                        <Input type='input' label='Nome do Profissional:' onChange={(e) => changeField("nomeProfissionalObito", e.target.value)} />
                        <Input type='input' label='CRM do Profissional:' onChange={(e) => changeField("crmProfissionalObito", e.target.value)} />
                        <S.ContainerFlex>
                        <Input type='date' label='Data do Óbito:' onChange={(e) => changeField("dataObito", e.target.value)} />
                        <Input type='time' label='Hora do Óbito:' onChange={(e) => changeField("horaObito", e.target.value)} />
                        </S.ContainerFlex>

                        <S.BlackLabel>Ficha de Notificação de Óbito</S.BlackLabel>
                        <Input type='input' label='Nome do Responsável pelo Recebimento:' onChange={(e) => changeField("nomeResponsavelRecebimentoObito", e.target.value)} />
                        <Input type='input' label='RG do Reponsável pelo Recebimento:' onChange={(e) => changeField("rgResponsavelRecebimentoObito", e.target.value)} />
                      </S.Wrapper>
                    </S.ContainerFlex>
                  }

                </S.RedContainer>
                {/* <S.Subtitle>DADOS COMPLEMENTARES DO PACIENTE</S.Subtitle>
                <S.Container style={{ width: 245 }}>
                  <Button kind='secondary' name='Copiar endereço ocorrência' onClick={copiarEndereco} />
                </S.Container>
                <S.Label>Cidade:</S.Label>
                { listData["cidades"] && 
                  <Select data={listData["cidades"]} propValue="id" propLabel="nome" onChange={(e) => {
                    filterBairrosPaciente(e.target.value)
                    changeField("cidadePaciente", e.target.value) 
                  }} />
                }
                <S.ContainerGrid>
                  <S.Wrapper>
                    <Input type='input' label='Endereço:' onChange={(e) => changeField("enderecoPaciente", e.target.value) } />
                  </S.Wrapper>
                  <S.Wrapper>
                    <Input type='input' label='Nº:' onChange={(e) => changeField("enderecoNumeroPaciente", e.target.value) } />
                  </S.Wrapper>
                </S.ContainerGrid>
                <S.Wrapper>
                  <Select data={bairrosPaciente} label='Bairro:' propValue="id" propLabel="nome" onChange={(e) => changeField("bairroPaciente", e.target.value) } />
                </S.Wrapper>
                <S.ContainerFlex>
                  <S.Wrapper>
                    <Input type='input' label='Complemento:' onChange={(e) => changeField("complementoPaciente", e.target.value) } />
                  </S.Wrapper>
                  <S.Wrapper>
                    <Input type='input' label='Telefone do Paciente:' onChange={(e) => changeField("telefonePaciente", e.target.value) } />
                  </S.Wrapper>
                  <S.Wrapper>
                    <Input type='input' label='RG:' onChange={(e) => changeField("rgPaciente", e.target.value) } />
                  </S.Wrapper>
                </S.ContainerFlex> */}

                {isLoadingEncerramento == true &&
                  <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
                }
                {isLoadingEncerramento == false &&
                  <S.ContainerButton>
                    <S.FinishButton>
                      <Button kind='secondary' name='Finalizar chamado' hasIcon onClick={finalizarChamado}>
                        <FaClipboardCheck size={25} color='green' />
                      </Button>
                    </S.FinishButton>
                  </S.ContainerButton>
                }
              </>
            }


          </>
          : action != "READ" &&
          <>
            <>
              {chamado == null &&
                <Checkbox data={tipoChamadoData} type="radio" value={[tipoChamado]} name="tipoChamado" onChange={(e) => setTipoChamado(Number(e.target.value))} />
              }
              {chamado != null &&
                <h2 style={{ fontWeight: "bold" }}> {tipoChamadoData.find((item) => item.value == tipoChamado).label}</h2>
              }
              <S.ContainerGrid>
                <S.Wrapper>



                  {chamado == null &&
                    <>
                      <S.InputContainer>
                        <S.Label>Telefone:</S.Label>
                        <S.FormControl as={InputMask}
                          mask="(99) 99999-9999" value={chamado?.telefone} onChange={(e) => changeField("telefone", e.target.value)}
                        >
                        </S.FormControl>
                      </S.InputContainer>

                    </>
                  }
                  {chamado != null &&
                    <Input type='input' label='Telefone:' value={chamado?.telefone} onChange={(e) => changeField("telefone", e.target.value)} />
                  }

                </S.Wrapper>

                <S.Wrapper>
                  <S.InputContainer>
                    <S.Label>Telefone 2:</S.Label>
                    <S.FormControl as={InputMask}
                      mask="(99) 99999-9999" value={chamado?.telefoneAlternativo} onChange={(e) => changeField("telefoneAlternativo", e.target.value)}
                    >
                    </S.FormControl>
                  </S.InputContainer>
                </S.Wrapper>
              </S.ContainerGrid>

              {(isTipoChamadoRegulacaoTransferencia() || isTipoChamadoEquipeContato() || isTipoChamadoDuplicidade()) &&
                <S.Wrapper>
                  <S.Label>Cidade:</S.Label>
                  {listData["cidades"] &&
                    <Select data={listData["cidades"]} propValue="id" propLabel="nome" value={chamado?.chamadoCidade?.id} onChange={(e) => {
                      filterBairros(e.target.value)
                      changeField("cidade", e.target.value)
                    }} />
                  }
                </S.Wrapper>
              }

              {(!isTipoChamadoTrote() && !isTipoChamadoEquipeContato()) &&
                <S.ContainerGrid>
                  <S.Wrapper>
                    {chamado == null &&
                      <Input type='input' label='Nome do Solicitante:' onChange={(e) => changeField("solicitanteNome", e.target.value)} />
                    }
                    {chamado != null &&
                      <Input type='input' label='Nome do Solicitante:' value={chamado?.solicitante} onChange={(e) => changeField("solicitanteNome", e.target.value)} />
                    }
                  </S.Wrapper>
                </S.ContainerGrid>
              }

              {(!isTipoChamadoDuplicidade() && !isTipoChamadoTrote() && !isTipoChamadoEquipeContato()) &&
                <S.Wrapper>
                  {chamado == null &&
                    <Input type='input' label='Nome do Paciente:' onChange={(e) => changeField("pacienteNome", e.target.value)} />
                  }
                  {chamado != null &&
                    <Input type='input' label='Nome do Paciente:' value={chamado?.pacienteNome} onChange={(e) => changeField("pacienteNome", e.target.value)} />
                  }
                </S.Wrapper>
              }

              {(isTipoChamadoDuplicidade()) &&
                <S.Wrapper>
                  {chamado == null &&
                    <Input type='input' label='Número do Chamado Duplicado:' value={formData["numeroChamadoDuplicado"]} onChange={(e) => changeField("numeroChamadoDuplicado", e.target.value)} />
                  }
                  {chamado != null &&
                    <Input type='input' label='Número do Chamado Duplicado:' value={formData["numeroChamadoDuplicado"]} onChange={(e) => changeField("numeroChamadoDuplicado", e.target.value)} />
                  }
                </S.Wrapper>
              }

              {(!isTipoChamadoTrote() && !isTipoChamadoEquipeContato() && !isTipoChamadoDuplicidade()) &&
                <S.ContainerFlex>
                  {/* <S.Wrapper>
              <Input type='date' label='Data de Nascimento:' onChange={(e) => changeField("pacienteDataNascimento", e.target.value)} />
            </S.Wrapper> */}
                  <S.Wrapper>
                    <S.ContainerFlex>
                      {chamado == null &&
                        <Input type='input' label='Idade:' onChange={(e) => changeField("idade", e.target.value)} />
                      }
                      {chamado != null &&
                        <Input type='input' label='Idade:' value={chamado?.pacienteIdade} onChange={(e) => changeField("idade", e.target.value)} />
                      }
                      <S.ContainerSelect>
                        <Select data={ageData} value={chamado?.tipoIdade} onChange={(e) => changeField("idadeUnidade", e.target.value)} />
                      </S.ContainerSelect>
                    </S.ContainerFlex>
                  </S.Wrapper>
                  <S.Wrapper>
                    {chamado == null &&
                      <Select data={genderData} label='Sexo:' onChange={(e) => changeField("sexo", e.target.value)} />
                    }
                    {chamado != null &&
                      <Select data={genderData} label='Sexo:' value={chamado?.pacienteSexo?.id} onChange={(e) => changeField("sexo", e.target.value)} />
                    }
                  </S.Wrapper>
                </S.ContainerFlex>
              }

              {isTipoChamadoEquipeContato() &&
                <S.Wrapper>
                  <Select data={tipoEquipeContatoData} label='Motivo do contato:' value={chamado?.descricao} onChange={(e) => changeField("tipoEquipeContato", e.target.value)} />
                </S.Wrapper>
              }

              {isTipoChamadoRegulacaoTransferencia() &&
                <>
                  <Checkbox
                    data={[{ type: 'checkbox', label: 'Informar Coordenadas', value: true }]}
                    onChange={(e) => {
                      setShowCoordenadas(e.target.checked)
                    }}
                    value={[showCoordenadas]}
                    checked={chamado?.latitude != null && chamado?.latitude != undefined}
                  />
                  {showCoordenadas &&
                    <S.ContainerFlex style={{ width: 500 }}>
                      <Input type='input' label='Latitude:' value={chamado?.latitude} onChange={(e) => changeField("latitude", e.target.value)} />
                      <Input type='input' label='Longitude:' value={chamado?.longitude} onChange={(e) => changeField("longitude", e.target.value)} />
                      <FaGlobeAmericas size={25} cursor='pointer' color='blue' />
                    </S.ContainerFlex>
                  }
                </>
              }

              {(isTipoChamadoRegulacao() || isTipoChamadoTransferencia()) &&
                <>
                  <S.ContainerGrid>
                    <S.Wrapper>
                      {chamado == null &&
                        <Input type='input' label='Endereço:' onChange={(e) => changeField("endereco", e.target.value)} />
                      }
                                            {chamado != null &&
                        <Input type='input' label='Endereço:' value={chamado?.chamadoEndereco} onChange={(e) => changeField("endereco", e.target.value)} />
                      }
                    </S.Wrapper>
                    <S.Wrapper>
                      {chamado == null &&
                        <Input type='input' label='Nº:' onChange={(e) => changeField("enderecoNumero", e.target.value)} />
                      }
                                            {chamado != null &&
                        <Input type='input' label='Nº:' value={chamado?.enderecoNumero} onChange={(e) => changeField("enderecoNumero", e.target.value)} />
                      }
                    </S.Wrapper>
                  </S.ContainerGrid>
                  <S.ContainerGrid>
                    <S.Wrapper>
                      <Select data={bairros} label='Bairro:' propValue="id" propLabel="nome" value={chamado?.chamadoBairro?.id} onChange={(e) => changeField("bairro", e.target.value)} />
                    </S.Wrapper>
                    <S.Wrapper>
                      {chamado == null &&
                        <Input type='input' label='Outro bairro:' onChange={(e) => changeField("outroBairro", e.target.value)} />
                      }
                      {chamado != null &&
                        <Input type='input' label='Outro bairro:' value={chamado?.bairroNovo} onChange={(e) => changeField("outroBairro", e.target.value)} />
                      }
                    </S.Wrapper>
                  </S.ContainerGrid>
                  <S.Wrapper>
                    {chamado == null &&
                      <Input type='input' label='Referência/Complemento: ' onChange={(e) => changeField("complemento", e.target.value)} />
                    }
                    {chamado != null &&
                      <Input type='input' label='Referência/Complemento: ' value={chamado?.chamadoPontoReferencia} onChange={(e) => changeField("complemento", e.target.value)} />
                    }
                  </S.Wrapper>
                  {isTipoChamadoRegulacao() &&
                    <S.Wrapper>
                      <br /><br />
                      {chamado == null &&
                        <Input type='input' label='Queixa Primária: ' onChange={(e) => changeField("queixaPrimaria", e.target.value)} textarea={true} />
                      }
                      {chamado != null &&
                        <Input type='input' label='Queixa Primária: ' value={chamado?.queixaPrimaria} onChange={(e) => changeField("queixaPrimaria", e.target.value)} textarea={true} />
                      }
                    </S.Wrapper>
                  }
                </>
              }

              <S.Wrapper>
                {chamado == null &&
                  <Input type='input' label='Observações: ' onChange={(e) => changeField("observacao", e.target.value)} textarea={true} />
                }
                {chamado != null &&
                  <Input type='input' label='Observações: ' value={chamado?.conteudoRegistrado} onChange={(e) => changeField("observacao", e.target.value)} textarea={true} />
                }
              </S.Wrapper>

              <br /><br />

              {isTipoChamadoRegulacaoTransferencia() &&
                <>
                  <S.ContainerFlex>
                    <S.Wrapper>
                      <Select data={listData["tiposSolicitantes"]} propValue="id" propLabel="descricao" label='Quem Solicitou:' value={chamado?.chamadoTipoSolicitante?.id} onChange={(e) => changeField("tipoSolicitante", e.target.value)} />
                    </S.Wrapper>

                    {isTipoChamadoRegulacao() &&
                      <>
                        <S.Wrapper>
                          <Select data={listData["localizacoesSolicitante"]} propValue="id" propLabel="descricao" label='Distância do Paciente:' value={chamado?.chamadoLocalizacaoSolicitante?.id} onChange={(e) => changeField("localizacaoSolicitante", e.target.value)} />
                        </S.Wrapper>
                        <S.Wrapper>
                          <Select data={listData["locaisPacientes"]} propValue="id" propLabel="descricao" value={chamado?.chamadoLocal?.id} label='Local:' onChange={(e) => changeField("localPaciente", e.target.value)} />
                          {chamado == null &&
                            <Input type='input' label='Outro local: ' onChange={(e) => changeField("outroLocal", e.target.value)} />
                          }
                          {chamado != null &&
                            <Input type='input' label='Outro local: ' value={chamado?.outroLocal} onChange={(e) => changeField("outroLocal", e.target.value)} />
                          }
                        </S.Wrapper>
                      </>
                    }
                  </S.ContainerFlex>
                </>
              }

              {action != "NEW" &&
                <>
                  {/* <S.Wrapper>
              <Input type='input' label='Observações Rádio Operador: ' onChange={(e) => changeField("observacaoRadioOperador", e.target.value)} />
            </S.Wrapper>
            <S.Wrapper>
              <Input type='input' label='Observações TARM: ' onChange={(e) => changeField("observacaoTarm", e.target.value)} />
            </S.Wrapper> */}
                </>
              }

              {(isTipoChamadoTransferencia() && isMedico()) &&
                <>
         {/* <S.Subtitle>APH 2º</S.Subtitle> */}  
         <hr></hr>
                  <S.Wrapper>
                    <S.Label>Motivo da Transferência</S.Label>
                  </S.Wrapper>
                  <S.Wrapper>
                    <Checkbox data={motivoTransferenciaData} value={[motivoTransferencia]} name="motivoTransferencia" onChange={(e) => {
                      setMotivoTransferencia(e.target.value)
                      changeField("motivoTransferencia", e.target.value)
                    }} />
                  </S.Wrapper>
                  <S.ContainerFlex>
                    <S.Wrapper>
                      {listData["hospitais"] &&
                        <Select data={listData["hospitais"]} propValue="id" propLabel="descricao" label='Unidade Origem:' onChange={(e) => changeField("unidadeOrigem", e.target.value)} />
                      }
                      <Input type='input' label='Complemento Origem' onChange={(e) => changeField("complementoUnidadeOrigem", e.target.value)} />
                    </S.Wrapper>
                    <S.Wrapper>
                      {listData["hospitais"] &&
                        <Select data={listData["hospitais"]} propValue="id" propLabel="descricao" label='Unidade Destino:' onChange={(e) => changeField("unidadeDestino", e.target.value)} />
                      }
                      <Input type='input' label='Complemento Destino' onChange={(e) => changeField("complementoUnidadeDestino", e.target.value)} />
                    </S.Wrapper>
                  </S.ContainerFlex>
                  <S.ContainerFlex>
                    <Input type='input' label='PA' onChange={(e) => changeField("pa", e.target.value)} />
                    <Input type='input' label='FC' onChange={(e) => changeField("fc", e.target.value)} />
                    <Input type='input' label='FR / SATM' onChange={(e) => changeField("fr", e.target.value)} />
                    <Input type='input' label='GLASGOW' onChange={(e) => changeField("glasgow", e.target.value)} />
                    <Input type='input' label='DX' onChange={(e) => changeField("dx", e.target.value)} />
                  </S.ContainerFlex>
                  <S.Wrapper>
                    <Input type='input' label='Observação Transferência' textarea={true} onChange={(e) => changeField("observacaoTransferencia", e.target.value)} />
                  </S.Wrapper>

                </>
              }

              {(isTipoChamadoTransferencia() && isMedico()) &&
                <>
                  <S.Wrapper>
                    <S.Label>Ação com Intervenção</S.Label>
                  </S.Wrapper>
                  <S.Wrapper>
                    <Checkbox data={acaoComIntervencaoData} name="acaoComIntervencao" onChange={(e) => changeField("acaoComIntervencao", e.target.value)} />
                  </S.Wrapper>
                </>
              }

              {(isTarm() || isRadioOperador()) && isStatusInicial() &&
                <S.ContainerButton>
                  <S.FinishButton>
                    {!isLoading &&
                      <Button kind='secondary' name='Salvar' hasIcon onClick={save}>
                        <FaClipboardCheck size={25} color='green' />
                      </Button>
                    }
                    {isLoading &&
                      <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
                    }
                  </S.FinishButton>
                </S.ContainerButton>
              }
              
              {(action != "NEW" && (isMedico() || isRadioOperador())) &&
                <>
                  {/* <S.Wrapper>
              <S.Label>Histórico Regulação Médica:</S.Label>
            </S.Wrapper> */}
                  <S.Container>
                    {/* { chamado?.regulacoes && 
                <>
                  { chamado?.regulacoes?.map((regulacao) => {
                    return (
                      <S.Wrapper key={regulacao.id}>
                        <S.Text>{moment(regulacao.dataHora).format("DD/MM/YYYY HH:mm:ss")}</S.Text>
                        <S.BlackLabel>{regulacao.usuario.name}</S.BlackLabel>
                      </S.Wrapper>
                    )
                  })}                  
                </>
              } */}

                    {/* { listData['hipotesesDiagnosticas'] && 
                <Select data={listData['hipotesesDiagnosticas']} label='HD:' onChange={(e) => changeField("hipoteseDiagnostica", e.target.value)} />
              } */}

                    <S.Wrapper>
                      {chamado == null &&
                        <Input type='input' textarea={true} label='Regulação' onChange={(e) => changeField("regulacaoMedica", e.target.value)} disabled={!isMedico()} />
                      }
                      {chamado != null &&
                        <Input type='input' textarea={true} label='Regulação' value={chamado?.observacaoMedica} onChange={(e) => changeField("regulacaoMedica", e.target.value)} disabled={!isMedico()} />
                      }
                    </S.Wrapper>
                    {listData['aph'] &&
                    <Select data={listData['aph']} label='APH:' value={chamado?.tipoAph?.id} propLabel="descricao" propValue="id" onChange={(e) =>
                      {
                        changeField("aph", e.target.value)
                        filterHipotesesDiagnosticas(e.target.value)
                      }
                      } />
                  }
                    <S.Wrapper>
                      {chamado == null &&
                        <Input type='input' label='HD' onChange={(e) => changeField("hipoteseDiagnostica", e.target.value)} disabled={!isMedico()} />
                      }
                    {chamado != null &&
                     <Select data={hipoteseDiagnostica} value={ parseInt(chamado.aphHipoteseDiagnostica)} propValue="id" propLabel="descricao" label='HD:' onChange={(e) => changeField("hipoteseDiagnostica", e.target.value)} /> 
                    }
                    </S.Wrapper>
                    {chamado == null &&
                      <S.Wrapper>
                        <Select data={listData["prioridades"]} value={chamado?.aphPrioridade} propValue="id" propLabel="descricao" label='Prioridade:' onChange={(e) => changeField("prioridade", e.target.value)} disabled={!isMedico()} />
                      </S.Wrapper>
                    }
                    {chamado != null &&
                      <S.Wrapper>
                        <Select data={listData["prioridades"]} value={chamado?.aphPrioridade} propValue="id" propLabel="descricao" label='Prioridade:' onChange={(e) => changeField("prioridade", e.target.value)} disabled={!isMedico()} />
                      </S.Wrapper>
                    }

                    {chamado != null &&
                      <S.Wrapper>
                        <Select data={listData["chamadoTipoAcao"]} value={[tipoAcaoComIntervencao]} propValue="id" propLabel="descricao" label='Tipo Ação:'
                          onChange={(e) => {
                            setTipoAcaoComIntervencao(e.target.value)
                            changeField("chamadoTipoAcao", e.target.value)
                            setDadosTipoAcaoComIntervencao(e.target.value)
                          }} disabled={!isMedico()} />
                      </S.Wrapper>
                    }

                    {tipoAcaoComIntervencao == 2 &&
                      <S.Wrapper>
                        <Select data={listData["chamadoTipoAcaoSemIntervencao"]?.sort((a, b) => a.descricao > b.descricao)} value={tipoAcaoSemIntervencao} propValue="id" propLabel="descricao" label='Sem intervenção:' onChange={(e) => {
                          changeField("chamadoTipoAcaoSemIntervencao", e.target.value)
                          setTipoAcaoSemIntervencao(e.target.value)
                          setDadosTipoAcaoSemIntervencao(e.target.value)
                        }} disabled={!isMedico()} />
                      </S.Wrapper>
                    }

                    {chamado == null && tipoAcaoComIntervencao == 1 ?
                      <S.ContainerFlex>
                        <S.Label>Tipo de Veículo:
                          <Checkbox data={tipoVeiculoData} type="radio" value={listData[tipoVeiculo]} name="tipoVeiculo" onChange={(e) => {
                            setTipoVeiculo(Number(e.target.value))
                            changeField("tipoVeiculo", e.target.value)
                          }} disabled={!isMedico()} />
                        </S.Label>
                      </S.ContainerFlex>
                      :
                      <></>
                    }
                    {chamado != null && tipoAcaoComIntervencao == 1 ?
                      <S.ContainerFlex>
                        <S.Label>Tipo de Veículo:
                          <Checkbox data={tipoVeiculoData} type="radio" value={[chamado?.tipoVeiculo?.id]} name="tipoVeiculo" onChange={(e) => {
                            setTipoVeiculo(Number(e.target.value))
                            changeField("tipoVeiculo", e.target.value)
                          }} disabled={!isMedico()} />
                        </S.Label>
                      </S.ContainerFlex>
                      :
                      <></>
                    }

                  </S.Container>
                  {isTipoChamadoRegulacao() && tipoAcaoComIntervencao == 1 &&
                    <S.Container>
                      <S.Wrapper>
                        <S.Label>Apoio:</S.Label>
                      </S.Wrapper>
                      {listData["apoios"] &&
                        <Checkbox data={listData["apoios"]} name="apoio" value={formData["apoio"]} onChange={(e) => addCheckField("apoio", e.target.value, e.target.checked)} disabled={!isMedico()} />
                      }
                      <S.Wrapper>
                        {listData['deslocamentos'] &&
                          <Select data={listData['deslocamentos']} propLabel="descricao" propValue="id" label='Código de Deslocamento:' onChange={(e) => changeField("deslocamento", e.target.value)} disabled={!isMedico()} />
                        }
                      </S.Wrapper>
                    </S.Container>
                  }
                  {/* <S.Subtitle>OBSERVAÇÕES</S.Subtitle>
            <S.Container>
              <S.Label>Data:</S.Label><S.BlackLabel>09/03/2023 20:51:03</S.BlackLabel>
              <S.Label>Usuário:</S.Label><S.BlackLabel>(RÁDIO OPERADOR) ADRIANO LONGO HUMMEL</S.BlackLabel>
              <S.Wrapper>
                <S.Label>Observação:</S.Label><S.BlackLabel>Controle de frota: PASSADO P EQUIPE AS 20:50H.</S.BlackLabel>
              </S.Wrapper>
            </S.Container> */}
                  <S.ContainerButton style={{ marginBottom: 30 }}>
                    <S.FinishButton>
                      {!isLoading &&
                        <Button kind='secondary' style={{ width: 300 }} name='Salvar Regulação' hasIcon onClick={saveRegulacao}>
                          <FaClipboardCheck size={25} color='green' />
                        </Button>
                      }
                      {isLoading &&
                        <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
                      }
                    </S.FinishButton>
                  </S.ContainerButton>
                </>
              }

              {action != "NEW" &&
                <>
                  {/* <S.Subtitle>HORÁRIOS DO CHAMADO</S.Subtitle>
            <S.ContainerFlex>
              <S.Wrapper>
                <S.Label>Chamado:</S.Label>
                <S.BlackLabel><br/> 09/03/2023 <br/> 20:47:19</S.BlackLabel>
              </S.Wrapper>
              <S.Wrapper>
                <S.Label>Regulação médica:</S.Label>
                <S.BlackLabel><br/> 09/03/2023 <br/> 20:49:55</S.BlackLabel>
              </S.Wrapper>
              <S.Wrapper>
                <S.Label>Solicitação VTR:</S.Label>
                <S.BlackLabel><br/> 09/03/2023 <br/> 20:50:27</S.BlackLabel>
              </S.Wrapper>
              <S.Wrapper>
                <S.Label>Saída VTR:</S.Label>
                <S.BlackLabel><br/> 09/03/2023 <br/> 20:50:54</S.BlackLabel>
              </S.Wrapper>
            </S.ContainerFlex> */}
                </>
              }
              {action != "NEW" &&
                <>
                  {/* <S.Subtitle>SINAIS VITAIS / OUTROS ACHADOS</S.Subtitle>
            <S.Container style={{ width: 155 }}>
              <Button kind='secondary' name='Carregar CID-10' />
            </S.Container>
            <S.ContainerFlex>
              <S.Wrapper>
                <Select data={aphData} label='Tipo de APH:' onChange={(e) => changeField("aph", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <S.Label></S.Label>
                <Select data={diagnosticData} label='Hipótese Diagnóstica:' onChange={(e) => changeField("hipoteseDiagnostica", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Input type='input' label='Outra HD:' onChange={(e) => changeField("outraHipoteseDiagnostica", e.target.value) } />
              </S.Wrapper>
              <S.ContainerIcon style={{ marginTop: 30 }}>
                <FaPlusCircle size={25} cursor='pointer' />
              </S.ContainerIcon>
            </S.ContainerFlex>
            
            <S.Wrapper style={{ width: 170 }}>
              <Input type='datetime-local' label='Sinais Vitais:' onChange={(e) => changeField("sinaisVitais", e.target.value) } />
            </S.Wrapper>
            <S.ContainerFlex>
              <Input type='input' label='1 - PA' onChange={(e) => changeField("pa", e.target.value) } />
              <Input type='input' label='2 - FC' onChange={(e) => changeField("fc", e.target.value) } />
              <Input type='input' label='3 - FR' onChange={(e) => changeField("fr", e.target.value) } />
              <Input type='input' label='4 - SAT' onChange={(e) => changeField("sat", e.target.value) } />
              <Input type='input' label='5 - GLASGOW' onChange={(e) => changeField("glasgow", e.target.value) } />
            </S.ContainerFlex>
            <S.ContainerFlex>
              <Input type='input' label='6 - DX' onChange={(e) => changeField("dx", e.target.value) } />
              <Input type='input' label='7 - TEMPERATURA' onChange={(e) => changeField("temperatura", e.target.value) } />
              <Input type='input' label='CINCINNAT' onChange={(e) => changeField("cincinnat", e.target.value) } />
              <Input type='input' label='DOR' onChange={(e) => changeField("dor", e.target.value) } />
              <S.ContainerIcon style={{ marginTop: 30 }}>
                <FaPlusCircle size={25} cursor='pointer' />
              </S.ContainerIcon>
            </S.ContainerFlex>
            <S.Wrapper>
              <S.Label>Antecedentes:</S.Label>
            </S.Wrapper>
            <Checkbox data={secondCheckboxData} name="antecedentes" onChange={(e) => changeField("antecedentes", e.target.value) } />
            <S.Wrapper>
              <Input type='input' label='Outros Antecedentes:' onChange={(e) => changeField("outrosAntecedentes", e.target.value) } />
            </S.Wrapper>
            <S.Wrapper>
              <S.Label>Nível de Consciência:</S.Label>
            </S.Wrapper>
            <Checkbox data={thirdCheckboxData} name="nivelConsciencia" onChange={(e) => changeField("nivelConsciencia", e.target.value) } />
            <S.Wrapper>
              <S.Label>Respiração:</S.Label>
            </S.Wrapper>
            <Checkbox data={fourthCheckboxData} name="respiracao" onChange={(e) => changeField("respiracao", e.target.value) } />
            <S.ContainerFlex>
              <S.Wrapper>
                <Select data={sweatingData} label='Sudorese:' onChange={(e) => changeField("sudorese", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Select data={skinColoringData} label='Coloração da pele:' onChange={(e) => changeField("coloracaoPele", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Select data={bleedingData} label='Sangramento:' onChange={(e) => changeField("sangramento", e.target.value) } />
              </S.Wrapper>
            </S.ContainerFlex>
            <S.Wrapper>
              <Input type='input' label='História Pregressa da Moléstia Atual:' onChange={(e) => changeField("molestia", e.target.value) } />
            </S.Wrapper>
            <S.ContainerGrid>
              <S.Wrapper>
                <Input type='input' label='Uso de algum Medicamento:' onChange={(e) => changeField("usoMedicamento", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Input type='input' label='Alergia:' onChange={(e) => changeField("alergia", e.target.value) } />
              </S.Wrapper>
            </S.ContainerGrid>
            <S.RedContainer>
              <S.Wrapper style={{ paddingTop: 2 }}>
                <S.Label>Prioridade:</S.Label>
                <Checkbox data={fifthCheckboxData} name="prioridade" onChange={(e) => changeField("prioridade", e.target.value) } />
              </S.Wrapper>
            </S.RedContainer>
            <S.Wrapper>
              <Input type='input' label='Observação:' onChange={(e) => changeField("observacaoPrioridade", e.target.value) } />
            </S.Wrapper>

            <S.Subtitle>FERIMENTOS</S.Subtitle>
            <S.ContainerFlex>
              <S.Wrapper>
                <Select data={woundData} label='Ferimento:' onChange={(e) => changeField("ferimento", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Select data={woundSiteData} label='Local do ferimento:' onChange={(e) => changeField("localFerimento", e.target.value) } />
              </S.Wrapper>
              <S.ContainerIcon style={{ marginTop: 30 }}>
                <FaPlusCircle size={25} cursor='pointer' />
              </S.ContainerIcon>
            </S.ContainerFlex>

            <S.Subtitle>PROCEDIMENTOS</S.Subtitle>
            <S.ContainerFlex>
              <S.Wrapper>
                <Select data={typeOfProcedureData} label='Tipo de procedimento:' onChange={(e) => changeField("tipoProcedimento", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Select data={procedureData} label='Procedimento:' onChange={(e) => changeField("procedimento", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Input type='input' label='Observação:' onChange={(e) => changeField("observacaoProcedimento", e.target.value) } />
              </S.Wrapper>
              <S.ContainerIcon style={{ marginTop: 30 }}>
                <FaPlusCircle size={25} cursor='pointer' />
              </S.ContainerIcon>
            </S.ContainerFlex>
            
            <S.Subtitle>MEDICAMENTOS</S.Subtitle>
            <S.ContainerFlex>
              <S.Wrapper>
                <Select data={aphData} label='Medicamento - unidade:' onChange={(e) => changeField("unidadeMedicamento", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Input type='input' label='Quantidade:' onChange={(e) => changeField("quantidadeMedicamento", e.target.value) } />
              </S.Wrapper>
              <S.Wrapper>
                <Input type='input' label='Observação:' onChange={(e) => changeField("observacaoMedicamento", e.target.value) } />
              </S.Wrapper>
              <S.ContainerIcon style={{ marginTop: 30 }}>
                <FaPlusCircle size={25} cursor='pointer' />
              </S.ContainerIcon>
            </S.ContainerFlex>

            <S.Subtitle>APOIO</S.Subtitle>
            <Checkbox data={sixthCheckboxData} name="apoio" onChange={(e) => changeField("apoio", e.target.value) } />
            <S.Wrapper>
              <Input type='input' label='Observação do Apoio:' onChange={(e) => changeField("observacaoApoio", e.target.value) } />
            </S.Wrapper> */}

                  {/* <S.RedContainer>
              <S.Subtitle>CONDUTA</S.Subtitle>
              <Checkbox data={seventhCheckboxData} name="conduta" onChange={(e) => changeField("conduta", e.target.value) } />
              <Select data={conductData} label='Conduta:' onChange={(e) => changeField("tipoConduta", e.target.value) } />
              <S.Container>
                <Input type='input' onChange={(e) => changeField("observacaoConduta", e.target.value) } />
              </S.Container>
              <S.ContainerIcon>
                <FaPlusCircle size={25} cursor='pointer' />
              </S.ContainerIcon>
              <hr/>
              <S.Wrapper>
                <S.Label>Encerramento:</S.Label>
              </S.Wrapper>
              <Select data={encerramentoData} onChange={(e) => changeField("encerramento", e.target.value) } />
            </S.RedContainer> */}

                  {(isRadioOperador() && isStatusRadioOperador()) &&
                    <>
                      <S.RedContainer>
                        <S.Subtitle>RÁDIO OPERADOR</S.Subtitle>
                        <S.Wrapper>
                          <S.Label>Viatura:</S.Label>
                        </S.Wrapper>
                        {(viaturas.length > 0) &&
                          <Select data={viaturas} propLabel="descricao" propValue="id" onChange={(e) => selectViatura(e.target.value)} />
                        }
                        {equipeViatura.length > 0 &&
                          <div style={{ marginTop: 20 }}>
                            {equipeViatura.map((funcionario) => {
                              return (
                                <p style={{ fontSize: 18 }}>
                                  <span style={{ fontWeight: "bold" }}>{funcionario.cargo}: </span>
                                  <span>{funcionario.nome}</span>
                                </p>
                              )
                            })}
                          </div>
                        }
                      </S.RedContainer>

                      <S.ContainerButton>
                        <S.FinishButton>
                          {!isLoading &&
                            <Button kind='secondary' name='Enviar viatura' hasIcon onClick={enviarViatura}>
                              <FaClipboardCheck size={25} color='green' />
                            </Button>
                          }
                          {isLoading &&
                            <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
                          }
                        </S.FinishButton>
                      </S.ContainerButton>
                    </>
                  }

                  {isMedico() && isReadyToFinish() &&
                    <>
                      <S.RedContainer>
                        <S.Subtitle>ENCERRAMENTO</S.Subtitle>
                        <S.Wrapper>
                          <S.Label>Encerramento:</S.Label>
                        </S.Wrapper>
                        <Select data={encerramentoData} onChange={(e) => {
                          changeField("encerramento", e.target.value)
                          setMotivoEncerramento(e.target.value)
                        }} />

                        <S.Wrapper>
                          <S.Label>Tipo Conduta:</S.Label>
                        </S.Wrapper>
                        <Select data={listData["tipoCondutas"]} propValue="id" propLabel="descricao" onChange={(e) => {
                          changeField("tipoConduta", e.target.value)
                        }} />

                        <S.Wrapper>
                          <S.Label>Descrição Conduta:</S.Label>
                        </S.Wrapper>
                        <Input type='input' onChange={(e) => changeField("descricaoConduta", e.target.value)} textarea={true} />
                        {((motivoEncerramento) == 5) &&
                          <S.ContainerFlex>
                            <S.Wrapper>
                              {listData["hospitais"] &&
                                <Select data={listData["hospitais"]} value={chamado?.hospitalDestino?.id} propValue="id" propLabel="descricao" label='Unidade Destino:' onChange={(e) => changeField("unidadeDestino", e.target.value)} />
                              }
                              <Input type='input' label='Complemento Destino' onChange={(e) => changeField("complementoUnidadeDestino", e.target.value)} />
                            </S.Wrapper>
                          </S.ContainerFlex>
                        }

                      </S.RedContainer>
                      {/* <S.Subtitle>DADOS COMPLEMENTARES DO PACIENTE</S.Subtitle>
                <S.Container style={{ width: 245 }}>
                  <Button kind='secondary' name='Copiar endereço ocorrência' onClick={copiarEndereco} />
                </S.Container>
                <S.Label>Cidade:</S.Label>
                { listData["cidades"] && 
                  <Select data={listData["cidades"]} propValue="id" propLabel="nome" onChange={(e) => {
                    filterBairrosPaciente(e.target.value)
                    changeField("cidadePaciente", e.target.value) 
                  }} />
                }
                <S.ContainerGrid>
                  <S.Wrapper>
                    <Input type='input' label='Endereço:' onChange={(e) => changeField("enderecoPaciente", e.target.value) } />
                  </S.Wrapper>
                  <S.Wrapper>
                    <Input type='input' label='Nº:' onChange={(e) => changeField("enderecoNumeroPaciente", e.target.value) } />
                  </S.Wrapper>
                </S.ContainerGrid>
                <S.Wrapper>
                  <Select data={bairrosPaciente} label='Bairro:' propValue="id" propLabel="nome" onChange={(e) => changeField("bairroPaciente", e.target.value) } />
                </S.Wrapper>
                <S.ContainerFlex>
                  <S.Wrapper>
                    <Input type='input' label='Complemento:' onChange={(e) => changeField("complementoPaciente", e.target.value) } />
                  </S.Wrapper>
                  <S.Wrapper>
                    <Input type='input' label='Telefone do Paciente:' onChange={(e) => changeField("telefonePaciente", e.target.value) } />
                  </S.Wrapper>
                  <S.Wrapper>
                    <Input type='input' label='RG:' onChange={(e) => changeField("rgPaciente", e.target.value) } />
                  </S.Wrapper>
                </S.ContainerFlex> */}

                      {isLoadingEncerramento == true &&
                        <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
                      }
                      {isLoadingEncerramento == false &&
                        <S.ContainerButton>
                          <S.FinishButton>
                            <Button kind='secondary' name='Finalizar chamado' hasIcon onClick={finalizarChamado}>
                              <FaClipboardCheck size={25} color='green' />
                            </Button>
                          </S.FinishButton>
                        </S.ContainerButton>
                      }
                    </>
                  }
                </>
              }
            </>
          </>
        }

      </S.SceneForm>

    </Layout>
  );
};

export default SceneForm;
