import api from "./api"

class ChamadoEquipamentoService{

    async getAllEquipments(){   
        return api.get("/equipamentos/find-equipments").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }


    async getAllFields(){   
        return api.get("/equipamentos/get-all-fields").then((result) => {
            return Promise.resolve(result)
        }).catch((error) => {
            return Promise.reject(error)
        })
    }
    
    async getAllEquipamentosRetidoChamado(id){
        return api.get("equipamentos/chamado/"+id).then((result) => {
            return Promise.resolve(result) 
        }).catch((error) => {
            return Promise.reject(error)
        })
    }

    async save(data){   
        console.log(data)
        if (data){
            return api.post("/equipamentos/create",  data).then((result) => {
                return Promise.resolve(result)
            }).catch((error) => {
                return Promise.reject(error)
            })     
        }}

    async updateLiberacao(data){   
            console.log("fazendo liberacao", data)
            if (data){
                return api.post("/equipamentos/update",  data).then((result) => {
                    return Promise.resolve(result)
                }).catch((error) => {
                    return Promise.reject(error)
                })     
            }}
 
}

const chamadoEquipamentoService = new ChamadoEquipamentoService()
export default chamadoEquipamentoService

