import styled, { css } from 'styled-components';
import { Nav } from 'react-bootstrap';

export const Container = styled.div`
padding-bottom: 1rem;
`;

export const Navbar = styled(Nav)`
  display: flex;
  width: 100%;
  height: 7.5rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 3rem;
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-bottom: 2px solid ${theme.colors.orange};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerLogo = styled.div`
  width: 15rem;
  height: 6rem;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

export const ContainerMenu = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
`;

export const NavLink = styled.div`
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  ${({ theme, isActive }) => css`
    color: ${isActive ? theme.colors.orange : theme.colors.darkGray};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.medium};
    font-weight: ${theme.fonts.weight.bold};
  `}

  :hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`;

export const NavItem = styled.span`
  margin-left: 0.3rem;
  ${({ theme }) => css`
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.medium};
    font-weight: ${theme.fonts.weight.bold};
  `}
`;

export const UserInformation = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    margin-bottom: 1rem;
    display: flex;
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
  `}
`;