import React, { useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import Logo from '../../../assets/images/logo_samu.png';
import * as S from './styles';
import { FaAccessibleIcon, FaAmbulance, FaPhone, FaSignOutAlt, FaUser, FaUserCircle } from 'react-icons/fa';
import loginService from '../../../services/LoginServices';
import Swal from 'sweetalert2';
import AuthContext from '../../../context/auth';

const Navbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { logout, user, role } = useContext(AuthContext)

  const logoff = () => {
    loginService.logout().then((response) => {
      window.localStorage.removeItem("id")
      window.localStorage.removeItem("user")
      window.localStorage.removeItem("hash")
      window.localStorage.removeItem("type")
      logout()
      navigate("/")
    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: 'Erro ao sair do sistema',
        //footer: '<a href="">Why do I have this issue?</a>'
      })
    })      
  }

  const isRadioOperador = () => {
    return (["ROOT","RADIO OPERADOR","ADMINISTRADOR"].indexOf(role) >= 0)
  }

  const isAdmin = () => {
    return (["ROOT","ADMINISTRADOR"].indexOf(role) >= 0)
  }

  return (
    <S.Container>
      <S.Navbar>
        <S.ContainerLogo>
          <Link to="/home">
            <S.Logo src={Logo} alt="SAMU" />
          </Link>
        </S.ContainerLogo>
        <S.ContainerMenu>
          <S.NavLink onClick={() => navigate("/home")} isActive={pathname.includes('/home')}>
            <FaPhone size={23} />
            <S.NavItem>Chamados</S.NavItem>
          </S.NavLink>
          { isAdmin() && 
            <S.NavLink onClick={() => navigate("/funcionarios")} isActive={pathname.includes('/funcionarios')}>
              <FaUser size={25} />
              <S.NavItem>Funcionários</S.NavItem>
            </S.NavLink>
          }
          { isRadioOperador() && 
            <>
              <S.NavLink onClick={() => navigate("/equipamentos")} isActive={pathname.includes('/equipamentos')}>
                <FaAccessibleIcon size={25} />
                <S.NavItem>Equipamentos retidos</S.NavItem>
              </S.NavLink>
            
              <S.NavLink onClick={() => navigate("/plantao")} isActive={pathname.includes('/plantao')}>
                <FaAmbulance size={25} />
                <S.NavItem>Plantão</S.NavItem>
              </S.NavLink>
            </>
          }
        </S.ContainerMenu>
        <S.UserInformation>
          <FaUserCircle size={25} style={{marginRight: 10}} />
          <span>Usuário Logado: {user}</span>
        </S.UserInformation>
        <S.NavLink onClick={() => logoff()}>
          <FaSignOutAlt size={25} />
          <S.NavItem>Sair</S.NavItem>
        </S.NavLink>
      </S.Navbar>
    </S.Container>
  );
};

export default Navbar;
