import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Login from './pages/public/login';
import Home from './pages/home';
import SceneForm from './pages/sceneForm';
import Equipments from './pages/equipment';
import WorkSchedule from './pages/workSchedule';
import Employees from './pages/employees';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/home" element={<Home/>} />
      <Route path="/cena" element={<SceneForm/>} />
      <Route path="/equipamentos" element={<Equipments/>} />
      <Route path="/plantao" element={<WorkSchedule/>} />
      <Route path="/funcionarios" element={<Employees/>} />
    </Routes>
  </BrowserRouter>
);

export default Router;
