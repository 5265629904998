import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Layout from '../../components/layout';
import TableContainer from '../../components/table';
import Modal from '../../components/modal';
import Input from '../../components/input';
import Select from '../../components/select';
import Button from '../../components/button';
import Loading from '../../assets/images/loading.gif';

import {
  FaArrowLeft,
  FaPlusCircle,
  FaAmbulance,
} from "react-icons/fa";

import * as S from './styles';
import veiculoService from '../../services/VeiculoService';
import moment from 'moment';
import Swal from 'sweetalert2';

const WorkSchedule = () => {
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState()
  const [schedule, setSchedule] = useState()
  const [funcionarios, setFuncionarios] = useState([])
  const [filteredFuncionarios, setFilteredFuncionarios] = useState([])
  const [cargos, setCargos] = useState([])
  const [statusList, setStatusList] = useState([])
  const [funcionario1, setFuncionario1] = useState({Fun_ID: null, fcar_ID: null})
  const [funcionario2, setFuncionario2] = useState({Fun_ID: null, fcar_ID: null})
  const [funcionario3, setFuncionario3] = useState({Fun_ID: null, fcar_ID: null})
  const [formData, setFormData] = useState({})

  const vehicleColumns = [
    { Header: 'Base', accessor: 'base' },
    { Header: 'VTR', accessor: 'vtr' },    
    { Header: 'Status', accessor: 'status' },
    { Header: '', accessor: 'button' },
  ];

  const loadListData = () => {
    setLoading(true)
    veiculoService.getAll().then((response) => {
      let data = response.data
      data = data.map((vehicle) => {
        
        return {
          vtr: vehicle.descricao,
          base: vehicle.base.descricao,
          status: vehicle.status.descricao,
          button: (<FaAmbulance size={20} cursor='pointer' onClick={() => {
            veiculoService.getFuncionarios().then((response) => {
              let listaFuncionarios = response.data         
              veiculoService.getLastSchedule(vehicle.id).then((result) => {     
                
                setFilteredFuncionarios(listaFuncionarios.filter((funcionario) => funcionario.cid_id == vehicle.base.cidade.id))
  
                let data = result.data
                if (data.funcionarios.length > 0){
                  setFuncionario1({ Fun_ID: data.funcionarios[0].Fun_ID, fcar_ID: data.funcionarios[0].fcar_ID})
                }
                if (data.funcionarios.length > 1){
                  setFuncionario2({ Fun_ID: data.funcionarios[1].Fun_ID, fcar_ID: data.funcionarios[1].fcar_ID})
                }
                if (data.funcionarios.length > 2){
                  setFuncionario3({ Fun_ID: data.funcionarios[2].Fun_ID, fcar_ID: data.funcionarios[2].fcar_ID})
                }
                setFormData({
                  km_inicial: data.vEsc_KmInicial,
                  data_entrada: data.vEsc_HoraInicio,
                  data_saida: data.vEsc_HoraFinal,
                  hora_entrada: data.vEsc_HoraInicio,
                  hora_saida: data.vEsc_HoraFinal,
                  observacao: data.ds_observacao,
                  status: vehicle?.status?.id
                })
  
                setSchedule(result.data)
                setSelectedVehicle(vehicle)
                setShow(true)
              }).catch((error) => {
                console.log(error)
                alert("Não foi possível recuperar os dados da escala dessa viatura")
              }) 
            }).catch((error) => {
              alert("Não foi possível listar os funcionários")
            })
          }} />)
        }
      })
      
      setVehicles(data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível listar as viaturas")
    })
  }

  const loadCargos = () => {
    setLoading(true)
    veiculoService.getCargos().then((response) => {
      let data = response.data
      setCargos(data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível listar os cargos")
    })
  }

  const loadStatus = () => {
    setLoading(true)
    veiculoService.getStatus().then((response) => {
      let data = response.data
      setStatusList(data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível listar os status dos veículos")
    })
  }

  const loadFuncionarios = () => {
    setLoading(true)
    veiculoService.getFuncionarios().then((response) => {
      let data = response.data
      setFuncionarios(data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível listar os funcionários")
    })
  }

  const changeField = (field, value) => {
    let data = formData
    data[field] = value
    setFormData(data)
    console.log(data)
  }

  const save = () => {
    setLoading(true)
    let postData = {
      km_inicial: formData.km_inicial,
      data_entrada: moment(formData.data_entrada).format("YYYY-MM-DD"),
      data_saida: moment(formData.data_saida).format("YYYY-MM-DD"),
      hora_entrada: formData.hora_entrada,
      hora_saida: formData.hora_saida,
      status: formData.status,
      observacao: formData.observacao,
      veiculo: selectedVehicle?.id,
      funcionarios: [funcionario1, funcionario2, funcionario3]
    }

    veiculoService.createSchedule(postData).then((response) => {
      let data = response.data
      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Plantão salvo com sucesso'        
      }).then(() => {
        setShow(false)
        loadListData()
      })
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível salvar os dados do Plantão")
    })    
  }

  useEffect(() => {
    loadListData()
    loadCargos()
    loadFuncionarios()
    loadStatus()
  }, [])

  return (
    <Layout>
      {isLoading &&
        <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
      }
      <S.FixedButton title='Voltar' onClick={() => navigate('/home')}>
        <FaArrowLeft size={30} cursor='pointer' />
      </S.FixedButton>
      <S.WorkScheduleForm>
        <S.Title>Plantão</S.Title>
        <TableContainer columns={vehicleColumns} data={vehicles} type="workSchedule" />
      </S.WorkScheduleForm>
      { (selectedVehicle && formData) && 
        <Modal title='Escala' show={show} setShow={setShow}>
          <S.ContainerFlex>
            <S.Text>VTR:</S.Text>
            <S.Text>{selectedVehicle.descricao}</S.Text>
          </S.ContainerFlex>
          <S.ContainerGrid>
            <Input color='black' type='date' label='Data Entrada' onChange={(e) => changeField("data_entrada", e.target.value)} value={moment(formData.data_entrada).format("YYYY-MM-DD")} />
            <Input color='black' type='date' label='Data Saída' onChange={(e) => changeField("data_saida", e.target.value)} value={moment(formData.data_saida).format("YYYY-MM-DD")} />
          </S.ContainerGrid>
          <S.ContainerGrid>
            <Input color='black' type='time' label='Hora Entrada' onChange={(e) => changeField("hora_entrada", e.target.value)} value={moment(formData.hora_entrada).format("HH:mm")} />
            <Input color='black' type='time' label='Hora Saída' onChange={(e) => changeField("hora_saida", e.target.value)} value={moment(formData.hora_saida).format("HH:mm")} />
          </S.ContainerGrid>
          <S.ContainerGrid style={{marginTop: 20}}>
            <S.Text>Colaboradores</S.Text>
          </S.ContainerGrid>
          <S.ContainerFlex>
            <Select propValue="fcar_ID" propLabel="fcar_descricao" data={cargos} value={funcionario1?.fcar_ID} onChange={(e) => {
              let funcionario = funcionario1
              funcionario.fcar_ID = e.target.value
              setFuncionario1(funcionario)
            }} />
            <Select propValue="fun_Id" propLabel="fun_Descricao" data={filteredFuncionarios} value={funcionario1?.Fun_ID} onChange={(e) => {
              let funcionario = funcionario1
              funcionario.Fun_ID = e.target.value
              setFuncionario1(funcionario)
            }} />
          </S.ContainerFlex>
          <S.ContainerFlex>
            <Select propValue="fcar_ID" propLabel="fcar_descricao" data={cargos} value={funcionario2?.fcar_ID} onChange={(e) => {
              let funcionario = funcionario2
              funcionario.fcar_ID = e.target.value
              setFuncionario2(funcionario)
            }}/>
            <Select propValue="fun_Id" propLabel="fun_Descricao" data={filteredFuncionarios} value={funcionario2?.Fun_ID} onChange={(e) => {
              let funcionario = funcionario2
              funcionario.Fun_ID = e.target.value
              setFuncionario2(funcionario)
            }} />
          </S.ContainerFlex>
          <S.ContainerFlex>
            <Select propValue="fcar_ID" propLabel="fcar_descricao" data={cargos} value={funcionario3?.fcar_ID} onChange={(e) => {
              let funcionario = funcionario3
              funcionario.fcar_ID = e.target.value
              setFuncionario3(funcionario)
            }} />
            <Select propValue="fun_Id" propLabel="fun_Descricao" data={filteredFuncionarios} value={funcionario3?.Fun_ID} onChange={(e) => {
              let funcionario = funcionario3
              funcionario.Fun_ID = e.target.value
              setFuncionario3(funcionario)
            }} />
          </S.ContainerFlex>
          <S.ContainerGrid style={{marginTop: 20}}>
            <S.Text>Status da VTR</S.Text>
          </S.ContainerGrid>
          <S.ContainerFlex>            
            <Select propValue="cd_veiculoStatus" propLabel="ds_veiculoStatus" data={statusList} value={selectedVehicle.status?.id} onChange={(e) => {
              changeField("status", e.target.value)
            }} />
          </S.ContainerFlex>
          {/* <S.ContainerIcon>
            <FaPlusCircle size={25} cursor='pointer' />
          </S.ContainerIcon> */}
          <Input color='black' type='text' label='KM Inicial:' value={formData.km_inicial} onChange={(e) => changeField("km_inicial", e.target.value)} />
          {/* <Input color='black' type='text' label='Contatos:' /> */}
          <Input color='black' type='text' textarea label='Observação:' value={formData.observacao} onChange={(e) => changeField("observacao", e.target.value)} />
          <S.ButtonContainer>
            { !isLoading && 
              <Button kind='secondary' name='Salvar' onClick={() => save()} />
            }
            { isLoading && 
              <div style={{textAlign: "center", marginBottom: 40}}><img src={Loading} width="60" /></div>
            }              
          </S.ButtonContainer>
        </Modal>
      }
    </Layout>
  );
};

export default WorkSchedule;
