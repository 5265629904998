import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  padding: 2rem 4rem 2rem 4rem;
`;

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerFiltros = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;
`;


export const ContainerInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  span {
    ${({ theme }) => css`
      margin: 0.5rem;
      font-family: ${theme.fonts.family.default};
      font-size: ${theme.fonts.sizes.small};
      font-weight: ${theme.fonts.weight.bold};
    `}
  }
`;
export const cardDoctor = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const cardAddress = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ToolTipAdrress = styled.div` 
  position: absolute;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.lightRed};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1rem;
  top : -70px;
  width: 15rem;
  height: auto;
  display: none;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.colors.black};
`;

export const Tooltip = styled.div`
  position: absolute;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.lightRed};
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1rem;
  top : -190px;
  width: 15rem;
  height: auto;
  display: none;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.colors.black};
`;


export const UserInformation = styled.div`
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1rem;
  display: flex;
`;

export const NewCall = styled.div`
  width: 15rem;
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    text-decoration: underline;
    margin-bottom: 0.5rem;
  `}
`;

export const ContainerGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 5rem;
`;

export const Text = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.black};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.default};
    font-weight: ${theme.fonts.weight.bold};
    margin-right: 0.5rem;
  `}
`;


export const ButtonContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavLink = styled.div`
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  ${({ theme, isActive }) => css`
    color: ${isActive ? theme.colors.orange : theme.colors.darkGray};
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.medium};
    font-weight: ${theme.fonts.weight.bold};
  `}

  :hover {
    color: ${({ theme }) => theme.colors.orange};
  }
`;


export const NavItem = styled.span`
  margin-left: 0.3rem;
  ${({ theme }) => css`
    font-family: ${theme.fonts.family.default};
    font-size: ${theme.fonts.sizes.medium};
    font-weight: ${theme.fonts.weight.bold};
  `}
`;
