import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../assets/images/loading.gif';
import Layout from '../../components/layout';
import Input from '../../components/input';
import Select from '../../components/select';
import Button from '../../components/button';

import {
  FaArrowLeft,
} from "react-icons/fa";

import Swal from 'sweetalert2';
import * as S from './styles';
import AuthContext from '../../context/auth';
import chamadoEquipamentoService from '../../services/EquipamentoService';
import EquipamentoRetido from '../../components/equipamento_retido';

const Equipments = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const [action, setAction] = useState(location.state?.action)
  const [chamadoID] = useState(location.state?.id)
  const { user, role } = useContext(AuthContext)
  const [listData, setListData] = useState({})
  const [formData, setFormData] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [chamado, setChamado] = useState([])
  const [listaChamados, setListaChamados] = useState([])
  const [listaViaturas, setListaViaturas] = useState([])
  const [viatura, setViatura] = useState([])
  const [listaHospitais, setListaHospitais] = useState([])
  const [hospital, setHospital] = useState([])
  const [listaProfissionais, setListaProfissionais] = useState([])
  const [profisionalResponsavel, setProfissionalResponsavel] = useState([])
  const [listaEquipamentos, setListaEquipamentos] = useState([])
  const [listaEquipamentosRetidos, setListaEquipamentosRetidos] = useState([])
  const [listaEquipamentosRetidosSelecionados, setListaEquipamentosRetidosSelecionados] = useState([])
  const [quantidadeRetidaBaixa, setQuantidadeRetidaBaixa] = useState([])
  const [quantidadeLiberada, setQuantidadeLiberada] = useState([])
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState([])
  const [historicoLocalizacao, setHistoricoLocalizacao] = useState([])
  const [indiceEquipamentos, setIndiceEquipamentos] = useState(1)
  const [equipamentoRetido, setEquipamentoRetido] = useState([])
  const [diaMesAno, setDiaMesAno] = useState([])
  const [horario, setHorario] = useState([])
  const [horarioLiberacao, setHorarioLiberacao] = useState([])
  const [idEquipamento, setIdEquipamento] = useState(0);


  const loadListData = () => {
    setLoading(true)
    chamadoEquipamentoService.getAllFields().then((response) => {
      setLoading(false)
      let data = response.data
      let listaChamados = data["chamados"]
      setChamado(listaChamados.filter((chamadoID) => chamadoID.id == 0))
      setListaChamados(listaChamados)
      let listaViaturas = data["viaturas"]
      setListaViaturas(listaViaturas)
      setViatura(listaViaturas.filter((viatura) => viatura.id == 0))
      let listaHospitais = data["hospitais"]
      setListaHospitais(listaHospitais)
      setHospital(listaHospitais.filter((hospital) => hospital.id == 0))
      let listaProfissionais = data["profissionais"]
      setListaProfissionais(listaProfissionais)
      setProfissionalResponsavel(listaProfissionais.filter((profissional) => profissional.id == 0))
      let listaEquipamentos = data["equipamentos"]
      setListaEquipamentos(listaEquipamentos)
      setEquipamentoRetido(listaEquipamentos.filter((equipamento) => equipamento.id == 0))
      formData["listaEquipamentos"] = []

      console.log("carregando os dados do banco de equipamentos", data)
      console.log("ListaChamados", listaChamados)
      console.log("chamado", chamado)

      if (action == "READ") {
        chamadoEquipamentoService.getAllEquipamentosRetidoChamado(chamadoID).then((response) => {
          //setando os valores da primeira linha de equipamento retido
          setChamado(response.data[0].chamado)
          setViatura(response.data[0].veiculo)
          setHospital(response.data[0].hospital)
          setIdEquipamento(response.data[0].id)
          // if(response.data[0].quantidade == response.data[0].quantidadeLiberada){
          //   setQuantidadesIguais(true)
          // }
          //os valores são setados assim pois o primeiro elemento não é uma tabela, e o restante sim
          let counter = 0;
          response.data.map((row) => {
            
            if (counter == 0) {
              setQuantidadeLiberada(response.data[0].quantidadeLiberada)
              setQuantidadeRetidaBaixa(response.data[0].quantidade)
              let newDate = new Date(response.data[0].dataHoraAlteracao)
              setUltimaAtualizacao(newDate.toLocaleDateString() + " " + newDate.toLocaleTimeString())
              setHistoricoLocalizacao(response.data[0].observacao)
              setEquipamentoRetido(response.data[0].equipamento)
              setIdEquipamento(row.id)
            } else {
             adicionarEquipamentosRetidos(row)
            }
            counter++
          })
          // setListaEquipamentosRetidos([rows])
        });
      } else {
        //alert("não é read")
      }
      setListData(data)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      alert("Não foi possível carregar os dados")
    })



  }

  const changeField = (field, value) => {
    let data = formData
    data[field] = value
    setFormData(data)
    console.log(data)
  }


  const columns = [
    { name: 'Equipamento', accessor: 'equipment' },
    { name: 'Qtd. Retida Baixa', accessor: 'low_amount' },
    { name: 'Qtd. Liberada', accessor: 'amount' },
    { name: 'Última Atualização', accessor: 'last_update' },
    { name: 'Histórico Localização', accessor: 'history_location' },
    { name: 'Adicionar/Remover', accessor: 'add_rem' }
  ];

  const adicionarEquipamentosRetidos = (data) => {
    if (data) {
      let atualizaIndice = indiceEquipamentos + 1
      setIndiceEquipamentos(atualizaIndice)
      let equipamento = listaEquipamentosRetidos
       
      if(data.quantidade > 0 && data.quantidadeLiberada> 0 && data.quantidade == data.quantidadeLiberada){
        //setQuantidadesIguais(true)
      }

      equipamento.push({
        idListaEquipamento: indiceEquipamentos,
        quantidadeLiberada:data.quantidadeLiberada,
        quantidadeBaixa: data.quantidade,
        observacao: data.observacao,
        ultimaAtualizacao: data.dataHoraAlteracao,
        equipamentoRetido: data.equipamento.nome,
        compararQuantidade: {compararQuantidade},
        handleAllRequests: {handleAllRequests},
        idEquip:data.id
      })
    } else {
      let atualizaIndice = indiceEquipamentos + 1
      setIndiceEquipamentos(atualizaIndice)
      let equipamento = listaEquipamentosRetidos
      equipamento.push({
        idListaEquipamento: indiceEquipamentos
      })
    }
    return indiceEquipamentos
  }

  const removerEquipamentoRetidos = (idLista, idEquipamento) => {
    let data = formData["listaEquipamentos"]
    let existeEquipamentoLista = data.filter((item) => item.id != idLista)
    formData["listaEquipamentos"] = existeEquipamentoLista
    let listaFiltrada = listaEquipamentosRetidos.filter((item) => item.idListaEquipamento != idLista)
    setListaEquipamentosRetidos(listaFiltrada)
  }

  const handleAllRequests = (id, value, field) => {
    let data = formData["listaEquipamentos"]
    let ultimaAtualizacao = new Date().toDateString()
    let existeEquipamentoLista = data.find((item) => item.id == id)
    if (existeEquipamentoLista) {
      data = data.filter((item) => item.id != id)
      formData["listaEquipamentos"] = data
      if (field == "equipamento") {
        let equipamento = listaEquipamentos.find((equipamento) => equipamento.id == value)
        data.push({
          "equipamento": equipamento,
          "id": id,
          "quantidadeBaixa": existeEquipamentoLista.quantidadeBaixa,
          "quantidadeLiberada": existeEquipamentoLista.quantidadeLiberada,
          "ultimaAtualizacao": ultimaAtualizacao,
          "historicoLocalizacao": existeEquipamentoLista.historicoLocalizacao
        })
      }
      if (field == "quantidadeBaixa") {
        data.push({
          "equipamento": existeEquipamentoLista.equipamento,
          "id": id,
          "quantidadeBaixa": value,
          "quantidadeLiberada": existeEquipamentoLista.quantidadeLiberada,
          "ultimaAtualizacao": ultimaAtualizacao,
          "historicoLocalizacao": existeEquipamentoLista.historicoLocalizacao
        })
      }
      if (field == "quantidadeLiberada") {
        data.push({
          "equipamento": existeEquipamentoLista.equipamento,
          "id": id,
          "quantidadeBaixa": existeEquipamentoLista.quantidadeBaixa,
          "quantidadeLiberada": value,
          "ultimaAtualizacao": ultimaAtualizacao,
          "historicoLocalizacao": existeEquipamentoLista.historicoLocalizacao
        })
      }
      if (field == "historicoLocalizacao") {
        data.push({
          "equipamento": existeEquipamentoLista.equipamento,
          "id": id,
          "quantidadeBaixa": existeEquipamentoLista.quantidadeBaixa,
          "quantidadeLiberada": existeEquipamentoLista.quantidadeLiberada,
          "ultimaAtualizacao": ultimaAtualizacao,
          "historicoLocalizacao": value
        })
      }

    } else {
      let ultimaAtualizacaoCampos = new Date()
      ultimaAtualizacaoCampos = ultimaAtualizacaoCampos.toLocaleDateString()
      data = data.filter((item) => item.id != id)
      formData["listaEquipamentos"] = data
      if (field == "equipamento") {
        let equipamento = listaEquipamentos.find((equipamento) => equipamento.id == value)
        data.push({
          "equipamento": equipamento,
          "id": id
        })
      }
      if (field == "quantidadeBaixa") {
        data.push({
          "quantidadeBaixa": value,
          "id": id
        })
      }
      if (field == "quantidadeLiberada") {
        data.push({
          "quantidadeLiberada": value,
          "id": id
        })
      }
      if (field == "historicoLocalizacao") {
        data.push({
          "historicoLocalizacao": value,
          "id": id
        })
      }
      //setListaEquipamentosRetidos(data)
    }
  }

  const save = () => {
    let data = formData
    setLoading(true)
    chamadoEquipamentoService.save(data).then((response) => {
      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Os equipamentos retidos foram salvos com sucesso'
      }).then(function () {
        navigate("/home")
      });
    }).catch((error) => {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        text: '"Houve um erro ao salvar os equipamentos retidos"'
      })
    })
 
    setLoading(false)
  }

  useEffect(() => {
    loadListData()
  }, [])

  function setLiberacaoConcluida(id, quantidadeRetidaBaixa, quantidadeLiberada) {
    if(quantidadeRetidaBaixa == quantidadeLiberada ){
    return true
    }else{
      return false
    }
  }

  const [quantidadesIguais, setQuantidadesIguais] = useState(false)
   const compararQuantidade = async (row, id, quantidadeBaixa, quantidadeLiberada, observacao) => {

    if (quantidadeBaixa > 0 && quantidadeLiberada > 0) {
      if (quantidadeBaixa == quantidadeLiberada) {
        const { value: clickOk } = await Swal.fire({
          title: "Liberação dos Equipamentos",
          text: "Confirma a liberação dos equipamentos, retidos?",
          showCancelButton: true,
        });
        if (clickOk) {

          await Swal.fire({
            title: "Liberando equipamentos",
            text: "O equipamento id: "+id+" foi liberado!",
          })
          let data = {
            id: id,
            quantidadeLiberada:quantidadeLiberada,
            observacao:observacao
          }
          chamadoEquipamentoService.updateLiberacao(data).then((response) => {
           // setQuantidadesIguais(true)
            console.log("salvou!", response)
          }).catch((error) => {
            alert("problema ao salvar liberação")
          })
        }else{
          //setQtdeLiberada(quantidadeLiberada-1)
          if(row == 0){
          setQuantidadeLiberada(quantidadeLiberada-1)
          }else{
            quantidadeLiberada = quantidadeLiberada-1
          }
          
        }
      }
    }
  }


  return (
    <>

      {action == "READ" ?
        <Layout>
          {isLoading &&
            <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
          }
          <S.FixedButton title='Voltar' onClick={() => navigate('/home')}>
            <FaArrowLeft size={30} cursor='pointer' />
          </S.FixedButton>
          <S.SceneForm>
            <S.Title>Equipamentos retidos</S.Title>
            <S.ContainerGrid>
              <Input
                color='black'
                disabled type='text'
                label='Registrado por'
                value={user} />
              <S.ContainerSelect>
                <Select
                disabled
                  propValue="id"
                  propLabel="id"
                  label="Nº do chamado"
                  data={listaChamados}
                  value={chamado.id}
                  onChange={(e) => {
                    setChamado(e.target.value)
                    changeField("chamado", e.target.value)
                  }} />
              </S.ContainerSelect>
            </S.ContainerGrid>
            <S.ContainerGrid>
              <S.Wrapper>
                <Select
                disabled
                  propValue="id"
                  propLabel="descricao"
                  label="VTR"
                  data={listaViaturas}
                  value={viatura.id}
                  onChange={(e) => {
                    changeField("viatura", e.target.value)
                  }} />
              </S.Wrapper>
              <S.Wrapper>
                <Select
                  disabled
                  propValue="id"
                  propLabel="descricao"
                  label="Estabelecimento"
                  data={listaHospitais}
                  value={hospital.id}
                  onChange={(e) => {
                    changeField("hospital", e.target.value)
                  }} />
              </S.Wrapper>
            </S.ContainerGrid>
            <Select
              propValue="id"
              propLabel="nome"
              label="Prof. Responsável"
              data={listaProfissionais}
              value={profisionalResponsavel.id}
              onChange={(e) => {
                changeField("profissionalResponsavel", e.target.value)
              }} />
            <S.Table>
              <S.Columns>
                {columns.map((item) => (
                  <S.Label key={item.name}>{item.name}</S.Label>
                ))}
              </S.Columns>
              <hr />
              <S.Columns>
                <S.ContainerSelectEquipamento>
                  <Select
                    propValue="id"
                    propLabel="nome"
                    disabled
                    data={listaEquipamentos}
                    value={equipamentoRetido.id}
                    onChange={(e) => {
                      handleAllRequests(0, e.target.value, "equipamento")
                      setUltimaAtualizacao(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
                    }} />
                </S.ContainerSelectEquipamento>
                <Input
                  useValue={true}
                  value={quantidadeRetidaBaixa}
                  disabled
                  type="number"
                  onChange={(e) => {
                    handleAllRequests(0, e.target.value, "quantidadeBaixa")
                    setUltimaAtualizacao(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
                    setQuantidadeRetidaBaixa(e.target.value)
                  }} />
                <Input
                  useValue={true}
                  value={quantidadeLiberada}
                  type="number"
                  disabled={setLiberacaoConcluida(idEquipamento, quantidadeRetidaBaixa, quantidadeLiberada)}
                  onChange={(e) => {
                    handleAllRequests(0, e.target.value, "quantidadeLiberada")
                    setUltimaAtualizacao(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
                    setQuantidadeLiberada(e.target.value)
                    compararQuantidade(0 , idEquipamento, quantidadeRetidaBaixa,e.target.value, historicoLocalizacao)
                  }} />
                <Input
                  useValue={true}
                  type="text"
                  value={ultimaAtualizacao}
                  disabled
                  onChange={(e) => {
                    //setUltimaAtualizacao(e.target.value)
                  }} />
                <Input
                  useValue={true}
                  type="text"
                  value={historicoLocalizacao}
                  onChange={(e) => {
                    handleAllRequests(0, e.target.value, "historicoLocalizacao")
                    setUltimaAtualizacao(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
                    setHistoricoLocalizacao(e.target.value)
                  }
                  } />
                <S.ButtonContainer>
                  <Button
                    kind='primary'
                    name="Adicionar"
                    onClick={(e) => (adicionarEquipamentosRetidos())}></Button>
                </S.ButtonContainer>
              </S.Columns>
              {listaEquipamentosRetidos.map((item, indice) =>
                <EquipamentoRetido
                  disabled
                  listaEquipamentos={listaEquipamentos}
                  equipamentoRetido={item.equipamentoRetido}
                  remover={removerEquipamentoRetidos}
                  idListaEquipamento={item.idListaEquipamento}
                  quantidadeBaixa={item.quantidadeBaixa}
                  quantidadeLiberada={item.quantidadeLiberada}
                  ultimaAtualizacao={new Date(item.ultimaAtualizacao).toLocaleDateString() + " " + new Date(item.ultimaAtualizacao).toLocaleTimeString()}
                  observacao={item.observacao}
                  handleAllRequests={handleAllRequests}
                  compararQuantidade={compararQuantidade}
                  idEquip={item.idEquip}
                >
                </EquipamentoRetido>
              )}
            </S.Table>
            <S.ContainerData>
              <Input
                color='black'
                type='date'
                label='Data'
                value={diaMesAno}
                onChange={(e) => {
                  setDiaMesAno(e.target.value)
                  changeField("diaMesAno", e.target.value)
                }} />
              <Input
                color='black'
                type='time'
                label='Horário'
                value={horario}
                onChange={(e) => {
                  setHorario(e.target.value)
                  changeField("horario", e.target.value)
                }}
              />
            </S.ContainerData>
            <S.ContainerData>
              <Input
                color='black'
                type='time'
                label='Hora Lib.'
                value={horarioLiberacao}
                onChange={(e) => {
                  setHorarioLiberacao(e.target.value)
                  changeField("horarioLiberacao", e.target.value)
                }} />
            </S.ContainerData>
            <S.ButtonContainer>
              <Button
                kind='secondary'
                name='Concluir' onClick={save} />
            </S.ButtonContainer>
          </S.SceneForm>
        </Layout>
        :
        <Layout>
          {isLoading &&
            <div style={{ textAlign: "center", marginBottom: 40 }}><img src={Loading} width="60" /></div>
          }
          <S.FixedButton title='Voltar' onClick={() => navigate('/home')}>
            <FaArrowLeft size={30} cursor='pointer' />
          </S.FixedButton>
          <S.SceneForm>
            <S.Title>Equipamentos retidos</S.Title>
            <S.ContainerGrid>
              <Input
                color='black'
                disabled type='text'
                label='Registrado por'
                value={user} />
              <S.ContainerSelect>
                <Select
                  propValue="id"
                  propLabel="id"
                  label="Nº do chamado"
                  data={listaChamados}
                  value={chamado.id}
                  onChange={(e) => {
                    setChamado(e.target.value)
                    changeField("chamado", e.target.value)
                  }} />
              </S.ContainerSelect>
            </S.ContainerGrid>
            <S.ContainerGrid>
              <S.Wrapper>
                <Select
                  propValue="id"
                  propLabel="descricao"
                  label="VTR"
                  data={listaViaturas}
                  value={viatura.id}
                  onChange={(e) => {
                    changeField("viatura", e.target.value)
                  }} />
              </S.Wrapper>
              <S.Wrapper>
                <Select
                  propValue="id"
                  propLabel="descricao"
                  label="Estabelecimento"
                  data={listaHospitais}
                  value={hospital.id}
                  onChange={(e) => {
                    changeField("hospital", e.target.value)
                  }} />
              </S.Wrapper>
            </S.ContainerGrid>
            <Select
              propValue="id"
              propLabel="nome"
              label="Prof. Responsável"
              data={listaProfissionais}
              value={profisionalResponsavel.id}
              onChange={(e) => {
                changeField("profissionalResponsavel", e.target.value)
              }} />
            <S.Table>
              <S.Columns>
                {columns.map((item) => (
                  <S.Label key={item.name}>{item.name}</S.Label>
                ))}
              </S.Columns>
              <hr />
              <S.Columns>
                <S.ContainerSelectEquipamento>
                  <Select
                    propValue="id"
                    propLabel="nome"
                    data={listaEquipamentos}
                    value={equipamentoRetido.id}
                    onChange={(e) => {
                      handleAllRequests(0, e.target.value, "equipamento")
                      setUltimaAtualizacao(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
                    }} />
                </S.ContainerSelectEquipamento>
                <Input
                  useValue={true}
                  value={quantidadeRetidaBaixa}
                  type="number"
                  onChange={(e) => {
                    handleAllRequests(0, e.target.value, "quantidadeBaixa")
                    setUltimaAtualizacao(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
                    setQuantidadeRetidaBaixa(e.target.value)
                  }} />
                <Input
                  useValue={true}
                  value={quantidadeLiberada}
                  type="number"
                  disabled
                  onChange={(e) => {
                    handleAllRequests(0, e.target.value, "quantidadeLiberada")
                    setUltimaAtualizacao(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
                    setQuantidadeLiberada(e.target.value)
                  }} />
                <Input
                  useValue={true}
                  type="text"
                  value={ultimaAtualizacao}
                  disabled
                  onChange={(e) => {
                    //setUltimaAtualizacao(e.target.value)
                  }} />
                <Input
                  useValue={true}
                  type="text"
                  value={historicoLocalizacao}
                  onChange={(e) => {
                    handleAllRequests(0, e.target.value, "historicoLocalizacao")
                    setUltimaAtualizacao(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
                    setHistoricoLocalizacao(e.target.value)
                  }
                  } />
                <S.ButtonContainer>
                  <Button
                    kind='primary'
                    name="Adicionar"
                    onClick={(e) => (adicionarEquipamentosRetidos())}></Button>
                </S.ButtonContainer>
              </S.Columns>
              {listaEquipamentosRetidos.map((item, indice) =>
                <EquipamentoRetido
                  listaEquipamentos={listaEquipamentos}
                  remover={removerEquipamentoRetidos}
                  idListaEquipamento={item.idListaEquipamento}
                  handleAllRequests={handleAllRequests}
                  equipamentoRetido={item.equipamentoRetido}
                >
                </EquipamentoRetido>
              )}
            </S.Table>
            <S.ContainerData>
              <Input
                color='black'
                type='date'
                label='Data'
                value={diaMesAno}
                onChange={(e) => {
                  setDiaMesAno(e.target.value)
                  changeField("diaMesAno", e.target.value)
                }} />
              <Input
                color='black'
                type='time'
                label='Horário'
                value={horario}
                onChange={(e) => {
                  setHorario(e.target.value)
                  changeField("horario", e.target.value)
                }}
              />
            </S.ContainerData>
            <S.ContainerData>
              <Input
                color='black'
                type='time'
                label='Hora Lib.'
                value={horarioLiberacao}
                onChange={(e) => {
                  setHorarioLiberacao(e.target.value)
                  changeField("horarioLiberacao", e.target.value)
                }} />
            </S.ContainerData>
            <S.ButtonContainer>
              <Button
                kind='secondary'
                name='Concluir' onClick={save} />
            </S.ButtonContainer>
          </S.SceneForm>
        </Layout>
      }
    </>
  );
};

export default Equipments;
