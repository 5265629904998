import React, { useEffect, useState, useSyncExternalStore } from 'react';
import PropTypes from 'prop-types';
import Layout from '../../components/layout';
import Input from '../../components/input';
import Select from '../../components/select';
import Button from '../../components/button';
import * as S from './styles';

const EquipamentoRetido = (props) => {
  const { listaEquipamentos: equipamentos,
    idListaEquipamento,
    quantidadeBaixa,
    quantidadeLiberada,
    observacao,
    ultimaAtualizacao,
    equipamentoRetido,
    idEquip } = props;

  const [ultima, setUltima] = useState(ultimaAtualizacao)
  const [qtdeLiberada, setQtdeLiberada] = useState(quantidadeLiberada)
  const [observacaoValue, setObservacao] = useState(observacao)
  const [isDisabled , setDisabled]= useState(false)
  

  function setQuantidadesIguais(id, qtdeLiberada, qtdeRetida) {
    //console.log("chamando aqui")
    if (qtdeLiberada == qtdeRetida) {
      setDisabled(true)
    }

  }

  useEffect(() => {
    setQuantidadesIguais(idListaEquipamento, quantidadeLiberada, quantidadeBaixa)
  }, [])


  return (
    <S.Columns id={idListaEquipamento}>
      {equipamentoRetido ?
        <>
          <S.ContainerSelect>
            <Select
              propValue="id"
              propLabel="nome"
              useValue={true}
              disabled
              data={equipamentos}
              value={equipamentos.find(item => item.nome = equipamentoRetido).id}
              onChange={(e) => {
                props.handleAllRequests(idListaEquipamento, e.target.value, "equipamento")
              }} />
          </S.ContainerSelect>
          <Input
            disabled
            useValue={true}
            value={quantidadeBaixa}
            type="number"
            onChange={(e) => {
              props.handleAllRequests(idListaEquipamento, e.target.value, "quantidadeBaixa")
            }} />

          <Input
            useValue={true}
            value={qtdeLiberada}
            type="number"
            disabled={isDisabled}
            onChange={(e) => {
              setQtdeLiberada(e.target.value)
              props.compararQuantidade(idListaEquipamento,idEquip, quantidadeBaixa, e.target.value, observacaoValue)
              setUltima(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
              setQuantidadesIguais(idEquip, quantidadeBaixa,quantidadeLiberada )
            }} />

          <Input
            useValue={true}
            type="text"
            value={ultima}
            disabled
            onChange={(e) => {
            }} />
          <Input
            useValue={true}
            type="text"
            value={observacaoValue}
            onChange={(e) => {
              props.handleAllRequests(idListaEquipamento, e.target.value, "historicoLocalizacao")
              setUltima(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
              setObservacao(e.target.value)
            }} />
          <S.ButtonContainer>
            <Button kind='secondary' name='Remover' onClick={() => {
              props.remover(idListaEquipamento)
            }} />
          </S.ButtonContainer>
        </>
        :
        <>
          <S.ContainerSelect>
            <Select
              propValue="id"
              propLabel="nome"
              useValue={true}
              data={equipamentos}
              value={""}
              onChange={(e) => {
                props.handleAllRequests(idListaEquipamento, e.target.value, "equipamento")
                setUltima(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
              }} />
          </S.ContainerSelect>
          <Input
            useValue={true}
            value={quantidadeBaixa}
            type="number"
            onChange={(e) => {
              props.handleAllRequests(idListaEquipamento, e.target.value, "quantidadeBaixa")
              setUltima(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
            }} />
          <Input
            useValue={true}
            disabled
            value={quantidadeLiberada}
            type="number"
            onChange={(e) => {
              setUltima(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
            }} />
          <Input
            useValue={true}
            type="text"
            value={ultima}
            disabled
            onChange={(e) => {
            }} />
          <Input
            useValue={true}
            type="text"
            value={observacao}
            onChange={(e) => {
              props.handleAllRequests(idListaEquipamento, e.target.value, "historicoLocalizacao")
              setUltima(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString())
            }} />
          <S.ButtonContainer>
            <Button kind='secondary' name='Remover' onClick={() => {
              props.remover(idListaEquipamento)
            }} />
          </S.ButtonContainer>
        </>
      }


    </S.Columns>
  );
};

EquipamentoRetido.propTypes = {
  //data: PropTypes.array.isRequired,
};

export default EquipamentoRetido;
