const tipoVeiculoData = [
  {
    type: 'radio',
    label: 'USB',
    value: 1
  },
  {
    type: 'radio',
    label: 'USA',
    value: 2
  } 
]
const tipoChamadoData = [
  {
    type: 'radio',
    label: 'REGULAÇÃO MÉDICA',
    value: 1,
    checked: true
  },
  {
    type: 'radio',
    label: 'TROTE',
    value: 3,
  },
  {
    type: 'radio',
    label: 'INFORMAÇÃO',
    value: 4,
  },
  {
    type: 'radio',
    label: 'ENGANO',
    value: 8,
  },
  {
    type: 'radio',
    label: 'QUEDA DA LIGAÇÃO',
    value: 10,
  },
  {
    type: 'radio',
    label: 'EQUIPE SAMU/CONTATO',
    value: 11,
  },
  {
    type: 'radio',
    label: 'TRANSF./INTERNAÇÃO',
    value: 6,
  },
  {
    type: 'radio',
    label: 'DUPLICIDADE DE OCORRÊNCIA',
    value: 14,
  }
];

const secondCheckboxData = [
  {
    type: 'checkbox',
    label: 'Cardiopatia'
  },
  {
    type: 'checkbox',
    label: 'Diabetes'
  },
  {
    type: 'checkbox',
    label: 'Epilepsia'
  },
  {
    type: 'checkbox',
    label: 'Etilismo'
  },
  {
    type: 'checkbox',
    label: 'Hipertensão'
  }
  ,
  {
    type: 'checkbox',
    label: 'HIV'
  },
  {
    type: 'checkbox',
    label: 'Neofropatia'
  },
  {
    type: 'checkbox',
    label: 'Neoplasia'
  },
  {
    type: 'checkbox',
    label: 'Pneumopatia'
  },
  {
    type: 'checkbox',
    label: 'Sem comorbidates'
  },
  {
    type: 'checkbox',
    label: 'Sequela AVC'
  },
  {
    type: 'checkbox',
    label: 'Tabagismo'
  }          
];

const thirdCheckboxData = [
  {
    type: 'checkbox',
    label: 'Normal'
  },
  {
    type: 'checkbox',
    label: 'Confuso'
  },
  {
    type: 'checkbox',
    label: 'Torporoso'
  },
  {
    type: 'checkbox',
    label: 'Inconsciente'
  },
  {
    type: 'checkbox',
    label: 'Irresponsivo'
  }          
];    

const fourthCheckboxData = [
  {
    type: 'checkbox',
    label: 'Bradipneia'
  },
  {
    type: 'checkbox',
    label: 'Dispneia'
  },
  {
    type: 'checkbox',
    label: 'Não Respira'
  },
  {
    type: 'checkbox',
    label: 'Normal'
  },
  {
    type: 'checkbox',
    label: 'Ruidosa'
  },
  {
    type: 'checkbox',
    label: 'Taquipneia'
  },
  {
    type: 'checkbox',
    label: 'Via aérea obstruída'
  },
  {
    type: 'checkbox',
    label: 'Via aérea pérvia'
  }          
];

const fifthCheckboxData = [
  {
    type: 'radio',
    label: 'Vermelho',
    color: '#FF0000',
  },
  {
    type: 'radio',
    label: 'Laranja',
    color: '#FF7700',
  },
  {
    type: 'radio',
    label: 'Amarelo',
    color: '#FFCC13',
  },
  {
    type: 'radio',
    label: 'Verde',
    color: '#008000',
  },
  {
    type: 'radio',
    label: 'Azul',
    color: '#1800FF',
  }    
];

const sixthCheckboxData = [
  {
    type: 'checkbox',
    label: 'Agente de Trânsito'
  },
  {
    type: 'checkbox',
    label: 'Águia'
  },
  {
    type: 'checkbox',
    label: 'Bombeiros'
  },
  {
    type: 'checkbox',
    label: 'CCR'
  },
  {
    type: 'checkbox',
    label: 'Guarda Municipal'
  },
  {
    type: 'checkbox',
    label: 'PM'
  },
  {
    type: 'checkbox',
    label: 'PRE'
  },
  {
    type: 'checkbox',
    label: 'PRF'
  },
  {
    type: 'checkbox',
    label: 'USA'
  },
  {
    type: 'checkbox',
    label: 'USB'
  }
];

const seventhCheckboxData = [
  {
    type: 'checkbox',
    label: 'Atendimento na residência'
  },
  {
    type: 'checkbox',
    label: 'Conduta VTR'
  },
  {
    type: 'checkbox',
    label: 'Remoção'
  }        
];

const ageData = [
  { value: 1, name: 'ANO(S)' },
  { value: 2, name: 'MÊS(ES)' },
  { value: 3, name: 'DIA(S)' },
  { value: 4, name: 'NÃO INFORMADO' },
];

const medicoRegulacaoData = [
  { value: 1, name: 'MÉDICO 1' },
  { value: 2, name: 'MÉDICO 2' },
  { value: 3, name: 'MÉDICO 3' },
  { value: 4, name: 'MÉDICO 4' },
];

const medicoCenaData = [
  { value: 1, name: 'MÉDICO 1' },
  { value: 2, name: 'MÉDICO 2' },
  { value: 3, name: 'MÉDICO 3' },
  { value: 4, name: 'MÉDICO 4' },
];

const encerramentoData = [
  { value: 1, name: 'Óbito' },
  { value: 2, name: 'Ambulância Branca do Município Atendeu' },
  { value: 3, name: 'Cancelado pelo solicitante ou familiares' },
  { value: 4, name: 'CCR Nova Dutra Atendendo' },
  { value: 5, name: 'Conduta do Médico Regulador' },
  { value: 6, name: 'Conduzido por outra Viatura do SAMU' },
  { value: 7, name: 'Endereço não localizado' },
  { value: 8, name: 'Equipe da Unidade não avisou evasão do paciente' },
  { value: 9, name: 'Falsa ocorrência' },
  { value: 10, name: 'Paciente evadiu-se' },
  { value: 11, name: 'Recusa atendimento' },
  { value: 12, name: 'Recusa remoção' },
  { value: 13, name: 'Removido por terceiros' },
  { value: 14, name: 'Socorrido pelo Bombeiros' }

];

const usersData = [
  { value: 1, name: 'USUÁRIO 1' },
  { value: 2, name: 'USUÁRIO 2' },
  { value: 3, name: 'USUÁRIO 3' },
  { value: 4, name: 'USUÁRIO 4' },
];

const unidadeAtendimentoData = [
  { value: 1, name: 'Unidade 1' },
  { value: 2, name: 'Unidade 2' },
  { value: 3, name: 'Unidade 3' },
  { value: 4, name: 'Unidade 4' },
];

const equipeEnfermagemData = [
  { value: 1, name: 'Equipe 1' },
  { value: 2, name: 'Equipe 2' },
  { value: 3, name: 'Equipe 3' },
  { value: 4, name: 'Equipe 4' },
];

const viaturaData = [
  { value: 1, name: 'USB 06 - LORENA (BASE DESCENTRALIZADA DE LORENA)' },
  { value: 2, name: 'USB 07 - LORENA (BASE DESCENTRALIZADA DE LORENA)' }
];

const equipeViaturaData = [
  { value: 1, name: 'EMERSON RAMOS DOS ANJOS - CONDUTOR DE VEÍCULO DE EMERGÊNCIA' },
  { value: 2, name: 'ROGÉRIO HENRIQUE DA SILVA - TÉCNICO DE ENFERMAGEM' }
];

const genderData = [
  { value: 1, name: 'MASCULINO' },
  { value: 2, name: 'FEMININO' },
  { value: 3, name: 'NÃO INFORMADO' },
];

const tipoEquipeContatoData = [
  { value: "INFORMACAO_PARA_MEDICO_REGULADOR", name: 'Informação para Regulação Médica' },
  { value: "FINALIZACAO_ATENDIMENTO_NO_PRAZO", name: 'Finalização de atendimento dentro do prazo' },
  { value: "OUTRO", name: 'Outro' },
];

const aphData = [
  { value: 'clinico', name: 'CLINICO' },
  { value: 'neonato', name: 'NEONATO' },
  { value: 'obstetrico', name: 'OBSTETRICO' },
  { value: 'pediatria', name: 'PEDIATRIA' },
  { value: 'psiquiatria', name: 'PSIQUIATRIA' },
  { value: 'trauma', name: 'TRAUMA' },
];

const diagnosticData = [];

const sweatingData = [
  { value: 'ausente', name: 'Ausente' },
  { value: 'presente', name: 'Presente' },
];

const skinColoringData = [
  { value: 'cianose', name: 'Cianose' },
  { value: 'normal', name: 'Normal' },
  { value: 'palidez', name: 'Palidez' },
];

const bleedingData = [
  { value: 'ausente', name: 'Ausente' },
  { value: 'discreto', name: 'Discreto' },
  { value: 'volumoso', name: 'Volumoso' },
];

const woundData = [
  { value: 'amputacao', name: 'AMPUTAÇÃO/AVULSÃO' },
  { value: 'contusao', name: 'CONTUSÃO' },
  { value: 'cortante', name: 'CORTANTE' },
  { value: 'contuso', name: 'CORTO-CONTUSO' },
  { value: 'deformidade', name: 'DEFORMIDADE' },
  { value: 'escoriacao', name: 'ESCORIAÇÃO' },
  { value: 'fab', name: 'FAB' },
  { value: 'faf', name: 'FAF' },
  { value: 'fraturaExposta', name: 'FRATURA EXPOSTA' },
  { value: 'fraturaFechada', name: 'FRATURA FECHADA' },
  { value: 'perfurante', name: 'PERFURANTE' },
  { value: 'queimadura', name: 'QUEIMADURA' },
  { value: 'outros', name: 'OUTROS' },
];

const woundSiteData = [
  { value: 'abdome', name: 'ABDOME' },
  { value: 'antebraco', name: 'ANTEBRAÇO' },
  { value: 'boca', name: 'BOCA/LÍNGUA' },
  { value: 'braco', name: 'BRAÇO' },
  { value: 'cabeca', name: 'CABEÇA' },
  { value: 'coxa', name: 'COXA' },
  { value: 'dedosMao', name: 'DEDOS DA MÃO' },
  { value: 'dedosPe', name: 'DEDOS DO PÉ' },
  { value: 'dorso', name: 'DORSO' },
  { value: 'face', name: 'FACE' },
  { value: 'joelho', name: 'JOELHO' },
  { value: 'mao', name: 'MÃO' },
  { value: 'olho', name: 'OLHO' },
  { value: 'ombro', name: 'OMBRO' },
  { value: 'pe', name: 'PÉ' },
  { value: 'pelve', name: 'PELVE/BACIA' },
  { value: 'perineo', name: 'PERÍNEO' },
  { value: 'perna', name: 'PERNA' },
  { value: 'pescoco', name: 'PESCOÇO' },
  { value: 'torax', name: 'TÓRAX' },
];

const typeOfProcedureData = [
  { value: 'circulacao', name: 'CIRCULAÇÃO' },
  { value: 'curativo', name: 'CURATIVO' },
  { value: 'imobilizacao', name: 'IMOBILIZAÇÃO' },
  { value: 'oxigenacao', name: 'OXIGENAÇÃO' },
  { value: 'rops', name: 'ROPS' },
  { value: 'sondagem', name: 'SONDAGEM' },
  { value: 'ventilacao', name: 'VENTILAÇÃO' },
  { value: 'viaAcesso', name: 'VIA DE ACESSO' },
  { value: 'outros', name: 'OUTROS' },
];

const procedureData = [
  { value: 'outros', name: 'OUTROS' },
  { value: 'aas100mg', name: 'AAS 100MG - COMP.' },
  { value: 'assVo', name: 'AAS VO - mg' },
  { value: 'adenosina', name: 'ADENOSINA 6MG - AMP.' },
  { value: 'adrenalina', name: 'ADRENALINA 1MG - AMP.' },
  { value: 'aguaDestilada', name: 'AGUA DESTILADA 10ML - AMP.' },
  { value: 'amiodarona', name: 'AMIODARONA 150MG - AMP.' },
  { value: 'analgesico', name: 'ANALGÉSICO EV - amp' },
  { value: 'antiEspasmodico', name: 'ANTI ESPASMÓDICO EV - amp' },
  { value: 'antiHipertensivoSl', name: 'ANTI HIPERTENSIVO SL -' },
  { value: 'antiHipertensivoVo', name: 'ANTI HIPERTENSIVO VO -' },
  { value: 'antiInflamatorioEv', name: 'ANTI INFLAMATÓRIO NÃO HORMONAL EV - amp' },
  { value: 'antiInflamatorioIm', name: 'ANTI INFLAMATÓRIO NÃO HORMONAL IM - amp' },
  { value: 'atropina', name: 'ATROPINA 3MG - AMP.' },
  { value: 'bicarbonatoSodio', name: 'BICARBONATO DE SÓDIO 8,4% - amp/10 ml' },
  { value: 'bricanyl', name: 'BRICANYL 0,5MG - AMP' },
  { value: 'buscopan', name: 'BUSCOPAN SIMPLES 20MG - AMP' },
  { value: 'captopril', name: 'CAPTOPRIL 25MG - COMP.' },
  { value: 'clopidogrel', name: 'CLOPIDOGREL 75MG - COMP.' },
  { value: 'corticosteroide', name: 'CORTICOSTERÓIDE EV - amp' },
  { value: 'decadron', name: 'DECADRON 10MG - AMP' },
  { value: 'diazepanEv', name: 'DIAZEPAN EV - amp' },
  { value: 'diazepanIm', name: 'DIAZEPAN IM - amp' },
  { value: 'diazepanVo', name: 'DIAZEPAN VO - 10 mg/compr.' },
  { value: 'dipirona', name: 'DIPIRONA 1MG - amp' },
  { value: 'dormonid', name: 'DORMONID 15MG - amp' },
  { value: 'etomidato', name: 'ETOMIDATO 20MG - AMP' },
  { value: 'fenerganEv', name: 'FENERGAN EV - amp' },
  { value: 'fenerganIm', name: 'FENERGAN IM - amp' },
  { value: 'fentanil', name: 'FENTANIL 50MCG 10ML - AMP' },
  { value: 'flumazenil', name: 'FLUMAZENIL 0,5MG - AMP' },
  { value: 'furosemida', name: 'FUROSEMIDA 20MG - AMP' },
  { value: 'glicose25', name: 'GLICOSE HIPERTÔNICA 25% - amp' },
  { value: 'glicose50', name: 'GLICOSE HIPERTÔNICA 50% - amp' },
  { value: 'gluconato', name: 'GLUCONATO DE CÁLCIO 10% - AMP' },
  { value: 'haldolEv', name: 'HALDOL EV - amp' },
  { value: 'haldolIm', name: 'HALDOL IM - amp' },
  { value: 'hidantal', name: 'HIDANTAL 0,4MG - 1AMP' },
  { value: 'hidrocortizona', name: 'HIDROCORTIZONA 500MG - FR' },
  { value: 'isordil', name: 'ISORDIL 5MG - COMP' },
  { value: 'lidocaina', name: 'LIDOCAINA S/V 2% - FR' },
  { value: 'morfina', name: 'MORFINA 10MG - AMP' },
  { value: 'nausedron', name: 'NAUSEDRON 8MG - AMP' },
  { value: 'omeprazol', name: 'OMEPRAZOL EV - amp' },
  { value: 'oxugenio', name: 'OXIGÊNIO - l/min' },
  { value: 'plasil', name: 'PLASIL 10MG - AMP' },
  { value: 'profenid', name: 'PROFENID - FR' },
  { value: 'seloken', name: 'SELOKEN 5MG - AMP' },
  { value: 'soroFisiologico', name: 'SORO FISIOLÓGICO 0,9% - ml' },
  { value: 'soroGlicosado', name: 'SORO GLICOSADO 5% - ml' },
  { value: 'soroRinger', name: 'SORO RINGER LACTATO - ml' },
  { value: 'succinilcolina', name: 'SUCCINILCOLINA 100MG - FR' },
  { value: 'sulfatoMagnesio', name: 'SULFATO DE MAGNÉSIO 50% - AMP' },
  { value: 'sustrate', name: 'SUSTRATE 10MG - COMP' },
  { value: 'tramal', name: 'TRAMAL - amp' },
  { value: 'transaminEv', name: 'TRANSAMIN EV - amp' },
];

const conductData  = [
  { value: 'medicoRegulador', name: 'Conduta Médico Regulador' },
  { value: 'medicoIntervencionista', name: 'Conduta Médico Intervencionista' },
  { value: 'equipeEnfermagem', name: 'Conduta Equipe de Enfermagem' },
];

const hdData  = [
  { value: 1, name: 'OUTRA' },
  { value: 2, name: 'INFARTO' }
];

const acaoComIntervencaoData = [
  { value: 1, label: 'USB', type: "checkbox" },
  { value: 2, label: 'USA', type: "checkbox" }
]

const motivoTransferenciaData = [
  { value: 1, label: 'Exame', type: "checkbox" },
  { value: 2, label: 'Internação', type: "checkbox" },
  { value: 3, label: 'Transferência', type: "checkbox" }
]

const deslocamentoData = [
  { value: 1, name: 'Código 1' },
  { value: 2, name: 'Código 2' },
]

export {
  tipoVeiculoData,
  tipoChamadoData,
  secondCheckboxData,
  thirdCheckboxData,
  fourthCheckboxData,
  fifthCheckboxData,
  sixthCheckboxData,
  seventhCheckboxData,
  ageData,
  medicoRegulacaoData,
  genderData,
  diagnosticData,
  sweatingData,
  skinColoringData,
  bleedingData,
  woundData,
  woundSiteData,
  typeOfProcedureData,
  procedureData,
  conductData,
  medicoCenaData,
  usersData,
  equipeEnfermagemData,
  viaturaData,
  equipeViaturaData,
  aphData,
  hdData,
  acaoComIntervencaoData,
  encerramentoData,
  deslocamentoData,
  motivoTransferenciaData,
  tipoEquipeContatoData
};
